<template>
  <div>
    <b-sidebar
      id="sidebar-right-make-pro"
      ref="sidebar_make_pro"
      :visible="showOpenMakePro"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-make-pro', val)"
    >
      <template #default="{ hide }">

        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="pl-0 px-2 pt-75 pb-2" style="background: #f7f7f7; border-bottom: 1px solid #ebe9f1;">
            <p class="card-text mb-0 pt-50 name-site-header-sidebar" style="font-weight: 700; color: #181E38; font-size: 15px;">
              Page:
            </p>
            <p class="card-text mb-0 pt-50 name-site-header-sidebar" style="font-weight: 700; color: #181E38; font-size: 15px;">
              {{ page.name }}
            </p>
            <a :href="page.domain" target="_blank">
              <span
                class="url-header-sidebar"
                style="text-decoration: underline; color: #A7AAB9; font-size: 15px;"
              >
                {{ page.domain }}
              </span>
            </a>
        </div>
        <div
          class="b-sidebar-body"
          style="padding: 40px;"
        >
          <h3
            class="mb-75 pb-0"
            style="font-weight: 700; color: #181E38 !important;"
          >
            Activate SEOcloud copilot for this page:
          </h3>
          <b-button
            style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 15px;"
            class="p-0 mt-1 mr-1"
            variant="primary"
            :disabled="processSubmit"
            @click="proFunction"
          >
            <span
              class="align-middle"
              style="font-weight: 700; color: #FFFFFF;"
            >Confirm</span>
          </b-button>
          <b-button
            style="background: #F0F1F7 !important; border: 1px solid #CFD3E5 !important; width: 158px; height: 46px; border-radius: 15px;"
            class="p-0 mt-1"
            variant="primary"
            @click="hide"
          >
            <span
              class="align-middle"
              style="font-weight: 700; color: #A7AAB9;"
            >No</span>
          </b-button>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
// import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, auth, firebase, dbErrors } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    // ClaimOwnership,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenMakePro',
    event: 'update:show-open-make-pro',
  },
  props: ['idsite', 'showOpenMakePro','page','status'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      crawls: '',
      processSubmit: false,
      fixText: '',
      catFix: '',
    }
  },
  watch: {
    showOpenMakePro(val) {
      if(val) {
        document.body.style.overflow = 'hidden'
        this.processSubmit = false
      } else {
        localStorage.removeItem('viewTSProMakeHistory')
        var fixCopilot = localStorage.getItem('fixCopilot')
        if(!fixCopilot) {
            document.body.style.overflow = 'auto'
        }
        this.processSubmit = false
      }
    }
  },
  validations: {
    showOpenMakePro(val) {
      localStorage.removeItem('pro')
    }
  },
  methods: {
    submitEditar(payload) {
      this.$refs.sidebar_make_pro.hide()
    },
    proFunction() {
      const user = auth.currentUser
      if(user) {
        if(this.idsite.id && this.processSubmit === false) {
          this.processSubmit = true
          // setTimeout(() => {
            db.collection('Users').doc(user.uid).get()
            .then(userDoc => {
              db.collection('Sites').doc(this.idsite.id).get()
              .then(eleSite => {
                if(eleSite.exists) {
                  var arrayProPages = eleSite.data().proPages ? eleSite.data().proPages : []
                  var arrayHProPages = eleSite.data().HproPages ? eleSite.data().HproPages : []
                  // console.log(arrayProPages)
                  if(!arrayHProPages.length) {
                    arrayProPages.push(this.page.domain)
                    arrayHProPages = arrayProPages
                  } else {
                    arrayHProPages.push(this.page.domain)
                    arrayProPages.push(this.page.domain)
                  }
                  
                  db.collection('Sites').doc(eleSite.id).update({
                    proPages: firebase.firestore.FieldValue.arrayUnion(this.page.domain),
                    HproPages: arrayHProPages,
                    asignatedProPages: true,
                    updated: Date.now()
                  })
                  .then(() => {
                    db.collection('Sites').doc(eleSite.id).get()
                    .then(siteDoc => {
                        var proPages = siteDoc.data().proPages ? siteDoc.data().proPages : []
                        var pstatus = siteDoc.data().pSt ? siteDoc.data().pSt : []
                        var pSt = []
                        proPages.forEach(sitePro => {
                            var alreadyExistpstatus = pstatus.filter(ele => ele.url === sitePro)
                            if(alreadyExistpstatus.length) {
                                pSt.push({
                                    url: ele,
                                    crD: alreadyExistpstatus[0].crD,
                                    chD: alreadyExistpstatus[0].chD
                                })
                            } else {
                                pSt.push({
                                    url: ele,
                                    crD: Date.now(),
                                    chD: Date.now()
                                })
                            }
                        })

                        db.collection('Sites').doc(eleSite.id).update({
                            pSt: pSt
                        })

                        db.collection('Sites_cache').doc(eleSite.id).update({
                            pSt: pSt
                        })
                    })
                  })
                  localStorage.setItem('pro', true)

                  db.collection('Sites').doc(eleSite.id).collection('pages').where('url', '==', this.page.domain).limit(1).get()
                  .then(docSite => {
                      if(docSite.size) {
                          var counterLoop = 0
                          docSite.forEach(ele => {
                              if(counterLoop === 0) {
                                  db.collection('Sites').doc(eleSite.id).collection('pages').doc(ele.id).update({
                                      pro: true
                                  })
                              }
                          })
                      }
                  })

                  dbErrors.collection('fixes').where('SiteID', '==', eleSite.id).get()
                  .then(docPageFixCollection => {
                    dbErrors.collection('fixes_cache').where('SiteID', '==', eleSite.id).limit(1).get()
                    .then(docPageFixCacheCollection => {
                      // console.log(docPageFixCollection.size,docPageFixCacheCollection.size)
                      if(docPageFixCollection.size) {
                        if(docPageFixCacheCollection.size) {
                          var arrayFixes = []
                          docPageFixCollection.forEach(docFix => {
                            if(docFix.data().changes && docFix.data().changes.length) {
                              var filterFixesOwner = docFix.data().changes.filter(item => item.user === userDoc.id)
                              if(filterFixesOwner.length) {
                                // order by the last fix
                                filterFixesOwner.sort((a, b) => b.idfix - a.idfix)
                                filterFixesOwner.forEach(element => {
                                  var filterchangesFixesCache = arrayFixes.filter(ele => ele.id === element.id && ele.idError === element.idError && ele.url === element.url)
                                  if(filterchangesFixesCache.length === 0) {
                                    if(arrayProPages.includes(element.url)) {
                                      if(element.idError === 8 || element.idError === 14 || element.idError === 3) {
                                        arrayFixes.push({
                                          id: element.id,
                                          idError: element.idError,
                                          typeError: element.typeError,
                                          old: element.old,
                                          fix: element.fix,
                                          url: element.url,
                                          urlImg: element.urlImg,
                                          date: element.date,
                                          idfix: element.idfix
                                        })
                                      } else {
                                        arrayFixes.push({
                                          id: element.id,
                                          idError: element.idError,
                                          typeError: element.typeError,
                                          old: element.old,
                                          fix: element.fix,
                                          url: element.url,
                                          date: element.date,
                                          idfix: element.idfix
                                        })
                                      }
                                    }
                                  }
                                })
                              }
                            }
                          })

                          docPageFixCacheCollection.forEach(docFixCache => {
                            dbErrors.collection('fixes_cache').doc(docFixCache.id).update({
                              changes: arrayFixes,
                              owner: userDoc.id,
                              ownerEmail: userDoc.data().email ? userDoc.data().email : '',
                              updated: Date.now()
                            })
                            .then(() => {
                              console.log('update fixes owner')
                            })
                            .catch((err) => console.log('err fixes owner', err.message))
                          })

                          var arrayFixesurl = []
                          arrayFixes.forEach(ele => {
                            if(arrayFixesurl.filter(item => item === ele.url).length === 0) {
                              arrayFixesurl.push(ele.url)
                            }
                          })

                          arrayFixesurl.forEach(rowErrorURL => {
                            var filterURLFixesPros = arrayFixes.filter(item => item.url === rowErrorURL)
                            
                            filterURLFixesPros.forEach(rowError => {
                              dbErrors.collection('Errors').where('idpage', '==', rowErrorURL).limit(1).get()
                              .then(docPageError => {
                                var idDocError = ''
                                var changesDocError = []
                                var listErrors = []
                                var changesPage = []
                                docPageError.forEach(docError => {
                                  idDocError = docError.id
                                  changesDocError = docError.data().changes ? docError.data().changes : []
                                  listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                                  changesPage = docError.data().changes ? docError.data().changes : []
                                })

                                // update error status and cont/est
                                var filterErr = []
                                if(rowError.idError === 8) {
                                  // filterErr = listErrors.filter(eleErr => eleErr.id === rowError.id || (eleErr.idc === 3 && eleErr.urlS === rowErrorURL))
                                  filterErr = listErrors.filter(eleErr => eleErr.urlS === rowErrorURL && eleErr.idc === rowError.idError)
                                  // console.log(1,filterErr.length,rowErrorURL,rowError.idError)
                                  if(filterErr.length === 1) {
                                    filterErr.forEach(ele => {
                                      // console.log(ele)
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                        errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                        var originalIE = ele.iE
                                        if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                          ele.iE = false
                                        } else {
                                          listErrors.filter(item => item.urlS === rowErrorURL && rowError.idError === item.idc).length >= 2 ? null : ele.iE = false
                                        }

                                        if(ele.idc === 3) {
                                          ele.est = rowError.fix
                                          ele.cont = rowError.fix
                                          ele.sta = 200
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                        } else {
                                          ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = rowError.fix : ele.cont = rowError.fix
                                          ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                        }
                                        // console.log(rowErrorURL, rowError.idError, ele)
                                        dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                        })
                                        .catch(e => console.log('Error add err: ', e.message))
                                      })
                                      .catch(e => console.log('Error delete err: ', e.message))
                                    })
                                  }
                                } else {
                                  filterErr = listErrors.filter(eleErr => eleErr.urlS === rowErrorURL && eleErr.idc === rowError.idError)
                                  // console.log(2,filterErr.length,rowErrorURL,rowError.idError)
                                  if(filterErr.length === 1) {
                                    filterErr.forEach(ele => {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                        errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                        if(rowError.idError === 8 || rowError.idError === 14 || rowError.idError === 3) {
                                          ele.iE = false
                                        } else {
                                          listErrors.filter(item => item.urlS === rowErrorURL && rowError.idError === item.idc).length >= 2 ? null : ele.iE = false
                                        }
                                        rowError.idError === 13 || rowError.idError === 15 || rowError.idError === 3 ? ele.est = rowError.fix : ele.cont = rowError.fix
                                        rowError.idError === 3 ? ele.sta = 200 : rowError.idError === 8 || rowError.idError === 14 ? ele.est = 200 : null
                                        ele.history && ele.history.length >= 1 ? ele.history.push({id: rowError.id, idc: rowError.idError}) : ele.history = [{id: rowError.id, idc: rowError.idError}]
                                        // console.log(rowErrorURL, rowError.idError, ele)
                                        dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                        })
                                        .catch(e => console.log('Error add err: ', e.message))
                                      })
                                      .catch(e => console.log('Error delete err: ', e.message))
                                    })
                                  }
                                }
                              })
                            })
                          })

                          dbErrors.collection('Errors').where('SiteID', '==', eleSite.id).get()
                          .then(docPageError => {
                            if(docPageError.size) {
                              var arrayErrors = []
                              docPageError.forEach(ele => {
                                if(ele.data().errors && ele.data().errors.length) {
                                  ele.data().errors.forEach(item => {
                                    arrayErrors.push(item)
                                  })
                                }
                              })

                              if(arrayErrors.filter(ele => ele.idc === 1 && ele.iE === true).length) {
                                if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.title) {
                                  db.collection('Sites').doc(eleSite.id).update({
                                    'errorsCategory.title': arrayErrors.filter(ele => ele.idc === 1 && ele.iE === true).length
                                  })
                                }
                              } else if(arrayErrors.filter(ele => ele.idc === 2 && ele.iE === true).length) {
                                if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.desc) {
                                  db.collection('Sites').doc(eleSite.id).update({
                                    'errorsCategory.desc': arrayErrors.filter(ele => ele.idc === 2 && ele.iE === true).length
                                  })
                                }
                              } else if(arrayErrors.filter(ele => ele.idc === 4 && ele.iE === true).length) {
                                if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.h1) {
                                  // if(arrayErrors.filter(ele => ele.idc === 4 && ele.iE === true).length <= 1) {
                                    db.collection('Sites').doc(eleSite.id).update({
                                      'errorsCategory.h1': arrayErrors.filter(ele => ele.idc === 4 && ele.iE === true).length
                                    })
                                  // }
                                }
                              } else if(arrayErrors.filter(ele => ele.idc === 5 && ele.iE === true).length) {
                                // if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.h2) {
                                //   db.collection('Sites').doc(eleSite.id).update({
                                //     'errorsCategory.h2': eleSite.data().errorsCategory.h2 - 1
                                //   })
                                // }
                              } else if(arrayErrors.filter(ele => ele.idc === 8 && ele.iE === true).length) {
                                if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.Imgs) {
                                  db.collection('Sites').doc(eleSite.id).update({
                                    'errorsCategory.Imgs': arrayErrors.filter(ele => ele.idc === 8 && ele.iE === true).length
                                  })
                                }
                              } else if(arrayErrors.filter(ele => ele.idc === 6 && ele.iE === true).length) {
                                if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.wCount) {
                                  db.collection('Sites').doc(eleSite.id).update({
                                    'errorsCategory.wCount': arrayErrors.filter(ele => ele.idc === 6 && ele.iE === true).length
                                  })
                                }
                              } else if(arrayErrors.filter(ele => ele.idc === 13 && ele.iE === true).length) {
                                if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.strings) {
                                  db.collection('Sites').doc(eleSite.id).update({
                                    'errorsCategory.strings': arrayErrors.filter(ele => ele.idc === 13 && ele.iE === true).length
                                  })
                                }
                              } else if(arrayErrors.filter(ele => ele.idc === 3 && ele.iE === true).length) {
                                if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.alts) {
                                  db.collection('Sites').doc(eleSite.id).update({
                                    'errorsCategory.alts': arrayErrors.filter(ele => ele.idc === 3 && ele.iE === true).length
                                  })
                                }
                              } else if(arrayErrors.filter(ele => ele.idc === 9 && ele.iE === true).length) {
                                // if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.dLink) {
                                //   db.collection('Sites').doc(eleSite.id).update({
                                //     'errorsCategory.dLink': arrayErrors.filter(ele => ele.idc === 9 && ele.iE === true).length
                                //   })
                                // }
                              } else if(arrayErrors.filter(ele => ele.idc === 14 && ele.iE === true).length) {
                                if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.missImgs) {
                                  db.collection('Sites').doc(eleSite.id).update({
                                    'errorsCategory.missImgs': arrayErrors.filter(ele => ele.idc === 14 && ele.iE === true).length
                                  })
                                }
                              } else if(arrayErrors.filter(ele => ele.idc === 11 && ele.iE === true).length) {
                                if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.urlCan) {
                                  db.collection('Sites').doc(eleSite.id).update({
                                    'errorsCategory.urlCan': arrayErrors.filter(ele => ele.idc === 11 && ele.iE === true).length
                                  })
                                }
                              } else if(arrayErrors.filter(ele => ele.idc === 10 && ele.iE === true).length) {
                                if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.gPadlock) {
                                  db.collection('Sites').doc(eleSite.id).update({
                                    'errorsCategory.gPadlock': arrayErrors.filter(ele => ele.idc === 10 && ele.iE === true).length
                                  })
                                }
                              } else if(arrayErrors.filter(ele => ele.idc === 15 && ele.iE === true).length) {
                                if(eleSite.data().errorsCategory && eleSite.data().errorsCategory.susp) {
                                  db.collection('Sites').doc(eleSite.id).update({
                                    'errorsCategory.susp': arrayErrors.filter(ele => ele.idc === 15 && ele.iE === true).length
                                  })
                                }
                              }
                            }
                          })

                          if(userDoc.id && userDoc.data().email) {
                            const getFirstUser = userDoc.id.slice(0, 4);
                            const getFirstSite = this.idsite.id.slice(0, 4);
                            var keyID = getFirstUser + getFirstSite

                            var obj = {
                              "author": userDoc.data().email,
                              "css": "",
                              "domain": eleSite.data().homepage.includes('https://') || eleSite.data().homepage.includes('http://') ? eleSite.data().homepage : eleSite.data().homepage,
                              "key": keyID,
                              "mode": 0
                            }
                            // console.log(this.idsite,obj)

                            var config = {
                              method: 'POST',
                              url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
                              headers: {
                                'Content-Type': 'application/json',
                              },
                              data: JSON.stringify(obj),
                            }

                            this.axios(config)
                            .then(response => {
                                console.log('Create fix file: '+response.data.status)
                                // this.verifyFunction()
                            })
                            .catch(e => console.log(e.message))
                          }
                        }
                      }
                    })
                  })

                  var logFix = localStorage.getItem('fixLogMakePro')
                  if(logFix) {
                    // var atomRowErrorParse = JSON.parse(logFix)
                    this.saveChange()
                  } else {
                    setTimeout(() => {
                      this.processSubmit = false
                      this.$emit('update:status', 'change')
                      localStorage.removeItem('viewTSProMakeHistory')
                      var fixCopilot = localStorage.getItem('fixCopilot')
                      if(fixCopilot) {
                        localStorage.removeItem('fixCopilot')
                      } else {
                        localStorage.setItem('fixErrorHistory', JSON.stringify([this.page]))
                      }

                      this.$toast({
                        component: ToastificationContent,
                        props: {
                          title: `Page updated to pro`,
                          icon: 'BellIcon',
                          variant: 'success',
                        },
                      })
                      this.$refs.sidebar_make_pro.hide()
                      var fixCopilot = localStorage.getItem('fixCopilot')
                      if(!fixCopilot) {
                        document.body.style.overflow = 'auto'
                      }
                    }, 4000)
                  }
                }
              })
              .catch(e => {
                this.processSubmit = false
                console.log('Error query site: ', e)
                this.$toast({
                  component: ToastificationContent,
                  props: {
                      title: 'Error make pro page',
                      icon: 'BellIcon',
                      variant: 'danger',
                  },
                })
              })
            })
          // }, 3500)
        }
      }
    },
    saveChange() {
      // console.log('first')
      // this.processSave = true
      // localStorage.setItem('fixLogMakePro', JSON.stringify({payload,optionsChatGPTErrors,siteId,siteSelected,fixText,counterListError}))
      var logFix = localStorage.getItem('fixLogMakePro')
      var atomRowErrorParse = JSON.parse(logFix)
      var payload = atomRowErrorParse.payload
      var optionsChatGPTErrors = atomRowErrorParse.optionsChatGPTErrors
      var siteId = atomRowErrorParse.siteId
      var siteSelected = atomRowErrorParse.siteSelected
      var fixText = atomRowErrorParse.fixText
      var counterListError = atomRowErrorParse.counterListError
      var user = auth.currentUser
      var oldText = ''
      oldText = payload.oldText
      var idProcessSave = payload.fixText
      this.fixText = payload.fixText
      this.catFix = payload.idc
      // console.log(payload);

      var urlWindow = window.location.href
      var urlWindowTransform = new URL(urlWindow)

      // save changes
      db.collection('Users').doc(user.uid).get()
      .then(userDoc => {
          var authUser = {
              nombre: userDoc.data().nombre ? userDoc.data().nombre : '',
              email: userDoc.data().email,
              uid: userDoc.data().uid,
              foto: userDoc.data().foto
          }

          var catRow = payload.idc
          // console.log(optionsChatGPTErrors);
          var errorsGPTFilter = optionsChatGPTErrors.filter(ele => ele.idc === catRow)
          var existRow = errorsGPTFilter[0].exist
          var idERow = errorsGPTFilter[0].id

          db.collection('Sites').doc(siteId).get()
          .then(siteDoc => {
              var listCounterPage = siteDoc.data().errorsPages && siteDoc.data().errorsPages.length ? siteDoc.data().errorsPages : []
              var filterCounterPage = listCounterPage.filter(eleErr => eleErr.url === siteSelected[0].cUrl)
              var docsObjErrors = siteDoc.data().errorsTypePage && siteDoc.data().errorsTypePage.length >= 1 ? siteDoc.data().errorsTypePage : []
              var filterObjErrorsFilter = docsObjErrors.filter(errObj => errObj.url === siteSelected[0].cUrl)
              var filterObjNoSiteErrorsFilter = docsObjErrors.filter(errObj => errObj.url !== siteSelected[0].cUrl)
              if(existRow === true) {
                  filterObjErrorsFilter.forEach(elePage => {
                  if(elePage.errors && elePage.errors.length) {
                      elePage.errors.forEach(eleCounter => {
                      if(payload.idc === 1) {
                          if(eleCounter.type === 'title') {
                          eleCounter.counter -= 1
                          }
                      } else if(payload.idc === 2) {
                          if(eleCounter.type === 'desc') {
                          eleCounter.counter -= 1
                          }
                      } else if(payload.idc === 4) {
                          if(eleCounter.type === 'h1') {
                          counterListError >= 2 ? eleCounter.counter -= 1 : null
                          }
                      } else if(payload.idc === 5) {
                          if(eleCounter.type === 'h2') {
                          eleCounter.counter -= 1
                          }
                      } else if(payload.idc === 8) {
                          if(eleCounter.type === 'imgs') {
                          // ele.beforeIgnore === true ? eleCounter.counter -= 1 : null
                          eleCounter.counter >= 1 ? eleCounter.counter -= 1 : null
                          }
                      } else if(payload.idc === 6) {
                          if(eleCounter.type === 'lWord') {
                          eleCounter.counter -= 1
                          }
                      } else if(payload.idc === 13) {
                          if(eleCounter.type === 'strings') {
                          eleCounter.counter -= 1
                          }
                      } else if(payload.idc === 3) {
                          if(eleCounter.type === 'mAlt') {
                          eleCounter.counter -= 1
                          }
                      } else if(payload.idc === 9) {
                          if(eleCounter.type === 'dLink') {
                          eleCounter.counter -= 1
                          }
                      } else if(payload.idc === 14) {
                          if(eleCounter.type === 'imgs') {
                          // ele.beforeIgnore === true ? eleCounter.counter -= 1 : null
                          eleCounter.counter >= 1 ? eleCounter.counter -= 1 : null
                          }
                      } else if(payload.idc === 11) {
                          if(eleCounter.type === 'urlCan') {
                          eleCounter.counter -= 1
                          }
                      } else if(payload.idc === 10) {
                          // if(eleCounter.type === 'urlCan') {
                          //   eleCounter.counter -= 1
                          // }
                      } else if(payload.idc === 15) {
                          if(eleCounter.type === 'strings') {
                          eleCounter.counter -= 1
                          }
                      }
                      })
                  }
                  })

                  var counterFixErr = 0
                  filterObjErrorsFilter.forEach(eleSiteTypeError => {
                  if(counterFixErr === 0) {
                      counterFixErr += 1
                      filterObjNoSiteErrorsFilter.push(eleSiteTypeError)
                  }
                  })

                  db.collection('Sites').doc(siteDoc.id).update({
                  errorsTypePage: filterObjNoSiteErrorsFilter
                  })
              }

              if(payload.idc === 1) {
                  var arrayPages = siteDoc.data().pages && siteDoc.data().pages.length >= 1 ? siteDoc.data().pages : []
                  var filterPageTitle = arrayPages.filter(ele => ele.url === siteSelected[0].cUrl && ele.status !== null)
                  var filterNoPageTitle = arrayPages.filter(ele => ele.url !== siteSelected[0].cUrl)
                  if(filterPageTitle.length >= 1) {
                  var counterPageT = 0
                  filterPageTitle.forEach(ele => {
                      if(counterPageT === 0) {
                      counterPageT += 1
                      ele.title = payload.fixText
                      filterNoPageTitle.push(ele)
                      }
                  })

                  db.collection('Sites').doc(siteDoc.id).update({
                      pages: filterNoPageTitle
                  })
                  console.log('updated title')
                  }
              }

              dbErrors.collection('fixes').where('idpage', '==', siteSelected[0].cUrl).limit(1).get()
              .then(docPageFixCollection => {
              dbErrors.collection('fixes_cache').where('SiteID', '==', siteDoc.id).limit(1).get()
              .then(docPageFixCacheCollection => {
                  var idDocPageFixCacheColl = ''
                  var changesFixesCache = []
                  docPageFixCacheCollection.forEach(docPageFixCacheColl => {
                      idDocPageFixCacheColl = docPageFixCacheColl.id
                      changesFixesCache = docPageFixCacheColl.data().changes
                  })

                  // exist already fix document
                  if(docPageFixCollection.size >= 1) {
                  var idDocPageFixColl = ''
                  var counterErrorFixes = 0
                  var arrayFixesDomain = []
                  var originalTextFix = ''
                  docPageFixCollection.forEach(docPageFixColl => {
                      idDocPageFixColl = docPageFixColl.id
                      if(docPageFixColl.data().changes && docPageFixColl.data().changes.length >= 1) {
                      docPageFixColl.data().changes.forEach(ele => {
                          arrayFixesDomain.push(ele)
                          if(ele.idfix === 1 && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl && ele.id === payload.idError) {
                          payload.idc === 3 ? originalTextFix = ele.urlImg : originalTextFix = ele.old
                          }
                      })
                      }
                  })
                  // !originalTextFix ? originalTextFix = oldText : null
                  counterErrorFixes = (arrayFixesDomain.filter(ele => ele.idError === payload.idc && ele.url === siteSelected[0].cUrl && ele.id === payload.idError).length) + 1
                  if(arrayFixesDomain.filter(ele => ele.idError === payload.idc && ele.url === siteSelected[0].cUrl && ele.id === payload.idError).length === 0) {
                      payload.idc === 3 ? originalTextFix = payload.img : originalTextFix = oldText
                  }
                  !originalTextFix && payload.idc === 3 ? originalTextFix = payload.img : !originalTextFix ? originalTextFix = oldText : null

                  dbErrors.collection('Errors').where('idpage', '==', siteSelected[0].cUrl).limit(1).get()
                  .then(docPageError => {
                      var idDocError = ''
                      var changesDocError = []
                      var listErrors = []
                      var changesPage = []
                      docPageError.forEach(docError => {
                          idDocError = docError.id
                          changesDocError = docError.data().changes ? docError.data().changes : []
                          listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                          changesPage = docError.data().changes ? docError.data().changes : []
                      })

                      var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                      var filterSizePageExclude = pageSizes.filter(ele => ele.url !== siteSelected[0].cUrl)
                      if(!changesPage.length) {
                      // update asignated pro and sizes
                      db.collection('Sites').doc(siteDoc.id).update({
                          asignatedProPages: true,
                          pageSizes: filterSizePageExclude
                      })

                      // update error status and cont/est
                      var filterErr = []
                      if(payload.idc === 8) {
                          filterErr = listErrors.filter(eleErr => eleErr.id === idERow || (eleErr.cont === oldText && eleErr.idc === 3 && eleErr.urlS === siteSelected[0].cUrl))

                          if(filterErr.length) {
                          filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                              errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                              var originalIE = ele.iE
                              if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                  ele.iE = false
                              } else {
                                  counterListError >= 2 ? null : ele.iE = false
                              }

                              if(ele.idc === 3) {
                                  ele.est = payload.fixTextAlt
                                  ele.cont = payload.fixText
                                  ele.sta = 200
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                  if(originalIE) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })
                                  }
                                  }
                              } else {
                                  ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                  ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                              }
                              dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                              })
                              .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                          })
                          }
                      } else {
                          filterErr = listErrors.filter(eleErr => eleErr.id === idERow)

                          if(filterErr.length) {
                          filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                              errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                              if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                  ele.iE = false
                              } else {
                                  counterListError >= 2 ? null : ele.iE = false
                              }
                              payload.idc === 13 || payload.idc === 15 || payload.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                              payload.idc === 3 ? ele.sta = 200 : payload.idc === 8 || payload.idc === 14 ? ele.est = 200 : null
                              ele.history && ele.history.length >= 1 ? ele.history.push({id: payload.idError, idc: payload.idc}) : ele.history = [{id: payload.idError, idc: payload.idc}]
                              dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                              })
                              .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                          })
                          }
                      }

                      // update error counter category
                      if(payload.idc === 1) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 2) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 4) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                          if(counterListError <= 1) {
                              db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                              })
                          }
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                          //   })
                          // }
                      } else if(payload.idc === 5) {
                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                          //   })
                          // }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                          //   })
                          // }
                      } else if(payload.idc === 8) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 6) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 13) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 3) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 9) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 14) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 11) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 10) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 15) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                          //   })
                          // }
                      }
                      
                      // update error counters by page
                      if(filterCounterPage.length) {
                          filterErr.forEach(ele => {
                          if(ele.counter) {
                              db.collection('Sites').doc(siteDoc.id).update({
                              errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                              ele.counter = ele.counter - 1
                              db.collection('Sites').doc(siteDoc.id).update({
                                  errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                              })
                              })
                          }
                          })
                      }

                      if(changesPage.length) {
                          // update asignated sizes
                          db.collection('Sites').doc(siteDoc.id).update({
                          pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                          if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                              changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: payload.idError,
                                  idError: payload.idc,
                                  typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                  old: originalTextFix,
                                  fix: payload.fixText,
                                  url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: counterErrorFixes
                              }),
                              has_fix: true
                              })
                          } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                              changes: [{
                                  id: payload.idError,
                                  idError: payload.idc,
                                  typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                  old: originalTextFix,
                                  fix: payload.fixText,
                                  url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: counterErrorFixes
                              }],
                              has_fix: true
                              })
                          }
                          }
                      } else {
                          // update asignated sizes
                          db.collection('Sites').doc(siteDoc.id).update({
                          pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                          if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                              changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: payload.idError,
                                  idError: payload.idc,
                                  typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                  old: originalTextFix,
                                  fix: payload.fixText,
                                  url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: counterErrorFixes
                              }),
                              has_fix: true
                              })
                          } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                              changes: [{
                                  id: payload.idError,
                                  idError: payload.idc,
                                  typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                  old: originalTextFix,
                                  fix: payload.fixText,
                                  url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: counterErrorFixes
                              }],
                              has_fix: true
                              })
                          }
                          }
                      }

                      setTimeout(() => {
                          // this.$emit('update:changeerror', true)
                          var arrayChan = []
                          // this.arraychanges.forEach(ele => {
                          //     arrayChan.push(ele)
                          // })
                          arrayChan.push(payload.idError)
                          // this.$emit('update:arraychanges', arrayChan)
                          this.$toast({
                              component: ToastificationContent,
                              props: {
                                  title: `Change applied`,
                                  icon: 'BellIcon',
                                  variant: 'success',
                              },
                          })
                          // this.$refs.sidebar_fix_error.hide()
                          this.sendJSONFix(idDocPageFixCacheColl, payload.idc, siteId, siteSelected)
                          // setTimeout(() => {
                              // this.processSave = false
                          // }, 1300)
                          payload.fixText = ''
                          payload.fixTextAlt = ''
                          oldText = ''
                      }, 1700)
                      } else {
                      // update asignated pro
                      db.collection('Sites').doc(siteDoc.id).update({
                          asignatedProPages: true,
                          pageSizes: filterSizePageExclude
                      })

                      // update error status and cont/est
                      var filterErr = []
                      if(payload.idc === 8) {
                          filterErr = listErrors.filter(eleErr => eleErr.id === idERow || (eleErr.cont === oldText && eleErr.idc === 3 && eleErr.urlS === siteSelected[0].cUrl))

                          if(filterErr.length) {
                          filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                              errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                              var originalIE = ele.iE
                              if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                  ele.iE = false
                              } else {
                                  counterListError >= 2 ? null : ele.iE = false
                              }

                              if(ele.idc === 3) {
                                  ele.est = payload.fixTextAlt
                                  ele.cont = payload.fixText
                                  ele.sta = 200
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                  if(originalIE) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })
                                  }
                                  }
                              } else {
                                  ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                  ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                              }
                              dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                              })
                              .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                          })
                          }
                      } else {
                          filterErr = listErrors.filter(eleErr => eleErr.id === idERow)

                          if(filterErr.length) {
                          filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                              errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                              if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                  ele.iE = false
                              } else {
                                  counterListError >= 2 ? null : ele.iE = false
                              }
                              payload.idc === 13 || payload.idc === 15 || payload.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                              payload.idc === 3 ? ele.sta = 200 : payload.idc === 8 || payload.idc === 14 ? ele.est = 200 : null
                              ele.history && ele.history.length >= 1 ? ele.history.push({id: payload.idError, idc: payload.idc}) : ele.history = [{id: payload.idError, idc: payload.idc}]
                              dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                              })
                              .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                          })
                          }
                      }

                      // update error counter category
                      if(payload.idc === 1) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 2) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 4) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                          if(counterListError <= 1) {
                              db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                              })
                          }
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                          //   })
                          // }
                      } else if(payload.idc === 5) {
                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                          //   })
                          // }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                          //   })
                          // }
                      } else if(payload.idc === 8) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 6) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 13) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 3) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 9) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 14) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 11) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 10) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                          //   })
                          // }
                      } else if(payload.idc === 15) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                          db.collection('Sites').doc(siteDoc.id).update({
                              'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                          })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                          //   db.collection('Sites').doc(siteDoc.id).update({
                          //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                          //   })
                          // }
                      }
                      
                      // update error counters by page
                      if(filterCounterPage.length) {
                          filterErr.forEach(ele => {
                          if(ele.counter) {
                              db.collection('Sites').doc(siteDoc.id).update({
                              errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                              ele.counter = ele.counter - 1
                              db.collection('Sites').doc(siteDoc.id).update({
                                  errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                              })
                              })
                          }
                          })
                      }

                      if(changesPage.length) {
                          // update asignated sizes
                          db.collection('Sites').doc(siteDoc.id).update({
                          pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                          if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                              changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: payload.idError,
                                  idError: payload.idc,
                                  typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                  old: originalTextFix,
                                  fix: payload.fixText,
                                  url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: counterErrorFixes
                              }),
                              has_fix: true
                              })
                          } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                              changes: [{
                                  id: payload.idError,
                                  idError: payload.idc,
                                  typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                  old: originalTextFix,
                                  fix: payload.fixText,
                                  url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: counterErrorFixes
                              }],
                              has_fix: true
                              })
                          }
                          }
                      } else {
                          // update asignated sizes
                          db.collection('Sites').doc(siteDoc.id).update({
                          pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                          if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                              changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: payload.idError,
                                  idError: payload.idc,
                                  typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                  old: originalTextFix,
                                  fix: payload.fixText,
                                  url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: counterErrorFixes
                              }),
                              has_fix: true
                              })
                          } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                              changes: [{
                                  id: payload.idError,
                                  idError: payload.idc,
                                  typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                  old: originalTextFix,
                                  fix: payload.fixText,
                                  url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: counterErrorFixes
                              }],
                              has_fix: true
                              })
                          }
                          }
                      }

                      setTimeout(() => {
                          // this.$emit('update:changeerror', true)
                          var arrayChan = []
                          // this.arraychanges.forEach(ele => {
                          // arrayChan.push(ele)
                          // })
                          arrayChan.push(payload.idError)
                          // this.$emit('update:arraychanges', arrayChan)
                          this.$toast({
                          component: ToastificationContent,
                          props: {
                              title: `Change applied`,
                              icon: 'BellIcon',
                              variant: 'success',
                          },
                          })
                          // this.$refs.sidebar_fix_error.hide()
                          this.sendJSONFix(idDocPageFixCacheColl, payload.idc, siteId, siteSelected)
                          // setTimeout(() => {
                              // this.processSave = false
                          // }, 1300)
                          payload.fixText = ''
                          payload.fixTextAlt = ''
                          oldText = ''
                      }, 1700)
                      }

                      if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                      // console.log('1')
                      if(payload.idc === 3) {
                          dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                          changes: firebase.firestore.FieldValue.arrayUnion({
                              id: payload.idError,
                              idError: payload.idc,
                              typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                              old: payload.idc === 3 ? oldText : originalTextFix,
                              fix: payload.fixText,
                              alt: payload.fixTextAlt,
                              url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                              urlImg: originalTextFix,
                              date: Date.now(),
                              user: authUser.uid,
                              idfix: counterErrorFixes
                          }),
                          has_fix: true,
                          updated: Date.now()
                          })

                          var filterchangesFixesCache8 = changesFixesCache.filter(ele => ele.urlImg === originalTextFix && ele.idError === 8 && ele.url === siteSelected[0].cUrl)
                          if(filterchangesFixesCache8.length === 0) {
                          } else {
                          var filterNochangesFixesCache = []
                          var idChangeCache = ''
                          changesFixesCache.forEach(ele => {
                              if(ele.urlImg === originalTextFix && ele.idError === 8 && ele.url === siteSelected[0].cUrl) {
                              idChangeCache = ele.id
                              } else {
                              filterNochangesFixesCache.push(ele)
                              }
                          })
                          filterNochangesFixesCache.push({
                              id: idChangeCache,
                              idError: 8,
                              typeError: 'Images',
                              old: payload.idc === 3 ? oldText : originalTextFix,
                              fix: payload.fixText,
                              alt: payload.fixTextAlt,
                              url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                              urlImg: originalTextFix,
                              date: Date.now(),
                              // user: authUser,
                              idfix: counterErrorFixes
                          })

                          dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                              changes: firebase.firestore.FieldValue.arrayUnion({
                              id: filterNochangesFixesCache[0].id,
                              idError: 8,
                              typeError: 'Images',
                              old: payload.idc === 3 ? oldText : originalTextFix,
                              fix: payload.fixText,
                              alt: payload.fixTextAlt,
                              url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                              urlImg: originalTextFix,
                              date: Date.now(),
                              user: authUser.uid,
                              idfix: counterErrorFixes
                              }),
                              has_fix: true,
                              updated: Date.now()
                          })

                          dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                              changes: filterNochangesFixesCache,
                              has_fix: true,
                              updated: Date.now()
                          })
                          }

                          var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === payload.idError && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl)
                          if(filterchangesFixesCache.length === 0) {
                          dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                              changes: firebase.firestore.FieldValue.arrayUnion({
                              id: payload.idError,
                              idError: payload.idc,
                              typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                              old: payload.idc === 3 ? oldText : originalTextFix,
                              fix: payload.fixText,
                              alt: payload.fixTextAlt,
                              url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                              urlImg: originalTextFix,
                              date: Date.now(),
                              // user: authUser,
                              idfix: counterErrorFixes
                              }),
                              has_fix: true,
                              updated: Date.now()
                          })
                          } else {
                          // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== payload.idError && ele.idError !== payload.idc && ele.url !== siteSelected[0].cUrl)
                          var filterNochangesFixesCache = []
                          changesFixesCache.forEach(ele => {
                              // console.log(ele)
                              if(ele.id === payload.idError && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl) {
                              // filterNochangesFixesCache.push(ele)
                              // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, siteSelected[0].cUrl)
                              } else {
                              // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, siteSelected[0].cUrl)
                              filterNochangesFixesCache.push(ele)
                              }
                          })
                          // console.log(filterNochangesFixesCache, changesFixesCache)
                          filterNochangesFixesCache.push({
                              id: payload.idError,
                              idError: payload.idc,
                              typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                              old: payload.idc === 3 ? oldText : originalTextFix,
                              fix: payload.fixText,
                              alt: payload.fixTextAlt,
                              url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                              urlImg: originalTextFix,
                              date: Date.now(),
                              // user: authUser,
                              idfix: counterErrorFixes
                          })

                          dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                              changes: filterNochangesFixesCache,
                              has_fix: true,
                              updated: Date.now()
                          })
                          }
                      } else {
                          dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                          changes: firebase.firestore.FieldValue.arrayUnion({
                              id: payload.idError,
                              idError: payload.idc,
                              typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                              old: payload.idc === 3 ? oldText : originalTextFix,
                              fix: payload.fixText,
                              alt: payload.fixTextAlt,
                              url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                              urlImg: originalTextFix,
                              date: Date.now(),
                              user: authUser.uid,
                              idfix: counterErrorFixes
                          }),
                          has_fix: true,
                          updated: Date.now()
                          })

                          var filterchangesFixesCache3 = changesFixesCache.filter(ele => ele.urlImg === originalTextFix && ele.idError === 3 && ele.url === siteSelected[0].cUrl)
                          if(filterchangesFixesCache3.length === 0) {
                          } else {
                          var filterNochangesFixesCache = []
                          var idChangeCache = ''
                          changesFixesCache.forEach(ele => {
                              if(ele.urlImg === originalTextFix && ele.idError === 3 && ele.url === siteSelected[0].cUrl) {
                              idChangeCache = ele.id
                              } else {
                              filterNochangesFixesCache.push(ele)
                              }
                          })
                          filterNochangesFixesCache.push({
                              id: idChangeCache,
                              idError: 3,
                              typeError: 'Missing alts',
                              old: payload.idc === 3 ? oldText : originalTextFix,
                              fix: payload.fixText,
                              alt: payload.fixTextAlt,
                              url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                              urlImg: originalTextFix,
                              date: Date.now(),
                              // user: authUser,
                              idfix: counterErrorFixes
                          })

                          dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                              changes: firebase.firestore.FieldValue.arrayUnion({
                              id: filterNochangesFixesCache[0].id,
                              idError: 3,
                              typeError: 'Missing alts',
                              old: payload.idc === 3 ? oldText : originalTextFix,
                              fix: payload.fixText,
                              alt: payload.fixTextAlt,
                              url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                              urlImg: originalTextFix,
                              date: Date.now(),
                              user: authUser.uid,
                              idfix: counterErrorFixes
                              }),
                              has_fix: true,
                              updated: Date.now()
                          })

                          dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                              changes: filterNochangesFixesCache,
                              has_fix: true,
                              updated: Date.now()
                          })
                          }

                          var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === payload.idError && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl)
                          if(filterchangesFixesCache.length === 0) {
                          dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                              changes: firebase.firestore.FieldValue.arrayUnion({
                              id: payload.idError,
                              idError: payload.idc,
                              typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                              old: payload.idc === 3 ? oldText : originalTextFix,
                              fix: payload.fixText,
                              alt: payload.fixTextAlt,
                              url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                              urlImg: originalTextFix,
                              date: Date.now(),
                              // user: authUser,
                              idfix: counterErrorFixes
                              }),
                              has_fix: true,
                              updated: Date.now()
                          })
                          } else {
                          // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== payload.idError && ele.idError !== payload.idc && ele.url !== siteSelected[0].cUrl)
                          var filterNochangesFixesCache = []
                          changesFixesCache.forEach(ele => {
                              // console.log(ele)
                              if(ele.id === payload.idError && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl) {
                              // filterNochangesFixesCache.push(ele)
                              // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, siteSelected[0].cUrl)
                              } else {
                              // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, siteSelected[0].cUrl)
                              filterNochangesFixesCache.push(ele)
                              }
                          })
                          // console.log(filterNochangesFixesCache, changesFixesCache)
                          filterNochangesFixesCache.push({
                              id: payload.idError,
                              idError: payload.idc,
                              typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                              old: payload.idc === 3 ? oldText : originalTextFix,
                              fix: payload.fixText,
                              alt: payload.fixTextAlt,
                              url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                              urlImg: originalTextFix,
                              date: Date.now(),
                              // user: authUser,
                              idfix: counterErrorFixes
                          })

                          dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                              changes: filterNochangesFixesCache,
                              has_fix: true,
                              updated: Date.now()
                          })
                          }
                      }
                      } else {
                      dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                          changes: firebase.firestore.FieldValue.arrayUnion({
                          id: payload.idError,
                          idError: payload.idc,
                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                          old: originalTextFix,
                          fix: payload.fixText,
                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                          date: Date.now(),
                          user: authUser.uid,
                          idfix: counterErrorFixes
                          }),
                          has_fix: true,
                          updated: Date.now()
                      })

                      var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === payload.idError && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl)
                      // console.log(filterchangesFixesCache)
                      if(filterchangesFixesCache.length === 0) {
                          dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                          changes: firebase.firestore.FieldValue.arrayUnion({
                              id: payload.idError,
                              idError: payload.idc,
                              typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                              old: originalTextFix,
                              fix: payload.fixText,
                              url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                              date: Date.now(),
                              // user: authUser,
                              idfix: counterErrorFixes
                          }),
                          has_fix: true,
                          updated: Date.now()
                          })
                      } else {
                          // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== payload.idError && ele.idError !== payload.idc && ele.url !== siteSelected[0].cUrl)
                          var filterNochangesFixesCache = []
                          changesFixesCache.forEach(ele => {
                          // console.log(ele)
                          if(ele.id === payload.idError && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl) {
                              // filterNochangesFixesCache.push(ele)
                              // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, siteSelected[0].cUrl)
                          } else {
                              // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, siteSelected[0].cUrl)
                              filterNochangesFixesCache.push(ele)
                          }
                          })
                          // console.log(filterNochangesFixesCache, changesFixesCache)
                          filterNochangesFixesCache.push({
                          id: payload.idError,
                          idError: payload.idc,
                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                          old: originalTextFix,
                          fix: payload.fixText,
                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                          date: Date.now(),
                          // user: authUser,
                          idfix: counterErrorFixes
                          })

                          dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                          changes: filterNochangesFixesCache,
                          has_fix: true,
                          updated: Date.now()
                          })
                      }
                      }
                  })
                  } else {
                      if(!idDocPageFixCacheColl) {
                          // doesnt exist fix document
                          dbErrors.collection('fixes_cache').add({
                          SiteID: siteDoc.id,
                          // idpage: siteSelected[0].cUrl,
                          owner: authUser.uid,
                          ownerEmail: authUser.email,
                          changes: [],
                          updated: Date.now()
                          }).then((docRefFixCacheColl) => {
                          idDocPageFixCacheColl = docRefFixCacheColl.id
                          
                          dbErrors.collection('fixes').add({
                              SiteID: siteDoc.id,
                              idpage: siteSelected[0].cUrl,
                              changes: [],
                              updated: Date.now()
                          }).then((docRefFixColl) => {
                              var idDocPageFixColl = docRefFixColl.id
                              // var idDocPageFixCacheColl = docRefFixCacheColl.id

                              dbErrors.collection('Errors').where('idpage', '==', siteSelected[0].cUrl).limit(1).get()
                              .then(docPageError => {
                              var idDocError = ''
                              var changesDocError = []
                              var listErrors = []
                              var changesPage = []
                              docPageError.forEach(docError => {
                                  idDocError = docError.id
                                  changesDocError = docError.data().changes ? docError.data().changes : []
                                  listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                                  changesPage = docError.data().changes ? docError.data().changes : []
                              })

                              var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                              var filterSizePageExclude = pageSizes.filter(ele => ele.url !== siteSelected[0].cUrl)
                              if(!changesPage.length) {
                                  // update asignated pro and sizes
                                  db.collection('Sites').doc(siteDoc.id).update({
                                  asignatedProPages: true,
                                  pageSizes: filterSizePageExclude
                                  })

                                  // update error status and cont/est
                                  var filterErr = []
                                  if(payload.idc === 8) {
                                  filterErr = listErrors.filter(eleErr => eleErr.id === idERow || (eleErr.cont === oldText && eleErr.idc === 3 && eleErr.urlS === siteSelected[0].cUrl))

                                  if(filterErr.length) {
                                      filterErr.forEach(ele => {
                                      // console.log(ele)
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                          var originalIE = ele.iE
                                          if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                          ele.iE = false
                                          } else {
                                          counterListError >= 2 ? null : ele.iE = false
                                          }

                                          if(ele.idc === 3) {
                                          ele.est = payload.fixTextAlt
                                          ele.cont = payload.fixText
                                          ele.sta = 200
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                          if(originalIE) {
                                              if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                              db.collection('Sites').doc(siteDoc.id).update({
                                                  'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                              })
                                              }
                                          }
                                          } else {
                                          ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                          ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                          }
                                          dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                          })
                                          .catch(e => console.log('Error add err: ', e.message))
                                      })
                                      .catch(e => console.log('Error delete err: ', e.message))
                                      })
                                  }
                                  } else {
                                  filterErr = listErrors.filter(eleErr => eleErr.id === idERow)

                                  if(filterErr.length) {
                                      filterErr.forEach(ele => {
                                      // console.log(ele)
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                          if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                          ele.iE = false
                                          } else {
                                          counterListError >= 2 ? null : ele.iE = false
                                          }
                                          payload.idc === 13 || payload.idc === 15 || payload.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                          payload.idc === 3 ? ele.sta = 200 : payload.idc === 8 || payload.idc === 14 ? ele.est = 200 : null
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: payload.idError, idc: payload.idc}) : ele.history = [{id: payload.idError, idc: payload.idc}]
                                          dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                          })
                                          .catch(e => console.log('Error add err: ', e.message))
                                      })
                                      .catch(e => console.log('Error delete err: ', e.message))
                                      })
                                  }
                                  }

                                  // update error counter category
                                  if(payload.idc === 1) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 2) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 4) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                      if(counterListError <= 1) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                          'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                      })
                                      }
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 5) {
                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                  //   })
                                  // }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 8) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 6) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 13) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 3) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 9) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 14) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 11) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 10) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 15) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                  //   })
                                  // }
                                  }
                                  
                                  // update error counters by page
                                  if(filterCounterPage.length) {
                                  filterErr.forEach(ele => {
                                      if(ele.counter) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                          errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                          ele.counter = ele.counter - 1
                                          db.collection('Sites').doc(siteDoc.id).update({
                                          errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                          })
                                      })
                                      }
                                  })
                                  }

                                  if(changesPage.length) {
                                  // update asignated sizes
                                  db.collection('Sites').doc(siteDoc.id).update({
                                      pageSizes: filterSizePageExclude
                                  })

                                  // update Errors page
                                  if(idDocError) {
                                      if(changesDocError.length) {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }),
                                          has_fix: true
                                      })
                                      } else {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: [{
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }],
                                          has_fix: true
                                      })
                                      }
                                  }
                                  } else {
                                  // update asignated sizes
                                  db.collection('Sites').doc(siteDoc.id).update({
                                      pageSizes: filterSizePageExclude
                                  })

                                  // update Errors page
                                  if(idDocError) {
                                      if(changesDocError.length) {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }),
                                          has_fix: true
                                      })
                                      } else {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: [{
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }],
                                          has_fix: true
                                      })
                                      }
                                  }
                                  }

                                  setTimeout(() => {
                                  // this.$emit('update:changeerror', true)
                                  var arrayChan = []
                                  // this.arraychanges.forEach(ele => {
                                  //     arrayChan.push(ele)
                                  // })
                                  arrayChan.push(payload.idError)
                                  // this.$emit('update:arraychanges', arrayChan)
                                  this.$toast({
                                      component: ToastificationContent,
                                      props: {
                                      title: `Change applied`,
                                      icon: 'BellIcon',
                                      variant: 'success',
                                      },
                                  })
                                  // this.$refs.sidebar_fix_error.hide()
                                  this.sendJSONFix(idDocPageFixCacheColl, payload.idc, siteId, siteSelected)
                                  // setTimeout(() => {
                                      // this.processSave = false
                                  // }, 1300)
                                  payload.fixText = ''
                                  payload.fixTextAlt = ''
                                  oldText = ''
                                  }, 1700)
                              } else {
                                  // update asignated pro
                                  db.collection('Sites').doc(siteDoc.id).update({
                                  asignatedProPages: true,
                                  pageSizes: filterSizePageExclude
                                  })

                                  // update error status and cont/est
                                  var filterErr = []
                                  if(payload.idc === 8) {
                                  filterErr = listErrors.filter(eleErr => eleErr.id === idERow || (eleErr.cont === oldText && eleErr.idc === 3 && eleErr.urlS === siteSelected[0].cUrl))

                                  if(filterErr.length) {
                                      filterErr.forEach(ele => {
                                      // console.log(ele)
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                          var originalIE = ele.iE
                                          if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                          ele.iE = false
                                          } else {
                                          counterListError >= 2 ? null : ele.iE = false
                                          }

                                          if(ele.idc === 3) {
                                          ele.est = payload.fixTextAlt
                                          ele.cont = payload.fixText
                                          ele.sta = 200
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                          if(originalIE) {
                                              if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                              db.collection('Sites').doc(siteDoc.id).update({
                                                  'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                              })
                                              }
                                          }
                                          } else {
                                          ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                          ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                          }
                                          dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                          })
                                          .catch(e => console.log('Error add err: ', e.message))
                                      })
                                      .catch(e => console.log('Error delete err: ', e.message))
                                      })
                                  }
                                  } else {
                                  filterErr = listErrors.filter(eleErr => eleErr.id === idERow)

                                  if(filterErr.length) {
                                      filterErr.forEach(ele => {
                                      // console.log(ele)
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                          if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                          ele.iE = false
                                          } else {
                                          counterListError >= 2 ? null : ele.iE = false
                                          }
                                          payload.idc === 13 || payload.idc === 15 || payload.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                          payload.idc === 3 ? ele.sta = 200 : payload.idc === 8 || payload.idc === 14 ? ele.est = 200 : null
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: payload.idError, idc: payload.idc}) : ele.history = [{id: payload.idError, idc: payload.idc}]
                                          dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                          })
                                          .catch(e => console.log('Error add err: ', e.message))
                                      })
                                      .catch(e => console.log('Error delete err: ', e.message))
                                      })
                                  }
                                  }

                                  // update error counter category
                                  if(payload.idc === 1) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 2) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 4) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                      if(counterListError <= 1) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                          'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                      })
                                      }
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 5) {
                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                  //   })
                                  // }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 8) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 6) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 13) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 3) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 9) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 14) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 11) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 10) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 15) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                  //   })
                                  // }
                                  }
                                  
                                  // update error counters by page
                                  if(filterCounterPage.length) {
                                  filterErr.forEach(ele => {
                                      if(ele.counter) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                          errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                          ele.counter = ele.counter - 1
                                          db.collection('Sites').doc(siteDoc.id).update({
                                          errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                          })
                                      })
                                      }
                                  })
                                  }

                                  if(changesPage.length) {
                                  // update asignated sizes
                                  db.collection('Sites').doc(siteDoc.id).update({
                                      pageSizes: filterSizePageExclude
                                  })

                                  // update Errors page
                                  if(idDocError) {
                                      if(changesDocError.length) {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }),
                                          has_fix: true
                                      })
                                      } else {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: [{
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }],
                                          has_fix: true
                                      })
                                      }
                                  }
                                  } else {
                                  // update asignated sizes
                                  db.collection('Sites').doc(siteDoc.id).update({
                                      pageSizes: filterSizePageExclude
                                  })

                                  // update Errors page
                                  if(idDocError) {
                                      if(changesDocError.length) {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }),
                                          has_fix: true
                                      })
                                      } else {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: [{
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }],
                                          has_fix: true
                                      })
                                      }
                                  }
                                  }

                                  setTimeout(() => {
                                  // this.$emit('update:changeerror', true)
                                  var arrayChan = []
                                  // this.arraychanges.forEach(ele => {
                                  //     arrayChan.push(ele)
                                  // })
                                  arrayChan.push(payload.idError)
                                  // this.$emit('update:arraychanges', arrayChan)
                                  this.$toast({
                                      component: ToastificationContent,
                                      props: {
                                      title: `Change applied`,
                                      icon: 'BellIcon',
                                      variant: 'success',
                                      },
                                  })
                                  // this.$refs.sidebar_fix_error.hide()
                                  this.sendJSONFix(idDocPageFixCacheColl, payload.idc, siteId, siteSelected)
                                  // setTimeout(() => {
                                      // this.processSave = false
                                  // }, 1300)
                                  payload.fixText = ''
                                  payload.fixTextAlt = ''
                                  oldText = ''
                                  }, 1700)
                              }

                              if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                  // console.log('2')
                                  dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                  changes: [{
                                      id: payload.idError,
                                      idError: payload.idc,
                                      typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                      old: oldText,
                                      fix: payload.fixText,
                                      alt: payload.fixTextAlt,
                                      url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                      urlImg: payload.idc === 3 ? payload.img : oldText,
                                      date: Date.now(),
                                      user: authUser.uid,
                                      idfix: 1
                                  }],
                                  has_fix: true,
                                  updated: Date.now()
                                  })

                                  dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                  changes: [{
                                      id: payload.idError,
                                      idError: payload.idc,
                                      typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                      old: oldText,
                                      fix: payload.fixText,
                                      alt: payload.fixTextAlt,
                                      url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                      urlImg: payload.idc === 3 ? payload.img : oldText,
                                      date: Date.now(),
                                      // user: authUser,
                                      idfix: 1
                                  }],
                                  has_fix: true,
                                  updated: Date.now()
                                  })
                              } else {
                                  dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                  changes: [{
                                      id: payload.idError,
                                      idError: payload.idc,
                                      typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                      old: oldText,
                                      fix: payload.fixText,
                                      alt: payload.fixTextAlt,
                                      url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                      urlImg: payload.idc === 3 ? payload.img : oldText,
                                      date: Date.now(),
                                      user: authUser.uid,
                                      idfix: 1
                                  }],
                                  has_fix: true,
                                  updated: Date.now()
                                  })

                                  dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                  changes: [{
                                      id: payload.idError,
                                      idError: payload.idc,
                                      typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                      old: oldText,
                                      fix: payload.fixText,
                                      alt: payload.fixTextAlt,
                                      url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                      urlImg: payload.idc === 3 ? payload.img : oldText,
                                      date: Date.now(),
                                      // user: authUser,
                                      idfix: 1
                                  }],
                                  has_fix: true,
                                  updated: Date.now()
                                  })
                              }
                              })
                          })
                          })
                      } else {
                          // doesnt exist fix document
                          dbErrors.collection('fixes').add({
                              SiteID: siteDoc.id,
                              idpage: siteSelected[0].cUrl,
                              changes: [],
                              updated: Date.now()
                          }).then((docRefFixColl) => {
                              var idDocPageFixColl = docRefFixColl.id
                              // var idDocPageFixCacheColl = docRefFixCacheColl.id

                              dbErrors.collection('Errors').where('idpage', '==', siteSelected[0].cUrl).limit(1).get()
                              .then(docPageError => {
                              var idDocError = ''
                              var changesDocError = []
                              var listErrors = []
                              var changesPage = []
                              docPageError.forEach(docError => {
                                  idDocError = docError.id
                                  changesDocError = docError.data().changes ? docError.data().changes : []
                                  listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                                  changesPage = docError.data().changes ? docError.data().changes : []
                              })

                              var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                              var filterSizePageExclude = pageSizes.filter(ele => ele.url !== siteSelected[0].cUrl)
                              if(!changesPage.length) {
                                  // update asignated pro and sizes
                                  db.collection('Sites').doc(siteDoc.id).update({
                                  asignatedProPages: true,
                                  pageSizes: filterSizePageExclude
                                  })

                                  // update error status and cont/est
                                  var filterErr = []
                                  if(payload.idc === 8) {
                                  filterErr = listErrors.filter(eleErr => eleErr.id === idERow || (eleErr.cont === oldText && eleErr.idc === 3 && eleErr.urlS === siteSelected[0].cUrl))

                                  if(filterErr.length) {
                                      filterErr.forEach(ele => {
                                      // console.log(ele)
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                          var originalIE = ele.iE
                                          if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                          ele.iE = false
                                          } else {
                                          counterListError >= 2 ? null : ele.iE = false
                                          }

                                          if(ele.idc === 3) {
                                          ele.est = payload.fixTextAlt
                                          ele.cont = payload.fixText
                                          ele.sta = 200
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                          if(originalIE) {
                                              if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                              db.collection('Sites').doc(siteDoc.id).update({
                                                  'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                              })
                                              }
                                          }
                                          } else {
                                          ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                          ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                          }
                                          dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                          })
                                          .catch(e => console.log('Error add err: ', e.message))
                                      })
                                      .catch(e => console.log('Error delete err: ', e.message))
                                      })
                                  }
                                  } else {
                                  filterErr = listErrors.filter(eleErr => eleErr.id === idERow)

                                  if(filterErr.length) {
                                      filterErr.forEach(ele => {
                                      // console.log(ele)
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                          if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                          ele.iE = false
                                          } else {
                                          counterListError >= 2 ? null : ele.iE = false
                                          }
                                          payload.idc === 13 || payload.idc === 15 || payload.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                          payload.idc === 3 ? ele.sta = 200 : payload.idc === 8 || payload.idc === 14 ? ele.est = 200 : null
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: payload.idError, idc: payload.idc}) : ele.history = [{id: payload.idError, idc: payload.idc}]
                                          dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                          })
                                          .catch(e => console.log('Error add err: ', e.message))
                                      })
                                      .catch(e => console.log('Error delete err: ', e.message))
                                      })
                                  }
                                  }

                                  // update error counter category
                                  if(payload.idc === 1) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 2) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 4) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                      if(counterListError <= 1) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                          'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                      })
                                      }
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 5) {
                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                  //   })
                                  // }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 8) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 6) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 13) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 3) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 9) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 14) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 11) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 10) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 15) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                  //   })
                                  // }
                                  }
                                  
                                  // update error counters by page
                                  if(filterCounterPage.length) {
                                  filterErr.forEach(ele => {
                                      if(ele.counter) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                          errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                          ele.counter = ele.counter - 1
                                          db.collection('Sites').doc(siteDoc.id).update({
                                          errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                          })
                                      })
                                      }
                                  })
                                  }

                                  if(changesPage.length) {
                                  // update asignated sizes
                                  db.collection('Sites').doc(siteDoc.id).update({
                                      pageSizes: filterSizePageExclude
                                  })

                                  // update Errors page
                                  if(idDocError) {
                                      if(changesDocError.length) {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }),
                                          has_fix: true
                                      })
                                      } else {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: [{
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }],
                                          has_fix: true
                                      })
                                      }
                                  }
                                  } else {
                                  // update asignated sizes
                                  db.collection('Sites').doc(siteDoc.id).update({
                                      pageSizes: filterSizePageExclude
                                  })

                                  // update Errors page
                                  if(idDocError) {
                                      if(changesDocError.length) {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }),
                                          has_fix: true
                                      })
                                      } else {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: [{
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }],
                                          has_fix: true
                                      })
                                      }
                                  }
                                  }

                                  setTimeout(() => {
                                  // this.$emit('update:changeerror', true)
                                  var arrayChan = []
                                  // this.arraychanges.forEach(ele => {
                                  //     arrayChan.push(ele)
                                  // })
                                  arrayChan.push(payload.idError)
                                  // this.$emit('update:arraychanges', arrayChan)
                                  this.$toast({
                                      component: ToastificationContent,
                                      props: {
                                      title: `Change applied`,
                                      icon: 'BellIcon',
                                      variant: 'success',
                                      },
                                  })
                                  // this.$refs.sidebar_fix_error.hide()
                                  this.sendJSONFix(idDocPageFixCacheColl, payload.idc, siteId, siteSelected)
                                  // setTimeout(() => {
                                      // this.processSave = false
                                  // }, 1300)
                                  payload.fixText = ''
                                  payload.fixTextAlt = ''
                                  oldText = ''
                                  }, 1700)
                              } else {
                                  // update asignated pro
                                  db.collection('Sites').doc(siteDoc.id).update({
                                  asignatedProPages: true,
                                  pageSizes: filterSizePageExclude
                                  })

                                  // update error status and cont/est
                                  var filterErr = []
                                  if(payload.idc === 8) {
                                  filterErr = listErrors.filter(eleErr => eleErr.id === idERow || (eleErr.cont === oldText && eleErr.idc === 3 && eleErr.urlS === siteSelected[0].cUrl))

                                  if(filterErr.length) {
                                      filterErr.forEach(ele => {
                                      // console.log(ele)
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                          var originalIE = ele.iE
                                          if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                          ele.iE = false
                                          } else {
                                          counterListError >= 2 ? null : ele.iE = false
                                          }

                                          if(ele.idc === 3) {
                                          ele.est = payload.fixTextAlt
                                          ele.cont = payload.fixText
                                          ele.sta = 200
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                          if(originalIE) {
                                              if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                              db.collection('Sites').doc(siteDoc.id).update({
                                                  'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                              })
                                              }
                                          }
                                          } else {
                                          ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                          ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                          }
                                          dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                          })
                                          .catch(e => console.log('Error add err: ', e.message))
                                      })
                                      .catch(e => console.log('Error delete err: ', e.message))
                                      })
                                  }
                                  } else {
                                  filterErr = listErrors.filter(eleErr => eleErr.id === idERow)

                                  if(filterErr.length) {
                                      filterErr.forEach(ele => {
                                      // console.log(ele)
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                          if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                          ele.iE = false
                                          } else {
                                          counterListError >= 2 ? null : ele.iE = false
                                          }
                                          payload.idc === 13 || payload.idc === 15 || payload.idc === 3 ? ele.est = payload.fixText : ele.cont = payload.fixText
                                          payload.idc === 3 ? ele.sta = 200 : payload.idc === 8 || payload.idc === 14 ? ele.est = 200 : null
                                          ele.history && ele.history.length >= 1 ? ele.history.push({id: payload.idError, idc: payload.idc}) : ele.history = [{id: payload.idError, idc: payload.idc}]
                                          dbErrors.collection('Errors').doc(idDocError).update({
                                          errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                          })
                                          .catch(e => console.log('Error add err: ', e.message))
                                      })
                                      .catch(e => console.log('Error delete err: ', e.message))
                                      })
                                  }
                                  }

                                  // update error counter category
                                  if(payload.idc === 1) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 2) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 4) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                                      if(counterListError <= 1) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                          'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                                      })
                                      }
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 5) {
                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                                  //   })
                                  // }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 8) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 6) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 13) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 3) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 9) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 14) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 11) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 10) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                                  //   })
                                  // }
                                  } else if(payload.idc === 15) {
                                  if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                      'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                                      })
                                  }

                                  // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                                  //   db.collection('Sites').doc(siteDoc.id).update({
                                  //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                                  //   })
                                  // }
                                  }
                                  
                                  // update error counters by page
                                  if(filterCounterPage.length) {
                                  filterErr.forEach(ele => {
                                      if(ele.counter) {
                                      db.collection('Sites').doc(siteDoc.id).update({
                                          errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                                      })
                                      .then(() => {
                                          ele.counter = ele.counter - 1
                                          db.collection('Sites').doc(siteDoc.id).update({
                                          errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                          })
                                      })
                                      }
                                  })
                                  }

                                  if(changesPage.length) {
                                  // update asignated sizes
                                  db.collection('Sites').doc(siteDoc.id).update({
                                      pageSizes: filterSizePageExclude
                                  })

                                  // update Errors page
                                  if(idDocError) {
                                      if(changesDocError.length) {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }),
                                          has_fix: true
                                      })
                                      } else {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: [{
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }],
                                          has_fix: true
                                      })
                                      }
                                  }
                                  } else {
                                  // update asignated sizes
                                  db.collection('Sites').doc(siteDoc.id).update({
                                      pageSizes: filterSizePageExclude
                                  })

                                  // update Errors page
                                  if(idDocError) {
                                      if(changesDocError.length) {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }),
                                          has_fix: true
                                      })
                                      } else {
                                      dbErrors.collection('Errors').doc(idDocError).update({
                                          changes: [{
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          user: authUser,
                                          idfix: 1
                                          }],
                                          has_fix: true
                                      })
                                      }
                                  }
                                  }

                                  setTimeout(() => {
                                  // this.$emit('update:changeerror', true)
                                  var arrayChan = []
                                  // this.arraychanges.forEach(ele => {
                                  //     arrayChan.push(ele)
                                  // })
                                  arrayChan.push(payload.idError)
                                  // this.$emit('update:arraychanges', arrayChan)
                                  this.$toast({
                                      component: ToastificationContent,
                                      props: {
                                      title: `Change applied`,
                                      icon: 'BellIcon',
                                      variant: 'success',
                                      },
                                  })
                                  // this.$refs.sidebar_fix_error.hide()
                                  this.sendJSONFix(idDocPageFixCacheColl, payload.idc, siteId, siteSelected)
                                  // setTimeout(() => {
                                      // this.processSave = false
                                  // }, 1300)
                                  payload.fixText = ''
                                  payload.fixTextAlt = ''
                                  oldText = ''
                                  }, 1700)
                              }

                              if(payload.idc === 8 || payload.idc === 14 || payload.idc === 3) {
                                  // console.log('3')
                                  dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                  changes: [{
                                      id: payload.idError,
                                      idError: payload.idc,
                                      typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                      old: oldText,
                                      fix: payload.fixText,
                                      alt: payload.fixTextAlt,
                                      url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                      urlImg: payload.idc === 3 ? payload.img : oldText,
                                      date: Date.now(),
                                      user: authUser.uid,
                                      idfix: 1
                                  }],
                                  has_fix: true,
                                  updated: Date.now()
                                  })

                                  if(changesFixesCache.length >= 1) {
                                  if(payload.idc === 3) {
                                      var filterchangesFixesCache8 = changesFixesCache.filter(ele => ele.urlImg === oldText && ele.idError === 8 && ele.url === siteSelected[0].cUrl)
                                      if(filterchangesFixesCache8.length === 0) {
                                      } else {
                                      var filterNochangesFixesCache = []
                                      var idChangeCache = ''
                                      changesFixesCache.forEach(ele => {
                                          if(ele.urlImg === oldText && ele.idError === 8 && ele.url === siteSelected[0].cUrl) {
                                          idChangeCache = ele.id
                                          } else {
                                          filterNochangesFixesCache.push(ele)
                                          }
                                      })
                                      filterNochangesFixesCache.push({
                                          id: idChangeCache,
                                          idError: 8,
                                          typeError: 'Images',
                                          old: oldText,
                                          fix: payload.fixText,
                                          alt: payload.fixTextAlt,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          urlImg: payload.idc === 3 ? payload.img : oldText,
                                          date: Date.now(),
                                          // user: authUser,
                                          idfix: counterErrorFixes
                                      })

                                      dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                          changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: filterNochangesFixesCache[0].id,
                                          idError: 8,
                                          typeError: 'Images',
                                          old: oldText,
                                          fix: payload.fixText,
                                          alt: payload.fixTextAlt,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          urlImg: payload.idc === 3 ? payload.img : oldText,
                                          date: Date.now(),
                                          user: authUser.uid,
                                          idfix: counterErrorFixes
                                          }),
                                          has_fix: true,
                                          updated: Date.now()
                                      })

                                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                          changes: filterNochangesFixesCache,
                                          has_fix: true,
                                          updated: Date.now()
                                      })
                                      }

                                      var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === payload.idError && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl)
                                      if(filterchangesFixesCache.length === 0) {
                                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                          changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          alt: payload.fixTextAlt,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          urlImg: payload.idc === 3 ? payload.img : oldText,
                                          date: Date.now(),
                                          // user: authUser,
                                          idfix: 1
                                          }),
                                          has_fix: true,
                                          updated: Date.now()
                                      })
                                      } else {
                                      // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== payload.idError && ele.idError !== payload.idc && ele.url !== siteSelected[0].cUrl)
                                      var filterNochangesFixesCache = []
                                      changesFixesCache.forEach(ele => {
                                          // console.log(ele)
                                          if(ele.id === payload.idError && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl) {
                                          // filterNochangesFixesCache.push(ele)
                                          // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, siteSelected[0].cUrl)
                                          } else {
                                          // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, siteSelected[0].cUrl)
                                          filterNochangesFixesCache.push(ele)
                                          }
                                      })
                                      // console.log(filterNochangesFixesCache, changesFixesCache)
                                      filterNochangesFixesCache.push({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          alt: payload.fixTextAlt,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          urlImg: payload.idc === 3 ? payload.img : oldText,
                                          date: Date.now(),
                                          // user: authUser,
                                          idfix: 1
                                      })

                                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                          changes: filterNochangesFixesCache,
                                          has_fix: true,
                                          updated: Date.now()
                                      })
                                      }
                                  } else {
                                      var filterchangesFixesCache3 = changesFixesCache.filter(ele => ele.urlImg === oldText && ele.idError === 3 && ele.url === siteSelected[0].cUrl)
                                      if(filterchangesFixesCache3.length === 0) {
                                      } else {
                                      var filterNochangesFixesCache = []
                                      var idChangeCache = ''
                                      changesFixesCache.forEach(ele => {
                                          if(ele.urlImg === oldText && ele.idError === 3 && ele.url === siteSelected[0].cUrl) {
                                          idChangeCache = ele.id
                                          } else {
                                          filterNochangesFixesCache.push(ele)
                                          }
                                      })
                                      filterNochangesFixesCache.push({
                                          id: idChangeCache,
                                          idError: 3,
                                          typeError: 'Missing alts',
                                          old: oldText,
                                          fix: payload.fixText,
                                          alt: payload.fixTextAlt,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          urlImg: payload.idc === 3 ? payload.img : oldText,
                                          date: Date.now(),
                                          // user: authUser,
                                          idfix: counterErrorFixes
                                      })

                                      dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                          changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: filterNochangesFixesCache[0].id,
                                          idError: 3,
                                          typeError: 'Missing alts',
                                          old: oldText,
                                          fix: payload.fixText,
                                          alt: payload.fixTextAlt,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          urlImg: payload.idc === 3 ? payload.img : oldText,
                                          date: Date.now(),
                                          user: authUser.uid,
                                          idfix: counterErrorFixes
                                          }),
                                          has_fix: true,
                                          updated: Date.now()
                                      })

                                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                          changes: filterNochangesFixesCache,
                                          has_fix: true,
                                          updated: Date.now()
                                      })
                                      }

                                      var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === payload.idError && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl)
                                      if(filterchangesFixesCache.length === 0) {
                                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                          changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          alt: payload.fixTextAlt,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          urlImg: payload.idc === 3 ? payload.img : oldText,
                                          date: Date.now(),
                                          // user: authUser,
                                          idfix: 1
                                          }),
                                          has_fix: true,
                                          updated: Date.now()
                                      })
                                      } else {
                                      // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== payload.idError && ele.idError !== payload.idc && ele.url !== siteSelected[0].cUrl)
                                      var filterNochangesFixesCache = []
                                      changesFixesCache.forEach(ele => {
                                          // console.log(ele)
                                          if(ele.id === payload.idError && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl) {
                                          // filterNochangesFixesCache.push(ele)
                                          // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, siteSelected[0].cUrl)
                                          } else {
                                          // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, siteSelected[0].cUrl)
                                          filterNochangesFixesCache.push(ele)
                                          }
                                      })
                                      // console.log(filterNochangesFixesCache, changesFixesCache)
                                      filterNochangesFixesCache.push({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          alt: payload.fixTextAlt,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          urlImg: payload.idc === 3 ? payload.img : oldText,
                                          date: Date.now(),
                                          // user: authUser,
                                          idfix: 1
                                      })

                                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                          changes: filterNochangesFixesCache,
                                          has_fix: true,
                                          updated: Date.now()
                                      })
                                      }
                                  }
                                  } else {
                                  dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                      changes: [{
                                      id: payload.idError,
                                      idError: payload.idc,
                                      typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                      old: oldText,
                                      fix: payload.fixText,
                                      alt: payload.fixTextAlt,
                                      url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                      urlImg: payload.idc === 3 ? payload.img : oldText,
                                      date: Date.now(),
                                      // user: authUser,
                                      idfix: 1
                                      }],
                                      has_fix: true,
                                      updated: Date.now()
                                  })
                                  }
                              } else {
                                  dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                  changes: [{
                                      id: payload.idError,
                                      idError: payload.idc,
                                      typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                      old: oldText,
                                      fix: payload.fixText,
                                      url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                      date: Date.now(),
                                      user: authUser.uid,
                                      idfix: 1
                                  }],
                                  has_fix: true,
                                  updated: Date.now()
                                  })

                                  if(changesFixesCache.length >= 1) {
                                  var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === payload.idError && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl)
                                  // console.log(filterchangesFixesCache)
                                  if(filterchangesFixesCache.length === 0) {
                                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                      changes: firebase.firestore.FieldValue.arrayUnion({
                                          id: payload.idError,
                                          idError: payload.idc,
                                          typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                          old: oldText,
                                          fix: payload.fixText,
                                          url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                          date: Date.now(),
                                          // user: authUser,
                                          idfix: 1
                                      }),
                                      has_fix: true,
                                      updated: Date.now()
                                      })
                                  } else {
                                      // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== payload.idError && ele.idError !== payload.idc && ele.url !== siteSelected[0].cUrl)
                                      var filterNochangesFixesCache = []
                                      changesFixesCache.forEach(ele => {
                                      // console.log(ele)
                                      if(ele.id === payload.idError && ele.idError === payload.idc && ele.url === siteSelected[0].cUrl) {
                                          // filterNochangesFixesCache.push(ele)
                                          // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, siteSelected[0].cUrl)
                                      } else {
                                          // console.log(ele.id, payload.idError, ele.idError, payload.idc, ele.url, siteSelected[0].cUrl)
                                          filterNochangesFixesCache.push(ele)
                                      }
                                      })
                                      // console.log(filterNochangesFixesCache, changesFixesCache)
                                      filterNochangesFixesCache.push({
                                      id: payload.idError,
                                      idError: payload.idc,
                                      typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                      old: oldText,
                                      fix: payload.fixText,
                                      url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                      date: Date.now(),
                                      // user: authUser,
                                      idfix: 1
                                      })

                                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                      changes: filterNochangesFixesCache,
                                      has_fix: true,
                                      updated: Date.now()
                                      })
                                  }
                                  } else {
                                  dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                      changes: [{
                                      id: payload.idError,
                                      idError: payload.idc,
                                      typeError: payload.idc === 1 ? 'Missing titles' : payload.idc === 2 ? 'Missing descriptions' : payload.idc === 11 ? 'URL canonalization' : payload.idc === 3 ? 'Missing alts' : payload.idc === 4 ? 'Headings H1' : payload.idc === 5 ? 'Headings H2' : payload.idc === 6 ? 'Low word count' : payload.idc === 8 ? 'Images' : payload.idc === 14 ? 'Images' : payload.idc === 9 ? 'Dead link checker' : payload.idc === 10 ? 'Canonicals' : payload.idc === 13 ? 'Strings' : payload.idc === 15 ? 'Suspicious' : payload.idc === 7 ? 'Externals' : '',
                                      old: oldText,
                                      fix: payload.fixText,
                                      url: siteSelected[0].cUrl ? siteSelected[0].cUrl : '',
                                      date: Date.now(),
                                      // user: authUser,
                                      idfix: 1
                                      }],
                                      has_fix: true,
                                      updated: Date.now()
                                  })
                                  }
                              }
                              })
                          })
                      }
                  }
              })
              })
          })
      
      })

      // v-if="permission === 'me' && pros.includes(item.url)"
    },
    sendJSONFix(val, idc, siteId, siteSelected) {
      if(idc !== 7) {
        const user = auth.currentUser
        if(user) {
            db.collection('Users').doc(user.uid).get()
            .then(userDoc => {
                setTimeout(() => {
                    dbErrors.collection('fixes_cache').doc(val).get()
                    .then(docFixCache => {
                        var urlTransform = new URL(siteSelected[0].cUrl)
                        var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                        const getFirstUser = userDoc.id.slice(0, 4);
                        const getFirstSite = siteId.slice(0, 4);
                        // const getFirstProject = this.$route.params.project.slice(0, 3);
                        var keyID = getFirstUser + getFirstSite

                        var obj = {}
                        obj.author = userDoc.data().email
                        obj.css = ""
                        obj.domain = domainConverter
                        obj.key = keyID
                        obj.mode = 1

                        var arraychanges = docFixCache.data().changes && docFixCache.data().changes.length ? docFixCache.data().changes : []
                        var pagesAlreadyProcessed = []
                        arraychanges.forEach(ele => {
                            if(pagesAlreadyProcessed.filter(item => item === ele.url).length === 0) {
                                var urlTransform = new URL(ele.url)
                                // var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                                pagesAlreadyProcessed.push(ele.url)
                                var pathURLConverter = urlTransform.pathname === '/' && !ele.url.endsWith('/') ? ele.url + '/' : ele.url
                                var encodedURL = btoa(pathURLConverter)
                                obj[encodedURL] = {
                                    "title": "",
                                    "path": urlTransform.pathname,
                                    "url": ele.url,
                                    "description": "",
                                    "canonical": "",
                                    "siteName": "",
                                    "alt": [],
                                    "src": [],
                                    "dlinks": [],
                                    "gp": [],
                                    "tags": {},
                                    "tgstxt": {},
                                    "findAndReplace": []
                                }

                                var filterFixesOnlyEachPage = arraychanges.filter(item => item.url === ele.url)
                                filterFixesOnlyEachPage.forEach(item => {
                                    if(item.idError === 1) {
                                        // title
                                        obj[encodedURL].title = item.fix
                                        // obj[encodedURL].siteName = item.fix
                                    } else if(item.idError === 2) {
                                        // desc
                                        obj[encodedURL].description = item.fix
                                    } else if(item.idError === 4) {
                                        // h1
                                        if(obj[encodedURL].tgstxt.h1 && obj[encodedURL].tgstxt.h1.length) {
                                        obj[encodedURL].tgstxt.h1.push({
                                            txt: item.old,
                                            ntx: item.fix
                                        })
                                        } else {
                                        obj[encodedURL].tgstxt.h1 = [{
                                            txt: item.old,
                                            ntx: item.fix
                                        }]
                                        }
                                    } else if(item.idError === 5) {
                                        // h2
                                        if(obj[encodedURL].tgstxt.h2 && obj[encodedURL].tgstxt.h2.length) {
                                        obj[encodedURL].tgstxt.h2.push({
                                            txt: item.old,
                                            ntx: item.fix
                                        })
                                        } else {
                                        obj[encodedURL].tgstxt.h2 = [{
                                            txt: item.old,
                                            ntx: item.fix
                                        }]
                                        }
                                    } else if(item.idError === 8) {
                                        // imgs
                                        if(obj[encodedURL].src && obj[encodedURL].src.length) {
                                        obj[encodedURL].src.push({
                                            url: item.old,
                                            src: item.fix
                                        })
                                        } else {
                                        obj[encodedURL].src = [{
                                            url: item.old,
                                            src: item.fix
                                        }]
                                        }

                                        if(item.alt) {
                                        if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                            obj[encodedURL].alt.push({
                                            url: item.fix,
                                            alt: item.alt
                                            })
                                        } else {
                                            obj[encodedURL].alt = [{
                                            url: item.fix,
                                            alt: item.alt
                                            }]
                                        }
                                        }
                                    } else if(item.idError === 6) {
                                        // lWord
                                        // 08-03-2024 no available fix/edit (word count is value from crawl page)
                                    } else if(item.idError === 13) {
                                        // strings
                                        if(item.findAndReplace) {
                                        if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                                            obj[encodedURL].findAndReplace.push({
                                            old: item.old,
                                            new: item.fix
                                            })
                                        } else {
                                            obj[encodedURL].findAndReplace = [{
                                            old: item.old,
                                            new: item.fix
                                            }]
                                        }
                                        }
                                    } else if(item.idError === 3) {
                                        // mAlt
                                        if(item.alt) {
                                        if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                            obj[encodedURL].alt.push({
                                            url: item.urlImg,
                                            alt: item.fix
                                            })
                                        } else {
                                            obj[encodedURL].alt = [{
                                            url: item.urlImg,
                                            alt: item.fix
                                            }]
                                        }
                                        }
                                    } else if(item.idError === 9) {
                                        // dLink
                                        if(item.dlinks) {
                                        if(obj[encodedURL].dlinks && obj[encodedURL].dlinks.length) {
                                            obj[encodedURL].dlinks.push({
                                            url: item.old,
                                            rmp: item.fix
                                            })
                                        } else {
                                            obj[encodedURL].dlinks = [{
                                            url: item.old,
                                            rmp: item.fix
                                            }]
                                        }
                                        }
                                    } else if(item.idError === 14) {
                                        // missimgs
                                        if(obj[encodedURL].src && obj[encodedURL].src.length) {
                                        obj[encodedURL].src.push({
                                            url: item.old,
                                            src: item.fix
                                        })
                                        } else {
                                        obj[encodedURL].src = [{
                                            url: item.old,
                                            src: item.fix
                                        }]
                                        }

                                        if(item.alt) {
                                        if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                                            obj[encodedURL].alt.push({
                                            url: item.fix,
                                            alt: item.alt
                                            })
                                        } else {
                                            obj[encodedURL].alt = [{
                                            url: item.fix,
                                            alt: item.alt
                                            }]
                                        }
                                        }
                                    } else if(item.idError === 11) {
                                        // urlCan
                                        obj[encodedURL].canonical = item.fix
                                    } else if(item.idError === 10) {
                                        // greenPadlockErrors
                                    } else if(item.idError === 15) {
                                        // Suspicious
                                        if(item.findAndReplace) {
                                        if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                                            obj[encodedURL].findAndReplace.push({
                                            old: item.old,
                                            new: item.fix
                                            })
                                        } else {
                                            obj[encodedURL].findAndReplace = [{
                                            old: item.old,
                                            new: item.fix
                                            }]
                                        }
                                        }
                                    }
                                })
                            }
                        })

                        setTimeout(() => {
                            // console.log(obj);
                            var config = {
                                method: 'POST',
                                url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
                                headers: {
                                    'Content-Type': 'application/json',
                                },
                                data: JSON.stringify(obj),
                            }

                            this.axios(config)
                            .then(response => {
                                dbErrors.collection('fixes_cache').doc(val).update({
                                    api_file_request: obj
                                })
                                
                                console.log('fixes file updated: '+response.data.status)
                            })
                            .catch(e => console.log(e.message))
                        }, 4000)
                    })
                }, 2000)

                setTimeout(() => {
                  this.processSubmit = false
                  this.$emit('update:status', 'change')
                  localStorage.removeItem('viewTSProMakeHistory')
                  localStorage.removeItem('fixLogMakePro')
                  var fixCopilot = localStorage.getItem('fixCopilot')
                  if(fixCopilot) {
                    localStorage.removeItem('fixCopilot')
                    this.catFix === 1 ? localStorage.setItem('newTitle', this.fixText) : null
                    this.catFix === 2 ? localStorage.setItem('newDesc', this.fixText) : null
                  } else {
                    localStorage.setItem('fixErrorHistory', JSON.stringify([this.page]))
                  }

                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: `Page updated to pro`,
                      icon: 'BellIcon',
                      variant: 'success',
                    },
                  })
                  this.$refs.sidebar_make_pro.hide()
                  var fixCopilot = localStorage.getItem('fixCopilot')
                  if(!fixCopilot) {
                    document.body.style.overflow = 'auto'
                  }
                }, 4000)
            })
        }
      }
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-make-pro {
    width: 90%;
    max-width: 593px !important;
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>
