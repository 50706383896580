<template>
<div>
    <!-- <b-img-lazy
        v-once
        width="238"
        height="240"
        :src="imageSrc"
        alt="Missing img"
    /> -->
    <img
      style="width: 100%; height: 250px;"
      :src="imageSrc"
      alt="Missing img"
    />
</div>
</template>

<script>
import { BImgLazy, } from 'bootstrap-vue'

export default {
    components: {
        BImgLazy
    },
    props: {
        status: {
        type: Number,
        default: 0
        },
        img: {
        type: String,
        default: ''
        }
    },
    data() {
        return {
            imageSrc: this.getImageSrc()
        };
    },
    //   methods: {
    //     getImageSrc() {
    //       return this.status === 0 
    //         ? require('@/assets/images/pages/auth-v1-top-bg.png') 
    //         : this.img;
    //     }
    //   },
    watch: {
        status: 'updateImageSrc',
        img: 'updateImageSrc'
    },
    methods: {
        updateImageSrc() {
            this.imageSrc = this.getImageSrc();
        },
        getImageSrc() {
            return this.status === 0 
                ? require('@/assets/images/pages/auth-v1-top-bg.png') 
                : this.img;
        }
    }
//     components: {
//         BImgLazy,
//     },
//     props: {
//     status: {
//       type: Number,
//       default: 0
//     },
//     img: {
//       type: String,
//       default: ''
//     }
//   },
//   data() {
//     return {
//     };
//   },
//   computed: {
//     shouldRender() {
//       // Solo renderiza la imagen si `img` está disponible o `status` es 0
//       return this.status === 0 || this.img !== '';
//     },
//     imgKey() {
//       // Solo cambia la clave si `img` es diferente, evitando re-renders innecesarios
//       return this.status === 0 ? 'default-img' : this.img;
//     },
//     imageSrc() {
//       // Calcula la ruta solo una vez, minimizando el recalculo+
//       const src = this.status === 0 
//         ? require('@/assets/images/pages/auth-v1-top-bg.png') 
//         : this.img;

//         console.log('Image src computed:', src);
//         return src;
//     }
//   },
//   watch: {
//     // img(val) {
//     //     console.log(val);
//     // }
//   }
}
</script>