<template>
  <div>
    <b-sidebar
      id="sidebar-right-fix-error"
      ref="sidebar_fix_error"
      :visible="showOpenFixError"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-fix-error', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-2">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>
        <hr v-if="idsite.label">
        <h3
          class="mb-0 pb-0 pt-0 ml-0"
          style="font-weight: 700; color: #181E38 !important; padding: 20px;"
        >
          Fix {{ rowError.idc === 7 ? 'External URL' : rowError.idc === 1 ? 'Title' : rowError.idc === 2 ? 'Description' : rowError.idc === 4 ? 'Heading H1' : rowError.idc === 5 ? 'Heading H2' : rowError.idc === 6 ? 'Word count' : rowError.idc === 13 ? 'String' : rowError.idc === 8 ? 'Image' : rowError.idc === 9 ? 'Dead link' : rowError.idc === 3 ? 'Alt tag' : rowError.idc === 14 ? 'Missing image' : rowError.idc === 11 ? 'Canonical url' : rowError.idc === 15 ? 'Suspicious text' : '' }}
        </h3>
        <div
          class="b-sidebar-body mt-0"
          style="padding: 20px;"
        >
          <b-row v-if="rowError.idc !== 7">
            <b-col md="12">
              <!-- <b-form-group
                label="Pro Page:"
                class=""
              > -->
                <!-- <v-select
                  v-model="pageTitleSelect"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionsProPage"
                  :clearable="false"
                  readonly
                /> -->
                <b-card-text
                  class="mb-25 pt-0"
                  style="font-weight: 700; color: #181E38; font-size: 15px;"
                >
                  Page:
                </b-card-text>
                <p style="color: rgb(45, 45, 45); margin-bottom: 0px;">{{ rowError.name === 'home' ? 'Homepage' : rowError.name }}</p>
                <p style="color: #8e8a8a; margin-top: 5px;">
                  <a :href="rowError.domain" target="_blank" style="text-decoration: none !important; color: #8e8a8a !important;">
                    <feather-icon
                      icon="ExternalLinkIcon"
                      style="position: relative; top: -2.5px;"
                      class="mr-25"
                    />
                    {{ rowError.domain }}
                  </a>
                </p>
              <!-- </b-form-group> -->
            </b-col>
            <!-- <b-col md="6">
              <b-form-group
                label="Error"
                class=""
              >
                <v-select
                  v-model="selectedError"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="optionsErrors"
                  :clearable="false"
                />
              </b-form-group>
            </b-col> -->
          </b-row>
          <b-row>
            <b-col md="2">
              <b-img-lazy
                :src="errSitesImg"
                width="100px"
                height="35px"
                class="mt-2"
                loading="lazy"
              />
            </b-col>
            <b-col md="10">
              <p
                class="mb-0"
                style="color: #656B85;"
              >
                <feather-icon
                  icon="ToolIcon"
                  style="fill: #656b85;"
                  class="mr-50"
                />How to fix {{ selectedError }}
              </p>
              <span class="hurmbeRegular">Donec vitae ex ut lectus pretium porta. In interdum ligula ac elementum aliquet. Suggestions: Lorem, ipsum, dolor, sit, amet.</span>
            </b-col>
          </b-row>
        </div>

        <div style="padding-bottom: 80px;">
          <div
            class="mt-2 py-2"
            style="background: #7838FF0D; border-left: 4px solid #A7AAB9;"
          >
            <b-form-group
              label-for="current"
              class="color-text-grey  px-2"
            >
              <template
                v-slot:label
              >
                <b-button
                  style="background: #F0F1F7 !important; border: 1px solid #F0F1F7 !important; width: 93px; height: 32px; border-radius: 5px; padding-left: 0px; padding-right: 0px;"
                  class="float-right pt-50"
                  variant="primary"
                  @click.prevent="rowError.history ? watchHistoryError() : null"
                  v-if="rowError.idc !== 7"
                >
                  <span
                    :style="rowError.history ? 'color: rgb(48, 205, 255)' : 'color: #656B85;'"
                    class="pt-0 px-0"
                  >
                    History
                  </span>
                  <feather-icon
                    icon="TrendingUpIcon"
                    :style="rowError.history ? 'color: rgb(48, 205, 255)' : 'color: #656B85;'"
                  />
                </b-button>
                <span style="font-size: 15px; color: #656B85;">Current {{ rowError.idc === 7 ? 'External URL' : rowError.idc === 1 ? 'Title' : rowError.idc === 2 ? 'Description' : rowError.idc === 4 ? 'Heading H1' : rowError.idc === 5 ? 'Heading H2' : rowError.idc === 6 ? 'Word count' : rowError.idc === 13 ? 'String' : rowError.idc === 8 ? 'Image' : rowError.idc === 9 ? 'Dead link' : rowError.idc === 3 ? 'Alt tag' : rowError.idc === 14 ? 'Missing image' : rowError.idc === 11 ? 'Canonical url' : rowError.idc === 15 ? 'Suspicious text' : '' }}</span>
              </template>
              <b-form-textarea
                v-model="oldText"
                class="mt-1 "
                id="textarea-default"
                placeholder=""
                style="background: #fff; border: 1px solid #d8d6de; border-radius: 3px;"
                rows="3"
                plaintext
                v-if="rowError.idc === 13 || rowError.idc === 15"
              />
              <b-form-input
                v-model="oldText"
                class="mt-1 "
                placeholder=""
                size="lg"
                style="height: 44px;"
                readonly
                v-else
              />
            </b-form-group>
          </div>

          <div style="border-left: 4px solid #7838FF; height: 100%;" class="pt-1">
            <b-tabs class="ml-75">
              <b-tab @click="changeTabFix" title="Manual">
                <validation-observer ref="simpleRules" v-slot="{ invalid }">
                  <!-- <b-form> -->
                    <b-form-group
                      label-for="fixtext"
                      class="color-text-grey px-2 pt-2"
                    >
                      <template
                        v-slot:label
                        style="font-weight: 700;"
                      >
                        <span
                          style="font-weight: 700; font-size: 15px; color: #7838FF;"
                          class=""
                        >New {{ rowError.idc === 1 ? 'Title' : rowError.idc === 2 ? 'Description' : rowError.idc === 4 ? 'H1' : rowError.idc === 5 ? 'Heading H2' : rowError.idc === 6 ? 'Word count' : rowError.idc === 13 ? 'String' : rowError.idc === 8 ? 'Image' : rowError.idc === 9 ? 'Dead link' : rowError.idc === 3 ? 'Alt' : rowError.idc === 14 ? 'Url image' : rowError.idc === 11 ? 'Canonical' : rowError.idc === 15 ? 'Suspicious' : '' }}</span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        :rules="rowError.idc === 14 || rowError.idc === 8 ? 'required|url|validimg' : rowError.idc === 9 || rowError.idc === 11 || rowError.idc === 7 ? 'required|url' : 'required'"
                        name="fixtext"
                      >
                        <b-form-textarea
                          v-model="fixText"
                          class="mt-1 "
                          id="textarea-default"
                          placeholder="New value"
                          rows="3"
                          :state="errors.length > 0 ? false:null"
                          @keyup.enter.native="onEnterPressed"
                          v-if="rowError.idc === 13 || rowError.idc === 15"
                        />
                        <b-form-input
                          v-model="fixText"
                          class="mt-1 "
                          placeholder="New value"
                          size="lg"
                          style="height: 44px;"
                          :state="errors.length > 0 ? false:null"
                          @keyup.enter.native="onEnterPressed"
                          v-else-if="rowError.idc === 14 || rowError.idc === 9 || rowError.idc === 8 || rowError.idc === 11"
                        />
                        <b-form-input
                          v-model="fixText"
                          class="mt-1 "
                          placeholder="New value"
                          size="lg"
                          style="height: 44px;"
                          @keyup.enter.native="onEnterPressed"
                          :state="errors.length > 0 ? false:null"
                          v-else
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-form-group
                      label-for="fixtextalt"
                      class="color-text-grey px-2 pt-25"
                      v-if="rowError.idc === 8"
                    >
                      <template
                        v-slot:label
                        style="font-weight: 700;"
                      >
                        <span
                          style="font-weight: 700; font-size: 15px; color: #7838FF;"
                          class=""
                        >Alt tag</span>
                      </template>

                      <validation-provider
                        #default="{ errors }"
                        :rules="'required'"
                        name="fixtextalt"
                      >
                        <b-form-input
                          v-model="fixTextAlt"
                          class="mt-1 "
                          placeholder="Alt text"
                          size="lg"
                          style="height: 44px;"
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-button
                      :style="permission === 'me' ? 'cursor: pointer !important; background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px;' : 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px;'"
                      class="mt-2 ml-2"
                      variant="primary"
                      :disabled="processSave || invalid"
                      @click.prevent="validationForm"
                    >
                      <span
                        class="align-middle"
                        style="font-weight: 700; color: #FFFFFF;"
                      >Save</span>
                    </b-button>
                  <!-- </b-form> -->
                </validation-observer>
              </b-tab>

              <b-tab @click="changeTabFix">
                <template #title>
                  <!-- <feather-icon icon="CpuIcon" /> -->
                  <b-img-lazy
                    :src="faviconSEO"
                    width="20px"
                    height="16px"
                    class=""
                    style="height: 16px; margin-right: 10px;"
                    loading="lazy"
                  />
                  <span>ChatGPT</span>
                </template>
                
                <div v-if="!processSaveImg && rowError.idc === 8 && !imgDallE">
                  <span v-if="noImgToAlt" style="color: rgba(255, 108, 0, 0.81); margin-left: 1.5rem;"><b>Missing image</b>, before add alt text, please add image</span>
                  <validation-observer ref="simpleRulesImg" v-slot="{ invalid }">
                    <b-form-group
                      label-for="text"
                      class="color-text-grey px-2 pt-2"
                    >
                      <template
                        v-slot:label
                        style="font-weight: 700;"
                      >
                        <span
                          style="font-weight: 700; font-size: 15px; color: #7838FF;"
                          class=""
                        >Describe image to add </span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        :rules="'required|max:36'"
                        name="text"
                      >
                        <b-form-input
                          v-model="promptDallE"
                          class="mt-1 "
                          placeholder="New value"
                          size="lg"
                          style="height: 44px;"
                          :state="errors.length > 0 ? false:null"
                          @keyup.enter.native="onEnterPressed"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-button
                      :style="permission === 'me' ? 'cursor: pointer !important; background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px;' : 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px;'"
                      class="mt-2 ml-2"
                      variant="primary"
                      :disabled="processSaveImg || invalid"
                      @click.prevent="validationFormImg('GPT')"
                    >
                      <span
                        class="align-middle"
                        style="font-weight: 700; color: #FFFFFF;"
                      >Send</span>
                    </b-button>
                  </validation-observer>
                </div>

                <div v-else-if="rowError.idc === 3 && !rowError.img">
                </div>

                <div v-else>
                  <b-overlay
                    :show="busyChatGPT"
                    rounded="xl"
                    :variant="'transparent'"
                    :opacity="0.85"
                    :blur="'2px'"
                    class="mt-2 ml-2"
                    v-if="rowError.idc === 1 || rowError.idc === 2"
                  >
                    <h4 style="text-align: center;" v-if="busyChatGPT">Loading...</h4>
                    <b-form-group v-for="(item, index) in optionsChatGPT" :key="index" class="mb-50" v-else>
                      <template
                        v-slot:label
                      >
                        <feather-icon
                          icon="CpuIcon"
                          :style="'color: #15a17f; top: -3px; position: relative;'"
                        />
                        <span
                          :style="'color: #15a17f;'"
                          class="pt-0 px-0"
                        >
                          {{ 'ChatGPT '+ (index + 1) }}
                        </span>
                      </template>
                      <b-form-radio
                        v-model="fixText"
                        :value="item"
                        class="custom-control-success"
                      >
                        {{ item }}
                      </b-form-radio>
                    </b-form-group>

                    <b-button
                      :style="permission === 'me' ? `cursor: pointer !important; background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px; ${!fixText ? 'cursor: no-drop !important;' : ''}` : `background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px; ${!fixText ? 'cursor: no-drop !important;' : ''}`"
                      class="mt-2 ml-0"
                      variant="primary"
                      :disabled="processSave || !fixText"
                      @click.prevent="saveChange"
                    >
                      <span
                        class="align-middle"
                        style="font-weight: 700; color: #FFFFFF;"
                      >Save</span>
                    </b-button>
                  </b-overlay>
                  <b-overlay
                    :show="busyChatGPT"
                    rounded="xl"
                    :variant="'transparent'"
                    :opacity="0.85"
                    :blur="'2px'"
                    class="mt-2 ml-2"
                    v-else-if="rowError.idc === 8 || rowError.idc === 3"
                  >
                    <h4 style="text-align: center;" v-if="busyChatGPT">Loading...</h4>
                    <div class="w-100">
                      <b-img-lazy
                        :src="imgDallE"
                        width="160"
                        height="120"
                        class=""
                        style="margin-right: 10px;"
                        loading="lazy"
                        v-if="imgDallE"
                      />
                      <h4 style="text-align: left;" class="mt-2" v-if="altDallE">Alt text suggest: <span style="color: rgb(120, 56, 255) !important;">{{altDallE}}</span></h4>
                      <h4 style="text-align: center;" v-else-if="!imgDallE && !busyChatGPT">No response AI Copilot</h4>
                    </div>

                    <b-button
                      :style="permission === 'me' ? `cursor: pointer !important; background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px; ${!imgDallE ? 'cursor: no-drop !important;' : ''}` : `background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px; ${!imgDallE ? 'cursor: no-drop !important;' : ''}`"
                      class="mt-2 ml-0"
                      variant="primary"
                      :disabled="processSave || !imgDallE"
                      @click.prevent="rowError.idc === 3 ? saveChange() : saveImgDallE(imgDallE, altDallE)"
                    >
                      <span
                        class="align-middle"
                        style="font-weight: 700; color: #FFFFFF;"
                      >Save</span>
                    </b-button>
                  </b-overlay>
                  <h4 style="text-align: center;" v-else>Coming soon...</h4>
                </div>
              </b-tab>

              <b-tab @click="changeTabFix" v-if="rowError.idc === 8">
                <template #title>
                  <!-- <feather-icon icon="CpuIcon" /> -->
                  <b-img-lazy
                    :src="faviconSEO"
                    width="20px"
                    height="16px"
                    class=""
                    style="height: 16px; margin-right: 10px;"
                    loading="lazy"
                  />
                  <span>Stability AI</span>
                </template>
                
                <div v-if="!processSaveImg && rowError.idc === 8 && !imgDallE">
                  <span v-if="noImgToAlt" style="color: rgba(255, 108, 0, 0.81); margin-left: 1.5rem;"><b>Missing image</b>, before add alt text, please add image</span>
                  <validation-observer ref="simpleRulesImg" v-slot="{ invalid }">
                    <b-form-group
                      label-for="text"
                      class="color-text-grey px-2 pt-2"
                    >
                      <template
                        v-slot:label
                        style="font-weight: 700;"
                      >
                        <span
                          style="font-weight: 700; font-size: 15px; color: #7838FF;"
                          class=""
                        >Describe image to add </span>
                      </template>
                      <validation-provider
                        #default="{ errors }"
                        :rules="'required|max:36'"
                        name="text"
                      >
                        <b-form-input
                          v-model="promptDallE"
                          class="mt-1 "
                          placeholder="New value"
                          size="lg"
                          style="height: 44px;"
                          :state="errors.length > 0 ? false:null"
                          @keyup.enter.native="onEnterPressed"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <b-button
                      :style="permission === 'me' ? 'cursor: pointer !important; background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px;' : 'background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px;'"
                      class="mt-2 ml-2"
                      variant="primary"
                      :disabled="processSaveImg || invalid"
                      @click.prevent="validationFormImg('STAB')"
                    >
                      <span
                        class="align-middle"
                        style="font-weight: 700; color: #FFFFFF;"
                      >Send</span>
                    </b-button>
                  </validation-observer>
                </div>

                <div v-else-if="rowError.idc === 3 && !rowError.img">
                </div>

                <div v-else>
                  <b-overlay
                    :show="busyChatGPT"
                    rounded="xl"
                    :variant="'transparent'"
                    :opacity="0.85"
                    :blur="'2px'"
                    class="mt-2 ml-2"
                    v-if="rowError.idc === 1 || rowError.idc === 2"
                  >
                    <h4 style="text-align: center;" v-if="busyChatGPT">Loading...</h4>
                    <b-form-group v-for="(item, index) in optionsChatGPT" :key="index" class="mb-50" v-else>
                      <template
                        v-slot:label
                      >
                        <feather-icon
                          icon="CpuIcon"
                          :style="'color: #15a17f; top: -3px; position: relative;'"
                        />
                        <span
                          :style="'color: #15a17f;'"
                          class="pt-0 px-0"
                        >
                          {{ 'ChatGPT '+ (index + 1) }}
                        </span>
                      </template>
                      <b-form-radio
                        v-model="fixText"
                        :value="item"
                        class="custom-control-success"
                      >
                        {{ item }}
                      </b-form-radio>
                    </b-form-group>

                    <b-button
                      :style="permission === 'me' ? `cursor: pointer !important; background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px; ${!fixText ? 'cursor: no-drop !important;' : ''}` : `background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px; ${!fixText ? 'cursor: no-drop !important;' : ''}`"
                      class="mt-2 ml-0"
                      variant="primary"
                      :disabled="processSave || !fixText"
                      @click.prevent="saveChange"
                    >
                      <span
                        class="align-middle"
                        style="font-weight: 700; color: #FFFFFF;"
                      >Save</span>
                    </b-button>
                  </b-overlay>
                  <b-overlay
                    :show="busyChatGPT"
                    rounded="xl"
                    :variant="'transparent'"
                    :opacity="0.85"
                    :blur="'2px'"
                    class="mt-2 ml-2"
                    v-else-if="rowError.idc === 8 || rowError.idc === 3"
                  >
                    <h4 style="text-align: center;" v-if="busyChatGPT">Loading...</h4>
                    <div class="w-100">
                      <b-img-lazy
                        :src="imgDallE"
                        width="260"
                        height="220"
                        class=""
                        style="margin-right: 10px;"
                        loading="lazy"
                        v-if="imgDallE"
                      />
                      <h4 style="text-align: left;" class="mt-2" v-if="altDallE">Alt text suggest: <span style="color: rgb(120, 56, 255) !important;">{{altDallE}}</span></h4>
                      <h4 style="text-align: center;" v-else-if="!imgDallE && !busyChatGPT">No response AI Copilot</h4>
                    </div>

                    <b-button
                      :style="permission === 'me' ? `cursor: pointer !important; background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px; ${!imgDallE ? 'cursor: no-drop !important;' : ''}` : `background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px; margin-right: 40px; ${!imgDallE ? 'cursor: no-drop !important;' : ''}`"
                      class="mt-2 ml-0"
                      variant="primary"
                      :disabled="processSave || !imgDallE"
                      @click.prevent="rowError.idc === 3 ? saveChange() : saveImgDallE(imgDallE, altDallE)"
                    >
                      <span
                        class="align-middle"
                        style="font-weight: 700; color: #FFFFFF;"
                      >Save</span>
                    </b-button>
                  </b-overlay>
                  <h4 style="text-align: center;" v-else>Coming soon...</h4>
                </div>
              </b-tab>
            </b-tabs>
          </div>

        </div>
        <help-footer :url="idsite.domain" />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy, BTabs, BTab,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow, BOverlay, BFormRadio,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  required, url, validimg, max
} from '@validations'
import { ref } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, firebase, auth, dbErrors, firebaseError, storage } from '@/firebase'
import { filter } from 'postcss-rtl/lib/affected-props'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    BTabs,
    BTab,
    BOverlay,
    BFormRadio,
    HelpFooter,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenFixError',
    event: 'update:show-open-fix-error',
  },
  props: ['idsite', 'showOpenFixError', 'rowError', 'permission', 'changeerror', 'arraychanges','contenterr'],
  data() {
    return {
      required,
      url,
      validimg,
      max,

      selectedMove: '',

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      selectedProPage: '',
      optionsProPage: [],
      selectedError: '',
      optionsErrors: [
        'Meta title',
        'Title',
        'Descriptions',
        'Alts tag',
        'Headings H1',
        'Low word count',
        // 'External links',
      ],
      oldText: '',
      fixText: '',
      fixTextAlt: '',
      processSave: false,
      pageTitleSelect: '',
      counterListError: 0,
      busyChatGPT: false,
      imgDallE: '',
      altDallE: '',
      optionsChatGPT: [],
      promptDallE: '',
      noImgToAlt: false,
      processSaveImg: false,
      idCrawl: '',

      // conf
      // site
      descriptionSite: '',
      toneSite: '',
      keywordsSite: '',

      // page
      descriptionPage: '',
      tonePage: '',
      keywordsPage: '',

      //   sources
      errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),
      faviconSEO: require('@/assets/images/icons/favicon-seocloud.png'),

      promptSite: '',
      promptPage: '',
    }
  },
  watch: {
    rowError(val) {
      // console.log(val.idc)
      this.oldText = val.idc === 7 ? val.url : val.idc === 13 || val.idc === 15 ? val.location : val.idc === 3 ? val.alt : val.content
      this.selectedError = 'Title'
      if(val.idc === 1) {
        this.selectedError = 'Titles'
      } else if(val.idc === 2) {
        this.selectedError = 'Descriptions'
      }
      // else if(val.idc === 3) {
      //   this.selectedError = 'Headings H1'
      // } else if(val.idc === 4) {
      //   this.selectedError = 'Headings H2'
      // }
      // else if(val.idc === 5) {
      //   this.selectedError = 'Images'
      // }
      else if(val.idc === 6) {
        this.selectedError = 'Low word count'
      }
      else if(val.idc === 8) {
        this.selectedError = 'Images'
      }
      else if(val.idc === 9) {
        this.selectedError = 'Dead links'
      }
      else if(val.idc === 3) {
        this.selectedError = 'Missing alts'
      }
      else if(val.idc === 13) {
        this.selectedError = 'Strings'
      }
      // else if(val.idc === 12) {
      //   this.selectedError = 'Missing images'
      // }
      else if(val.idc === 11) {
        this.selectedError = 'Canonicals'
      }
      else if(val.idc === 14) {
        this.selectedError = 'Missing images'
      }
    },
    showOpenFixError(val) {
      this.imgDallE = ''
      this.altDallE = ''
      this.promptDallE = ''
      this.noImgToAlt = false
      this.processSaveImg = false
      if(val) {
        document.body.style.overflow = 'hidden'
        this.fixText = ''
        this.fixTextAlt = ''
        this.busyChatGPT = false
        this.optionsChatGPT = []
        this.idCrawl = ''

        this.loadProPagesObject()

        dbErrors.collection('IA_form_builder').doc('sites').get()
        .then(docIA => {
          this.promptSite = docIA.data().promptSite ? docIA.data().promptSite : ''
          this.promptPage = docIA.data().promptPage ? docIA.data().promptPage : ''
        })
      } else {
        localStorage.removeItem('fixErrorHistory')
        document.body.style.overflow = 'auto'
        this.busyChatGPT = false
        this.optionsChatGPT = []
      }
    },
    pageTitleSelect(val) {
      // select automated page error when select of pro page in sidebar
      // console.log(val)
      // list
      this.counterListError = 0
      var filterProPagesView = this.contenterr.filter(ele => ele.domain === val.url)
      filterProPagesView.forEach(ele => {
        if(ele.list && ele.list.length) {
          this.counterListError =ele.list.length
          //     // this.rowError = ele.list[0]
          //     // this.$emit('update:row-error', ele.list[0])
          //     this.$emit('update:rowError', ele.list[0])
        }
      //   } else {
      //     // this.rowError = ele
      //     // this.$emit('update:row-error', ele)
      //     this.$emit('update:rowError', ele)
      //   }
      })
    }
  },
  validations: {
  },
  methods: {
    changeTabFix() {
      this.fixText = ''
    },
    onEnterPressed() {
      // 
    },
    watchHistoryError() {
      localStorage.setItem('fixErrorHistory', true)
    },
    structureChatGPT(payload) {
      // console.log(payload);
      // console.log(JSON.parse(payload))
      var dataRes = JSON.parse(payload)
      function eliminarAcentoGrave(texto) {
          // Define una expresión regular para el carácter de acento grave `
          const acentoGrave = /`/g;

          // Reemplaza el carácter de acento grave con una cadena vacía
          return texto.replace(acentoGrave, '');
      }

      // Aplicar la función eliminarAcentoGrave a cada elemento del array
      var arrayLimpio = dataRes.map(eliminarAcentoGrave);

      function extractArrayFromResponse(responseText) {
          // Define una expresión regular para extraer el contenido del array
          const arrayPattern = /\[(.*?)\]/s;
          const match = responseText.match(arrayPattern);
          
          if (match && match[0]) {
              let jsonString = match[0];
              
              // Reemplazar comillas simples por comillas dobles
              // jsonString = jsonString.replace(/'/g, '"');
              
              // Escapar comillas dentro de los elementos del array
              jsonString = jsonString.replace(/\\([^"])/g, '\\$1');
              
              // Intenta convertir la subcadena coincidente en un objeto JSON
              try {
                  return JSON.parse(jsonString);
              } catch (error) {
                  console.error("Error al parsear el array:", error);
              }
          }
          
          return [];
      }

      // Unir el array limpio en una sola cadena para pasarlo a la función extractArrayFromResponse
      const arrayLimpioString = JSON.stringify(arrayLimpio);
      const arraySimple = extractArrayFromResponse(arrayLimpioString);

      this.busyChatGPT = false
      this.optionsChatGPT = arraySimple
    },
    loadProPagesObject() {
      // console.log('call pro pages')
      this.promptDallE = ''
      this.pageTitleSelect = { title: this.rowError.name, url: this.rowError.domain }

      this.optionsProPage = []
      var filterProPagesView = this.contenterr.filter(ele => ele.pro === true)
      filterProPagesView.forEach(ele => {
        var alreadyAdd = this.optionsProPage.filter(item => item.url === ele.domain)
        if(alreadyAdd.length === 0) {
          this.optionsProPage.push({title: ele.name, url: ele.domain})
        }
      })

      db.collection('Sites').doc(this.rowError.idsite).get()
      .then(site => {
        this.idCrawl = site.data().mostRecentCrawl

        // console.log(this.rowError.idc);
        if(this.rowError.idc === 1 || this.rowError.idc === 2) {
          var typeBuild = this.rowError.idc === 1 ? 'titles': 'desc'
          var cadenaPrompt = []
          var arrayVariables = []
          var arrayFieldsAnswer = []
          var itemsBuilds = []
          var itemsBuildsCache = []
          this.busyChatGPT = true
          dbErrors.collection('IA_form_builder').doc('prompt').get()
          .then(docPrompt => {
            var promtObj = docPrompt.data().prompt.filter(ele => ele.typeError === typeBuild)

            dbErrors.collection('IA_form_builder').doc('content').get()
            .then(docBuilder => {
              if(docBuilder.data().form) {
                itemsBuilds = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                itemsBuildsCache = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
              }

              if(promtObj.length >= 1) {
                const cadena = promtObj[0].logic
                cadenaPrompt = cadena

                // Utilizando expresiones regulares con el modificador global para encontrar todas las palabras entre paréntesis
                const resultados = cadena.match(/\((.*?)\)/g)

                if(resultados) {
                  const palabrasEntreParentesis = resultados.map(resultado => resultado.slice(1, -1))
                  arrayVariables = palabrasEntreParentesis
                  // console.log(palabrasEntreParentesis)
                  if(palabrasEntreParentesis.includes('web_content')) {
                    itemsBuilds.push({labelField: 'Web content', textField: '', typeField: 'textarea', variableField: 'web_content'})
                  }
                } else {
                  console.log("No se encontró variables en el prompt.")
                }
              } else {
                cadenaPrompt = []
                arrayVariables = []
              }

              //  actualizar only-text site
              db.collection('Sites').doc(this.rowError.idsite).get()
              .then(site => {
                this.descriptionSite = site.data().descriptionSite ? site.data().descriptionSite : ''
                var atomictoneSite = site.data().toneSite ? site.data().toneSite : ''
                this.toneSite = ''
                if(atomictoneSite && atomictoneSite.length >= 1) {
                  var ind = 0
                  atomictoneSite.forEach(ele => {
                    ind += 1
                    this.toneSite += ind === 1 && atomictoneSite.length === 1 ? `(${ele})` : ind === 1 ? `(${ele}` : ind === atomictoneSite.length ? `, ${ele})` : `, ${ele}`
                  })
                } else {
                  this.toneSite = ''
                }

                var atomickeywordsSite = site.data().keywordsSite ? site.data().keywordsSite : ''
                this.keywordsSite = ''
                if(atomickeywordsSite && atomickeywordsSite.length >= 1) {
                  var ind = 0
                  atomickeywordsSite.forEach(ele => {
                    ind += 1
                    this.keywordsSite += ind === 1 && atomickeywordsSite.length === 1 ? `(${ele})` : ind === 1 ? `(${ele}` : ind === atomickeywordsSite.length ? `, ${ele})` : `, ${ele}`
                  })
                } else {
                  this.keywordsSite = ''
                }

                var actualConf = site.data().confPages ? site.data().confPages : []
                var filterConfPage = actualConf.filter(ele => ele.url === this.rowError.domain)
                this.descriptionPage = filterConfPage.length && filterConfPage[0].description ? filterConfPage[0].description : ''
                var atomictonePage = filterConfPage.length && filterConfPage[0].tone ? filterConfPage[0].tone : ''
                this.tonePage = ''
                if(atomictonePage && atomictonePage.length >= 1) {
                  var ind = 0
                  atomictonePage.forEach(ele => {
                    ind += 1
                    this.tonePage += ind === 1 && atomictonePage.length === 1 ? `(${ele})` : ind === 1 ? `(${ele}` : ind === atomictonePage.length ? `, ${ele})` : `, ${ele}`
                  })
                } else {
                  this.tonePage = ''
                }

                var atomickeywordsPage = filterConfPage.length && filterConfPage[0].keywords ? filterConfPage[0].keywords : ''
                this.keywordsPage = ''
                if(atomickeywordsPage && atomickeywordsPage.length >= 1) {
                  var ind = 0
                  atomickeywordsPage.forEach(ele => {
                    ind += 1
                    this.keywordsPage += ind === 1 && atomickeywordsPage.length === 1 ? `(${ele})` : ind === 1 ? `(${ele}` : ind === atomickeywordsPage.length ? `, ${ele})` : `, ${ele}`
                  })
                } else {
                  this.keywordsPage = ''
                }

                // this.optionsProPage.forEach(ele => {
                  db.collection('Requests').where('RequestURL', '==', this.rowError.domain).where('idcrawl', '==', site.data().mostRecentCrawl).where('tipo', '==', 'only-text').limit(1).get()
                  .then(docsRequests => {
                    // console.log(docsRequests.size)
                    if(docsRequests.size === 0) {
                      console.log('no exist request')
                      var makeid = (length) => {
                        var result = ''
                        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
                        var charactersLength = characters.length
                        for(var i = 0; i < length; i++) {
                          result += characters.charAt(Math.floor(Math.random() * charactersLength))
                        }
                        return result
                      }
                      
                      var dateQueue = new Date()
                      var string_date_queue = dateQueue.getDate()+'/'+(dateQueue.getMonth()+1)+'/'+dateQueue.getFullYear()+' '+dateQueue.getHours()+':'+dateQueue.getMinutes()+':'+dateQueue.getSeconds()
                      var idNewRequest = makeid(20)
                      var URLactual = new URL(this.rowError.domain)
                      var payloadReq = {
                        id: site.data().mostRecentCrawl,
                        type: 'only-text',
                        forceNotFound: 'no',
                        url: this.rowError.domain,
                        pageSize: 9999999999,
                        takeScreenshots: 'no',
                        ignoreIndexNoFollow: 'yes',
                        useFastCrawler: 'no',
                        triggeredBy: 'automated',
                        source: 'overview',
                        stringSearch: []
                      }

                      db.collection('Requests').add({
                        request_json: payloadReq,
                        IDRequest: idNewRequest,
                        RequestURL: this.rowError.domain,
                        CrawlJSON: null,
                        SiteURL: URLactual.hostname,
                        data: null,
                        tipo: 'only-text',
                        takeScreenshots: 'no',
                        apiWI: false,
                        idcrawl: site.data().mostRecentCrawl,
                        hitcache: false,
                        cachedID: null,
                        tries: 1,
                        useFastCrawler: 'no',
                        created_at: string_date_queue,
                        request_date: Date.now(),
                        response_date: 0,
                      })
                      .then(docRefAddRequests => {
                        var config = {
                          'url': 'https://app-facm3chzha-uc.a.run.app/crawl-url',
                          'method': 'POST',
                          'maxBodyLength': Infinity,
                          'headers': {
                            'Content-Type': 'application/json',
                          },
                          'data': JSON.stringify(payloadReq)
                        };
                    
                        this.axios(config)
                        .then((response) => {
                          console.log('response only-urls: ', payloadReq.url)

                          var body = response.data
                          var dataBodyResponse = 'found'

                          db.collection('Requests').doc(docRefAddRequests.id).update({
                            CrawlJSON: body,
                            data: dataBodyResponse,
                            response_date: Date.now()
                          })
                          .catch(e => console.log('Error update requests: ', e.message))

                          // docsRequests.forEach(doc => {
                            // console.log(doc.data().CrawlJSON.data.allText)

                            var responseObjArray = []
                            var urlT = new URL(this.rowError.domain)
                            var buildR = itemsBuilds.filter(ele => ele.variableField === 'page_title')
                            buildR.forEach(ele => {
                              ele.textField = this.idsite.name ? this.idsite.name : urlT.pathname
                            })

                            var buildR = itemsBuilds.filter(ele => ele.variableField === 'web_content')
                            buildR.forEach(ele => {
                              ele.textField = body.data.allText.substring(0, 170)
                            })

                            var arrayFieldsAnswer = itemsBuilds
                            arrayVariables.forEach(ele => {
                              arrayFieldsAnswer.forEach((item, index) => {
                                if(item.variableField === ele) {
                                  var responseField = ''
                                  if(item.typeField === 'checkbox') {
                                    var variableCounter = 0
                                    item.textField.forEach(ele => {
                                      variableCounter += 1
                                      responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                                    })
                                  } else {
                                    responseField = item.textField
                                  }

                                  responseObjArray.push({response: responseField})
                                }
                              })
                            })

                            if(cadenaPrompt.length >= 1) {
                              // this.showDelayGPT = true
                              let ocurrenciasParentesis = cadenaPrompt.match(/\(/g);
                              let contadorParentesis = ocurrenciasParentesis ? ocurrenciasParentesis.length : 0;
                              const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                              var counterFor = 0
                              var sendToApi = ''
                              partesNoParentesis.forEach(element => {
                                if(responseObjArray[counterFor]) {
                                  sendToApi += (contadorParentesis > counterFor ? element + responseObjArray[counterFor].response : element)
                                  counterFor += 1
                                } else {
                                  sendToApi += element
                                }
                              })
                              // this.descriptionSite ? (sendToApi += ', Site description: ' + this.descriptionSite) : null
                              // this.toneSite ? (sendToApi += ', Site Tone: ' + this.toneSite) : null
                              // this.keywordsSite ? (sendToApi += ' Site keywords: ' + this.keywordsSite) : null

                              // this.descriptionPage ? (sendToApi += ', Page description: ' + this.descriptionPage) : null
                              // this.tonePage ? (sendToApi += ', Page Tone: ' + this.tonePage) : null
                              // this.keywordsPage ? (sendToApi += ', Page keywords: ' + this.keywordsPage) : null
                              // console.log(sendToApi);

                              let textopromptSite = this.promptSite
                              let descripcionNuevaS = this.descriptionSite
                              let tonoNuevoS = this.toneSite
                              let palabrasClaveNuevasS = this.keywordsSite
                              let textoModificadoS = textopromptSite
                              .replace(/\(description\)/g, descripcionNuevaS)
                              .replace(/\(tone\)/g, tonoNuevoS)
                              .replace(/\(keywords\)/g, palabrasClaveNuevasS);
                              textoModificadoS && (descripcionNuevaS || tonoNuevoS || palabrasClaveNuevasS) ? sendToApi += textoModificadoS : null

                              let textopromptPage = this.promptPage
                              let descripcionNuevaP = this.descriptionPage
                              let tonoNuevoP = this.tonePage
                              let palabrasClaveNuevasP = this.keywordsPage
                              let textoModificadoP = textopromptPage
                              .replace(/\(description\)/g, descripcionNuevaP)
                              .replace(/\(tone\)/g, tonoNuevoP)
                              .replace(/\(keywords\)/g, palabrasClaveNuevasP);
                              textoModificadoP && (descripcionNuevaP || tonoNuevoP || palabrasClaveNuevasP) ? sendToApi += textoModificadoP : null

                              var config = {
                                method: 'POST',
                                url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat',
                                headers: {
                                  'Content-Type': 'application/json',
                                },
                                data: JSON.stringify({messages: sendToApi}),
                              }

                              this.axios(config)
                              .then(response => {
                                // console.log(response.data.response)
                                var dataRes = ''
                                if(typeBuild === 'title') {
                                  function removeDoubleQuotes(str) {
                                    return str.replace(/"/g, '');
                                  }
                                  let cleanedText = removeDoubleQuotes(response.data.response);
                                  dataRes = [cleanedText]
                                } else {
                                  dataRes = response.data.response
                                }

                                var responseFromApi = dataRes
                                // this.showDelayGPT = false
                                this.structureChatGPT(responseFromApi)
                              })
                              .catch(e => {
                                console.log(e)
                                // this.showDelayGPT = false
                                this.busyChatGPT = false
                              })
                            } else {
                              console.log('No prompt created');
                              // this.sendToApi = 'No prompt created'
                              // this.responseFromApi = ''
                              this.busyChatGPT = false
                            }
                          // })
                        })
                      })
                      .catch(e => {
                        console.log('Error update requests: ', e.message)
                        this.busyChatGPT = false
                      })
                    } else {
                      console.log('exist request')
                      docsRequests.forEach(doc => {
                        // console.log(doc.data().CrawlJSON.data.allText)

                        var responseObjArray = []
                        var urlT = new URL(this.rowError.domain)
                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'page_title')
                        buildR.forEach(ele => {
                          ele.textField = this.idsite.name ? this.idsite.name : urlT.pathname
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'web_content')
                        buildR.forEach(ele => {
                          ele.textField = doc.data().CrawlJSON.data.allText.substring(0, 170)
                        })

                        var arrayFieldsAnswer = itemsBuilds
                        arrayVariables.forEach(ele => {
                          arrayFieldsAnswer.forEach((item, index) => {
                            if(item.variableField === ele) {
                              var responseField = ''
                              if(item.typeField === 'checkbox') {
                                var variableCounter = 0
                                item.textField.forEach(ele => {
                                  variableCounter += 1
                                  responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                                })
                              } else {
                                responseField = item.textField
                              }

                              responseObjArray.push({response: responseField})
                            }
                          })
                        })

                        if(cadenaPrompt.length >= 1) {
                          // this.showDelayGPT = true
                          let ocurrenciasParentesis = cadenaPrompt.match(/\(/g);
                          let contadorParentesis = ocurrenciasParentesis ? ocurrenciasParentesis.length : 0;
                          const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                          var counterFor = 0
                          var sendToApi = ''
                          partesNoParentesis.forEach(element => {
                            if(responseObjArray[counterFor]) {
                              sendToApi += (contadorParentesis > counterFor ? element + responseObjArray[counterFor].response : element)
                              counterFor += 1
                            } else {
                              sendToApi += element
                            }
                          })
                          // this.descriptionSite ? (sendToApi += ', Site description: ' + this.descriptionSite) : null
                          // this.toneSite ? (sendToApi += ', Site Tone: ' + this.toneSite) : null
                          // this.keywordsSite ? (sendToApi += ' Site keywords: ' + this.keywordsSite) : null

                          // this.descriptionPage ? (sendToApi += ', Page description: ' + this.descriptionPage) : null
                          // this.tonePage ? (sendToApi += ', Page Tone: ' + this.tonePage) : null
                          // this.keywordsPage ? (sendToApi += ', Page keywords: ' + this.keywordsPage) : null
                          // console.log(sendToApi);

                          let textopromptSite = this.promptSite
                          let descripcionNuevaS = this.descriptionSite
                          let tonoNuevoS = this.toneSite
                          let palabrasClaveNuevasS = this.keywordsSite
                          let textoModificadoS = textopromptSite
                          .replace(/\(description\)/g, descripcionNuevaS)
                          .replace(/\(tone\)/g, tonoNuevoS)
                          .replace(/\(keywords\)/g, palabrasClaveNuevasS);
                          textoModificadoS && (descripcionNuevaS || tonoNuevoS || palabrasClaveNuevasS) ? sendToApi += textoModificadoS : null

                          let textopromptPage = this.promptPage
                          let descripcionNuevaP = this.descriptionPage
                          let tonoNuevoP = this.tonePage
                          let palabrasClaveNuevasP = this.keywordsPage
                          let textoModificadoP = textopromptPage
                          .replace(/\(description\)/g, descripcionNuevaP)
                          .replace(/\(tone\)/g, tonoNuevoP)
                          .replace(/\(keywords\)/g, palabrasClaveNuevasP);
                          textoModificadoP && (descripcionNuevaP || tonoNuevoP || palabrasClaveNuevasP) ? sendToApi += textoModificadoP : null

                          var config = {
                            method: 'POST',
                            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            data: JSON.stringify({messages: sendToApi}),
                          }

                          this.axios(config)
                          .then(response => {
                            // console.log(response.data.response)
                            var dataRes = ''
                            if(typeBuild === 'title') {
                              dataRes = [response.data.response]
                            } else {
                              dataRes = response.data.response
                            }

                            var responseFromApi = dataRes
                            // this.showDelayGPT = false
                            this.structureChatGPT(responseFromApi)
                          })
                          .catch(e => {
                            console.log(e)
                            // this.showDelayGPT = false
                            this.busyChatGPT = false
                          })
                        } else {
                          console.log('No prompt created');
                          // this.sendToApi = 'No prompt created'
                          // this.responseFromApi = ''
                          this.busyChatGPT = false
                        }
                      })
                    }
                  })
                // })
              })
            })
          })
        } else if(this.rowError.idc === 8) {
          this.busyChatGPT = true
          this.imgDallE = ''
          this.altDallE = ''
          this.promptDallE = ''
        } else if(this.rowError.idc === 3) {
          this.busyChatGPT = true
          this.imgDallE = ''
          this.altDallE = ''
          this.promptDallE = ''
          // console.log(this.rowError);

          // console.log(this.rowError.estatus);
          if(this.rowError.img && this.rowError.estatus <= 309) {
            db.collection('Requests').where('url', '==', this.rowError.domain).where('type', '==', 'DallE-tag').where('crawlid', '==', site.data().mostRecentCrawl).limit(1).get()
            .then(qDall => {
              console.log('ia r: ', qDall.size);
              if(qDall.size) {
                qDall.forEach(qEle => {
                  this.imgDallE = qEle.data().AIPilot.img
                  var responseFromApi = qEle.data().AIPilot.tag
                  // console.log(responseFromApi);
                  this.altDallE = responseFromApi
                  this.busyChatGPT = false
                  // this.promptDallE = ''
                })
              } else {
                var typeBuild = 'alts'
                var cadenaPrompt = []
                var arrayVariables = []
                var arrayFieldsAnswer = []
                var itemsBuilds = []
                var itemsBuildsCache = []
                this.busyChatGPT = true
                dbErrors.collection('IA_form_builder').doc('prompt').get()
                .then(docPrompt => {
                  var promtObj = docPrompt.data().prompt.filter(ele => ele.typeError === typeBuild)

                  dbErrors.collection('IA_form_builder').doc('errors').get()
                  .then(docBuilder => {
                    if(docBuilder.data().form) {
                      // console.log(docBuilder.data().form);
                      itemsBuilds = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                      itemsBuildsCache = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                    }

                    if(promtObj.length >= 1) {
                      const cadena = promtObj[0].logic
                      cadenaPrompt = cadena

                      // Utilizando expresiones regulares con el modificador global para encontrar todas las palabras entre paréntesis
                      const resultados = cadena.match(/\((.*?)\)/g)

                      if(resultados) {
                        const palabrasEntreParentesis = resultados.map(resultado => resultado.slice(1, -1))
                        arrayVariables = palabrasEntreParentesis
                        // console.log(palabrasEntreParentesis)
                        if(palabrasEntreParentesis.includes('web_content')) {
                          itemsBuilds.push({labelField: 'Web content', textField: '', typeField: 'textarea', variableField: 'web_content'})
                        }
                      } else {
                        console.log("No se encontró variables en el prompt.")
                      }
                    } else {
                      cadenaPrompt = []
                      arrayVariables = []
                    }

                    var responseObjArray = []
                    var urlT = new URL(this.rowError.domain)
                    var buildR = itemsBuilds.filter(ele => ele.variableField === 'page_title')
                    buildR.forEach(ele => {
                      ele.textField = this.idsite.name ? this.idsite.name : urlT.pathname
                    })

                    var buildR = itemsBuilds.filter(ele => ele.variableField === 'web_content')
                    buildR.forEach(ele => {
                      ele.textField = body.data.allText.substring(0, 170)
                    })

                    var buildR = itemsBuilds.filter(ele => ele.variableField === 'uploaded_image')
                    buildR.forEach(ele => {
                      ele.textField = this.rowError.img
                    })

                    var buildR = itemsBuilds.filter(ele => ele.variableField === 'url_site')
                    buildR.forEach(ele => {
                      ele.textField = this.rowError.domain
                    })

                    // console.log(itemsBuilds,this.rowError.img);
                    var arrayFieldsAnswer = itemsBuilds
                    arrayVariables.forEach(ele => {
                      arrayFieldsAnswer.forEach((item, index) => {
                        if(item.variableField === ele) {
                          var responseField = ''
                          if(item.typeField === 'checkbox') {
                            var variableCounter = 0
                            item.textField.forEach(ele => {
                              variableCounter += 1
                              responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                            })
                          } else {
                            responseField = item.textField
                          }

                          responseObjArray.push({response: responseField})
                        }
                      })
                    })

                    if(cadenaPrompt.length >= 1) {
                      // this.showDelayGPT = true
                      let ocurrenciasParentesis = cadenaPrompt.match(/\(/g);
                      let contadorParentesis = ocurrenciasParentesis ? ocurrenciasParentesis.length : 0;
                      const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                      var counterFor = 0
                      var sendToApi = ''
                      partesNoParentesis.forEach(element => {
                        if(responseObjArray[counterFor]) {
                          sendToApi += (contadorParentesis > counterFor ? element + responseObjArray[counterFor].response : element)
                          counterFor += 1
                        } else {
                          sendToApi += element
                        }
                      })
                      // console.log(1, sendToApi);

                      var config = {
                        method: 'POST',
                        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat',
                        headers: {
                          'Content-Type': 'application/json',
                        },
                        data: JSON.stringify({messages: sendToApi}),
                      }

                      this.axios(config)
                      .then(response => {
                        // console.log(response.data)
                        this.imgDallE = this.rowError.img
                        var responseFromApi = response.data.response
                        // console.log(responseFromApi);
                        this.altDallE = responseFromApi
                        this.busyChatGPT = false

                        db.collection('Requests').add({
                          url: this.rowError.domain,
                          type: 'DallE-tag',
                          crawlid: site.data().mostRecentCrawl,
                          siteid: this.rowError.idsite,
                          AIPilot: {img: this.rowError.img, tag: responseFromApi}
                        })
                        .then(() => console.log('upload req dall'))
                        .catch(e => console.log('Error update AIPilot: ', e.message))
                      })
                      .catch(e => {
                        this.busyChatGPT = false
                      })
                    }
                  })
                })
              }
            })
          } else {
            this.noImgToAlt = true
            this.rowError.idc = 8
          }
        }
      })
    },
    loadImgInfo(payload) {
      if(this.promptDallE) {
        this.processSaveImg = true
        if(payload === 'STAB') {
          db.collection('Sites').doc(this.rowError.idsite).get()
          .then(site => {
            if(this.rowError.idc === 8) {
              this.busyChatGPT = true
              this.imgDallE = ''
              this.altDallE = ''
              // console.log(this.rowError);
              db.collection('Requests').where('url', '==', this.rowError.domain).where('type', '==', 'DallE-tag-stab').where('crawlid', '==', site.data().mostRecentCrawl).limit(1).get()
              .then(qDall => {
                console.log('ia r: ', qDall.size);
                if(qDall.size) {
                  qDall.forEach(qEle => {
                    this.imgDallE = qEle.data().AIPilot.img
                    var responseFromApi = qEle.data().AIPilot.tag
                    // console.log(responseFromApi);
                    this.altDallE = responseFromApi
                    this.busyChatGPT = false
                    this.promptDallE = ''
                  })
                } else {
                  var payloadDallE = {
                    messages: this.promptDallE
                  }

                  var config = {
                    'url': 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat-bedrock-stability',
                    'method': 'POST',
                    'maxBodyLength': Infinity,
                    'headers': {
                      'Content-Type': 'application/json',
                    },
                    'data': JSON.stringify(payloadDallE)
                  };
              
                  this.axios(config)
                  .then(async (responseDallE) => {
                    // console.log(responseDallE.data.response);
                      const refImg = await storage.ref().child('sitesImgs').child(`${Date.now()}DallE`)
                      const upl = await refImg.putString(responseDallE.data.response, 'base64', {
                        contentType: 'image/png'  // Especifica el tipo de contenido
                      });
                      // const upl = await refImg.putString(responseData64.data, 'data_url')
                      // const upl = await refImg.putString(responseDallE.data.response, 'base64url')
                      const urlDescarga = await refImg.getDownloadURL()
                      // console.log(urlDescarga);

                      var typeBuild = 'alts'
                      var cadenaPrompt = []
                      var arrayVariables = []
                      var arrayFieldsAnswer = []
                      var itemsBuilds = []
                      var itemsBuildsCache = []
                      this.busyChatGPT = true
                      dbErrors.collection('IA_form_builder').doc('prompt').get()
                      .then(docPrompt => {
                        var promtObj = docPrompt.data().prompt.filter(ele => ele.typeError === typeBuild)

                        dbErrors.collection('IA_form_builder').doc('errors').get()
                        .then(docBuilder => {
                          if(docBuilder.data().form) {
                            itemsBuilds = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                            itemsBuildsCache = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                          }

                          if(promtObj.length >= 1) {
                            const cadena = promtObj[0].logic
                            cadenaPrompt = cadena

                            // Utilizando expresiones regulares con el modificador global para encontrar todas las palabras entre paréntesis
                            const resultados = cadena.match(/\((.*?)\)/g)

                            if(resultados) {
                              const palabrasEntreParentesis = resultados.map(resultado => resultado.slice(1, -1))
                              arrayVariables = palabrasEntreParentesis
                              // console.log(palabrasEntreParentesis)
                              if(palabrasEntreParentesis.includes('web_content')) {
                                itemsBuilds.push({labelField: 'Web content', textField: '', typeField: 'textarea', variableField: 'web_content'})
                              }
                            } else {
                              console.log("No se encontró variables en el prompt.")
                            }
                          } else {
                            cadenaPrompt = []
                            arrayVariables = []
                          }

                          var responseObjArray = []
                          var urlT = new URL(this.rowError.domain)
                          var buildR = itemsBuilds.filter(ele => ele.variableField === 'page_title')
                          buildR.forEach(ele => {
                            ele.textField = this.idsite.name ? this.idsite.name : urlT.pathname
                          })

                          var buildR = itemsBuilds.filter(ele => ele.variableField === 'web_content')
                          buildR.forEach(ele => {
                            ele.textField = body.data.allText.substring(0, 170)
                          })

                          var buildR = itemsBuilds.filter(ele => ele.variableField === 'uploaded_image')
                          buildR.forEach(ele => {
                            ele.textField = urlDescarga
                          })

                          var buildR = itemsBuilds.filter(ele => ele.variableField === 'url_site')
                          buildR.forEach(ele => {
                            ele.textField = this.rowError.domain
                          })

                          var arrayFieldsAnswer = itemsBuilds
                          arrayVariables.forEach(ele => {
                            arrayFieldsAnswer.forEach((item, index) => {
                              if(item.variableField === ele) {
                                var responseField = ''
                                if(item.typeField === 'checkbox') {
                                  var variableCounter = 0
                                  item.textField.forEach(ele => {
                                    variableCounter += 1
                                    responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                                  })
                                } else {
                                  responseField = item.textField
                                }

                                responseObjArray.push({response: responseField})
                              }
                            })
                          })

                          if(cadenaPrompt.length >= 1) {
                            // this.showDelayGPT = true
                            let ocurrenciasParentesis = cadenaPrompt.match(/\(/g);
                            let contadorParentesis = ocurrenciasParentesis ? ocurrenciasParentesis.length : 0;
                            const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                            var counterFor = 0
                            var sendToApi = ''
                            partesNoParentesis.forEach(element => {
                              if(responseObjArray[counterFor]) {
                                sendToApi += (contadorParentesis > counterFor ? element + responseObjArray[counterFor].response : element)
                                counterFor += 1
                              } else {
                                sendToApi += element
                              }
                            })
                            // console.log(2,sendToApi);
                            // var sendToApi = 'Give alt tag for image with this description: ' + response.data.altText

                            var config = {
                              method: 'POST',
                              url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat',
                              headers: {
                                'Content-Type': 'application/json',
                              },
                              data: JSON.stringify({messages: sendToApi}),
                            }

                            this.axios(config)
                            .then(response => {
                              // var obj = {
                              //   url: responseDallE.data.imageUrl
                              // }
                              // var config = {
                              //   method: 'POST',
                              //   url: 'https://luguzxy83e.execute-api.us-east-2.amazonaws.com/prod/imgseocloud',
                              //   maxBodyLength: Infinity,
                              //   headers: {
                              //     'Content-Type': 'application/json',
                              //   },
                              //   data: JSON.stringify(obj),
                              // }

                              // this.axios(config)
                              // .then(async responseData64 => {
                              //   const refImg = await storage.ref().child('sitesImgs').child(`${Date.now()}DallE`)
                              //   const upl = await refImg.putString(responseData64.data, 'data_url')
                              //   const urlDescarga = await refImg.getDownloadURL()

                                this.imgDallE = urlDescarga
                                var responseFromApi = response.data.response
                                // console.log(responseFromApi);
                                this.altDallE = responseFromApi
                                this.busyChatGPT = false
                                this.promptDallE = ''

                                db.collection('Requests').add({
                                  url: this.rowError.domain,
                                  type: 'DallE-tag-stab',
                                  crawlid: site.data().mostRecentCrawl,
                                  siteid: this.rowError.idsite,
                                  AIPilot: {img: urlDescarga, tag: responseFromApi}
                                })
                                .then(() => console.log('upload req dall'))
                                .catch(e => console.log('Error update AIPilot: ', e.message))
                              // })
                            })
                            .catch(e => console.log(e.message))
                          }
                        })
                      })
                    
                  })
                  .catch(e => {
                    this.busyChatGPT = false
                    console.log(e.message);
                  })
                }
              })
            } else if(this.rowError.idc === 3) {
              this.busyChatGPT = true
              this.imgDallE = ''
              this.altDallE = ''
              // console.log(this.rowError);

              if(this.rowError.img) {
                db.collection('Requests').where('url', '==', this.siteSelected[0].cUrl).where('type', '==', 'DallE-tag').where('crawlid', '==', site.data().mostRecentCrawl).limit(1).get()
                .then(qDall => {
                  console.log('ia r: ', qDall.size);
                  if(qDall.size) {
                    qDall.forEach(qEle => {
                      this.arrayMISuggest = qEle.data().AIPilot

                      this.imgDallE = qEle.data().AIPilot.img
                      var responseFromApi = qEle.data().AIPilot.alt
                      // console.log(responseFromApi);
                      this.altDallE = responseFromApi
                      this.busyChatGPT = false
                      this.promptDallE = ''
                    })
                  } else {
                    var typeBuild = 'alts'
                    var cadenaPrompt = []
                    var arrayVariables = []
                    var arrayFieldsAnswer = []
                    var itemsBuilds = []
                    var itemsBuildsCache = []
                    this.busyChatGPT = true
                    dbErrors.collection('IA_form_builder').doc('prompt').get()
                    .then(docPrompt => {
                      var promtObj = docPrompt.data().prompt.filter(ele => ele.typeError === typeBuild)

                      dbErrors.collection('IA_form_builder').doc('errors').get()
                      .then(docBuilder => {
                        if(docBuilder.data().form) {
                          itemsBuilds = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                          itemsBuildsCache = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                        }

                        if(promtObj.length >= 1) {
                          const cadena = promtObj[0].logic
                          cadenaPrompt = cadena

                          // Utilizando expresiones regulares con el modificador global para encontrar todas las palabras entre paréntesis
                          const resultados = cadena.match(/\((.*?)\)/g)

                          if(resultados) {
                            const palabrasEntreParentesis = resultados.map(resultado => resultado.slice(1, -1))
                            arrayVariables = palabrasEntreParentesis
                            // console.log(palabrasEntreParentesis)
                            if(palabrasEntreParentesis.includes('web_content')) {
                              itemsBuilds.push({labelField: 'Web content', textField: '', typeField: 'textarea', variableField: 'web_content'})
                            }
                          } else {
                            console.log("No se encontró variables en el prompt.")
                          }
                        } else {
                          cadenaPrompt = []
                          arrayVariables = []
                        }

                        var responseObjArray = []
                        var urlT = new URL(this.rowError.domain)
                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'page_title')
                        buildR.forEach(ele => {
                          ele.textField = this.idsite.name ? this.idsite.name : urlT.pathname
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'web_content')
                        buildR.forEach(ele => {
                          ele.textField = body.data.allText.substring(0, 170)
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'uploaded_image')
                        buildR.forEach(ele => {
                          ele.textField = this.rowError.img
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'url_site')
                        buildR.forEach(ele => {
                          ele.textField = this.rowError.domain
                        })

                        var arrayFieldsAnswer = itemsBuilds
                        arrayVariables.forEach(ele => {
                          arrayFieldsAnswer.forEach((item, index) => {
                            if(item.variableField === ele) {
                              var responseField = ''
                              if(item.typeField === 'checkbox') {
                                var variableCounter = 0
                                item.textField.forEach(ele => {
                                  variableCounter += 1
                                  responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                                })
                              } else {
                                responseField = item.textField
                              }

                              responseObjArray.push({response: responseField})
                            }
                          })
                        })

                        if(cadenaPrompt.length >= 1) {
                          // this.showDelayGPT = true
                          let ocurrenciasParentesis = cadenaPrompt.match(/\(/g);
                          let contadorParentesis = ocurrenciasParentesis ? ocurrenciasParentesis.length : 0;
                          const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                          var counterFor = 0
                          var sendToApi = ''
                          partesNoParentesis.forEach(element => {
                            if(responseObjArray[counterFor]) {
                              sendToApi += (contadorParentesis > counterFor ? element + responseObjArray[counterFor].response : element)
                              counterFor += 1
                            } else {
                              sendToApi += element
                            }
                          })
                          // console.log(3, sendToApi);
                          // var sendToApi = 'response ' + response.data.altText

                          var config = {
                            method: 'POST',
                            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            data: JSON.stringify({messages: sendToApi}),
                          }

                          this.axios(config)
                          .then(response => {
                            // console.log(response.data.response)
                            this.imgDallE = this.rowError.img
                            var responseFromApi = response.data.response
                            // console.log(responseFromApi);
                            this.altDallE = responseFromApi
                            this.busyChatGPT = false
                            this.promptDallE = ''

                            db.collection('Requests').add({
                              url: this.rowError.domain,
                              type: 'DallE-tag',
                              crawlid: site.data().mostRecentCrawl,
                              siteid: this.rowError.idsite,
                              AIPilot: {img: this.rowError.img, tag: responseFromApi}
                            })
                            .then(() => console.log('upload req dall'))
                            .catch(e => console.log('Error update AIPilot: ', e.message))
                          })
                          .catch(e => {
                            this.busyChatGPT = false
                          })
                        }
                      })
                    })
                  }
                })
              }
            }
          })
        } else {
          db.collection('Sites').doc(this.rowError.idsite).get()
          .then(site => {
            if(this.rowError.idc === 8) {
              this.busyChatGPT = true
              this.imgDallE = ''
              this.altDallE = ''
              // console.log(this.rowError);
              db.collection('Requests').where('url', '==', this.rowError.domain).where('type', '==', 'DallE-tag').where('crawlid', '==', site.data().mostRecentCrawl).limit(1).get()
              .then(qDall => {
                console.log('ia r: ', qDall.size);
                if(qDall.size) {
                  qDall.forEach(qEle => {
                    this.imgDallE = qEle.data().AIPilot.img
                    var responseFromApi = qEle.data().AIPilot.tag
                    // console.log(responseFromApi);
                    this.altDallE = responseFromApi
                    this.busyChatGPT = false
                    this.promptDallE = ''
                  })
                } else {
                  var payloadDallE = {
                    prompt: this.promptDallE
                  }

                  var config = {
                    'url': 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/generate-image',
                    'method': 'POST',
                    'maxBodyLength': Infinity,
                    'headers': {
                      'Content-Type': 'application/json',
                    },
                    'data': JSON.stringify(payloadDallE)
                  };
              
                  this.axios(config)
                  .then((responseDallE) => {
                    var typeBuild = 'alts'
                    var cadenaPrompt = []
                    var arrayVariables = []
                    var arrayFieldsAnswer = []
                    var itemsBuilds = []
                    var itemsBuildsCache = []
                    this.busyChatGPT = true
                    dbErrors.collection('IA_form_builder').doc('prompt').get()
                    .then(docPrompt => {
                      var promtObj = docPrompt.data().prompt.filter(ele => ele.typeError === typeBuild)

                      dbErrors.collection('IA_form_builder').doc('errors').get()
                      .then(docBuilder => {
                        if(docBuilder.data().form) {
                          itemsBuilds = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                          itemsBuildsCache = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                        }

                        if(promtObj.length >= 1) {
                          const cadena = promtObj[0].logic
                          cadenaPrompt = cadena

                          // Utilizando expresiones regulares con el modificador global para encontrar todas las palabras entre paréntesis
                          const resultados = cadena.match(/\((.*?)\)/g)

                          if(resultados) {
                            const palabrasEntreParentesis = resultados.map(resultado => resultado.slice(1, -1))
                            arrayVariables = palabrasEntreParentesis
                            // console.log(palabrasEntreParentesis)
                            if(palabrasEntreParentesis.includes('web_content')) {
                              itemsBuilds.push({labelField: 'Web content', textField: '', typeField: 'textarea', variableField: 'web_content'})
                            }
                          } else {
                            console.log("No se encontró variables en el prompt.")
                          }
                        } else {
                          cadenaPrompt = []
                          arrayVariables = []
                        }

                        var responseObjArray = []
                        var urlT = new URL(this.rowError.domain)
                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'page_title')
                        buildR.forEach(ele => {
                          ele.textField = this.idsite.name ? this.idsite.name : urlT.pathname
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'web_content')
                        buildR.forEach(ele => {
                          ele.textField = body.data.allText.substring(0, 170)
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'uploaded_image')
                        buildR.forEach(ele => {
                          ele.textField = responseDallE.data.imageUrl
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'url_site')
                        buildR.forEach(ele => {
                          ele.textField = this.rowError.domain
                        })

                        var arrayFieldsAnswer = itemsBuilds
                        arrayVariables.forEach(ele => {
                          arrayFieldsAnswer.forEach((item, index) => {
                            if(item.variableField === ele) {
                              var responseField = ''
                              if(item.typeField === 'checkbox') {
                                var variableCounter = 0
                                item.textField.forEach(ele => {
                                  variableCounter += 1
                                  responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                                })
                              } else {
                                responseField = item.textField
                              }

                              responseObjArray.push({response: responseField})
                            }
                          })
                        })

                        if(cadenaPrompt.length >= 1) {
                          // this.showDelayGPT = true
                          let ocurrenciasParentesis = cadenaPrompt.match(/\(/g);
                          let contadorParentesis = ocurrenciasParentesis ? ocurrenciasParentesis.length : 0;
                          const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                          var counterFor = 0
                          var sendToApi = ''
                          partesNoParentesis.forEach(element => {
                            if(responseObjArray[counterFor]) {
                              sendToApi += (contadorParentesis > counterFor ? element + responseObjArray[counterFor].response : element)
                              counterFor += 1
                            } else {
                              sendToApi += element
                            }
                          })
                          // console.log(2,sendToApi);
                          // var sendToApi = 'Give alt tag for image with this description: ' + response.data.altText

                          var config = {
                            method: 'POST',
                            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            data: JSON.stringify({messages: sendToApi}),
                          }

                          this.axios(config)
                          .then(response => {
                            var obj = {
                              url: responseDallE.data.imageUrl
                            }
                            var config = {
                              method: 'POST',
                              url: 'https://luguzxy83e.execute-api.us-east-2.amazonaws.com/prod/imgseocloud',
                              maxBodyLength: Infinity,
                              headers: {
                                'Content-Type': 'application/json',
                              },
                              data: JSON.stringify(obj),
                            }

                            this.axios(config)
                            .then(async responseData64 => {
                              const refImg = await storage.ref().child('sitesImgs').child(`${Date.now()}DallE`)
                              const upl = await refImg.putString(responseData64.data, 'data_url')
                              const urlDescarga = await refImg.getDownloadURL()

                              this.imgDallE = urlDescarga
                              var responseFromApi = response.data.response
                              // console.log(responseFromApi);
                              this.altDallE = responseFromApi
                              this.busyChatGPT = false
                              this.promptDallE = ''

                              db.collection('Requests').add({
                                url: this.rowError.domain,
                                type: 'DallE-tag',
                                crawlid: site.data().mostRecentCrawl,
                                siteid: this.rowError.idsite,
                                AIPilot: {img: urlDescarga, tag: responseFromApi}
                              })
                              .then(() => console.log('upload req dall'))
                              .catch(e => console.log('Error update AIPilot: ', e.message))
                            })
                          })
                          .catch(e => console.log(e.message))
                        }
                      })
                    })
                  })
                  .catch(e => {
                    this.busyChatGPT = false
                    console.log(e.message);
                  })
                }
              })
            } else if(this.rowError.idc === 3) {
              this.busyChatGPT = true
              this.imgDallE = ''
              this.altDallE = ''
              // console.log(this.rowError);

              if(this.rowError.img) {
                db.collection('Requests').where('url', '==', this.siteSelected[0].cUrl).where('type', '==', 'DallE-tag').where('crawlid', '==', site.data().mostRecentCrawl).limit(1).get()
                .then(qDall => {
                  console.log('ia r: ', qDall.size);
                  if(qDall.size) {
                    qDall.forEach(qEle => {
                      this.arrayMISuggest = qEle.data().AIPilot

                      this.imgDallE = qEle.data().AIPilot.img
                      var responseFromApi = qEle.data().AIPilot.alt
                      // console.log(responseFromApi);
                      this.altDallE = responseFromApi
                      this.busyChatGPT = false
                      this.promptDallE = ''
                    })
                  } else {
                    var typeBuild = 'alts'
                    var cadenaPrompt = []
                    var arrayVariables = []
                    var arrayFieldsAnswer = []
                    var itemsBuilds = []
                    var itemsBuildsCache = []
                    this.busyChatGPT = true
                    dbErrors.collection('IA_form_builder').doc('prompt').get()
                    .then(docPrompt => {
                      var promtObj = docPrompt.data().prompt.filter(ele => ele.typeError === typeBuild)

                      dbErrors.collection('IA_form_builder').doc('errors').get()
                      .then(docBuilder => {
                        if(docBuilder.data().form) {
                          itemsBuilds = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                          itemsBuildsCache = docBuilder.data().form.filter(ele => ele.typeError === typeBuild)
                        }

                        if(promtObj.length >= 1) {
                          const cadena = promtObj[0].logic
                          cadenaPrompt = cadena

                          // Utilizando expresiones regulares con el modificador global para encontrar todas las palabras entre paréntesis
                          const resultados = cadena.match(/\((.*?)\)/g)

                          if(resultados) {
                            const palabrasEntreParentesis = resultados.map(resultado => resultado.slice(1, -1))
                            arrayVariables = palabrasEntreParentesis
                            // console.log(palabrasEntreParentesis)
                            if(palabrasEntreParentesis.includes('web_content')) {
                              itemsBuilds.push({labelField: 'Web content', textField: '', typeField: 'textarea', variableField: 'web_content'})
                            }
                          } else {
                            console.log("No se encontró variables en el prompt.")
                          }
                        } else {
                          cadenaPrompt = []
                          arrayVariables = []
                        }

                        var responseObjArray = []
                        var urlT = new URL(this.rowError.domain)
                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'page_title')
                        buildR.forEach(ele => {
                          ele.textField = this.idsite.name ? this.idsite.name : urlT.pathname
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'web_content')
                        buildR.forEach(ele => {
                          ele.textField = body.data.allText.substring(0, 170)
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'uploaded_image')
                        buildR.forEach(ele => {
                          ele.textField = this.rowError.img
                        })

                        var buildR = itemsBuilds.filter(ele => ele.variableField === 'url_site')
                        buildR.forEach(ele => {
                          ele.textField = this.rowError.domain
                        })

                        var arrayFieldsAnswer = itemsBuilds
                        arrayVariables.forEach(ele => {
                          arrayFieldsAnswer.forEach((item, index) => {
                            if(item.variableField === ele) {
                              var responseField = ''
                              if(item.typeField === 'checkbox') {
                                var variableCounter = 0
                                item.textField.forEach(ele => {
                                  variableCounter += 1
                                  responseField += (variableCounter === 1 ? '(' : '') + ele + (variableCounter === item.textField.length ? '' : ', ' ) + (variableCounter === item.textField.length ? ')' : '')
                                })
                              } else {
                                responseField = item.textField
                              }

                              responseObjArray.push({response: responseField})
                            }
                          })
                        })

                        if(cadenaPrompt.length >= 1) {
                          // this.showDelayGPT = true
                          let ocurrenciasParentesis = cadenaPrompt.match(/\(/g);
                          let contadorParentesis = ocurrenciasParentesis ? ocurrenciasParentesis.length : 0;
                          const partesNoParentesis = cadenaPrompt.split(/\([^()]*\)/)
                          var counterFor = 0
                          var sendToApi = ''
                          partesNoParentesis.forEach(element => {
                            if(responseObjArray[counterFor]) {
                              sendToApi += (contadorParentesis > counterFor ? element + responseObjArray[counterFor].response : element)
                              counterFor += 1
                            } else {
                              sendToApi += element
                            }
                          })
                          // console.log(3, sendToApi);
                          // var sendToApi = 'response ' + response.data.altText

                          var config = {
                            method: 'POST',
                            url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/chat',
                            headers: {
                              'Content-Type': 'application/json',
                            },
                            data: JSON.stringify({messages: sendToApi}),
                          }

                          this.axios(config)
                          .then(response => {
                            // console.log(response.data.response)
                            this.imgDallE = this.rowError.img
                            var responseFromApi = response.data.response
                            // console.log(responseFromApi);
                            this.altDallE = responseFromApi
                            this.busyChatGPT = false
                            this.promptDallE = ''

                            db.collection('Requests').add({
                              url: this.rowError.domain,
                              type: 'DallE-tag',
                              crawlid: site.data().mostRecentCrawl,
                              siteid: this.rowError.idsite,
                              AIPilot: {img: this.rowError.img, tag: responseFromApi}
                            })
                            .then(() => console.log('upload req dall'))
                            .catch(e => console.log('Error update AIPilot: ', e.message))
                          })
                          .catch(e => {
                            this.busyChatGPT = false
                          })
                        }
                      })
                    })
                  }
                })
              }
            }
          })
        }  
      }
    },
    sendJSONFix(val) {
      if(this.rowError.idc !== 7) {
        const user = auth.currentUser
        if(user) {
          db.collection('Sites').doc(this.rowError.idsite).get()
          .then(site => {
            if(site.data().owner && site.data().owner.uid) {
              // check script exitense
              if(user.uid === site.data().owner.uid) {
                var getFirstUser = user.uid.slice(0, 4);
                var getFirstSite = site.id.slice(0, 4);
                var keyID = getFirstUser + getFirstSite

                var obj = {
                  "url": site.data().homepage,
                  "scriptSrc": `https://cdn.seocloud.ai/${keyID}`
                }

                var config = {
                  method: 'POST',
                  url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/OpenAI/check-script',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  data: JSON.stringify(obj),
                }

                this.axios(config)
                .then(response => {
                  console.log('header: ',response.data)
                  if(!response.data.scriptPresent) {
                    // this.isNotAlreadyOwner = false
                    localStorage.setItem('configRunLicense', true)
                  } else {
                    // this.isNotAlreadyOwner = true
                  }
                })
              } else {
                // this.isNotAlreadyOwner = false
                localStorage.setItem('configRunLicense', true)
              }
            } else {
              // history owner lost
              if(site.data().owners && site.data().owners.length) {
                if(site.data().owners.includes(user.uid)) {
                  // this.isNotAlreadyOwner = false
                  localStorage.setItem('configRunLicense', true)
                }
              }
            }
          })

          db.collection('Users').doc(user.uid).get()
          .then(userDoc => {
            setTimeout(() => {
              dbErrors.collection('fixes_cache').doc(val).get()
              .then(docFixCache => {
                var urlTransform = new URL(this.idsite.domainFix)
                var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                const getFirstUser = userDoc.id.slice(0, 4);
                const getFirstSite = this.$route.params.id.slice(0, 4);
                // const getFirstProject = this.$route.params.project.slice(0, 3);
                var keyID = getFirstUser + getFirstSite

                var obj = {}
                obj.author = userDoc.data().email
                obj.css = ""
                obj.domain = domainConverter
                obj.key = keyID
                obj.mode = 1

                var arraychanges = docFixCache.data().changes && docFixCache.data().changes.length ? docFixCache.data().changes : []
                var pagesAlreadyProcessed = []
                arraychanges.forEach(ele => {
                  if(pagesAlreadyProcessed.filter(item => item === ele.url).length === 0) {
                    var urlTransform = new URL(ele.url)
                    // var domainConverter = urlTransform.protocol + '//' + urlTransform.host

                    pagesAlreadyProcessed.push(ele.url)
                    var pathURLConverter = urlTransform.pathname === '/' && !ele.url.endsWith('/') ? ele.url + '/' : ele.url
                    var encodedURL = btoa(pathURLConverter)
                    obj[encodedURL] = {
                      "title": "",
                      "path": urlTransform.pathname,
                      "url": ele.url,
                      "description": "",
                      "canonical": "",
                      "siteName": "",
                      "alt": [],
                      "src": [],
                      "dlinks": [],
                      "gp": [],
                      "tags": {},
                      "tgstxt": {},
                      "findAndReplace": []
                    }

                    var filterFixesOnlyEachPage = arraychanges.filter(item => item.url === ele.url)
                    filterFixesOnlyEachPage.forEach(item => {
                      if(item.idError === 1) {
                        // title
                        obj[encodedURL].title = item.fix
                        // obj[encodedURL].siteName = item.fix
                      } else if(item.idError === 2) {
                        // desc
                        obj[encodedURL].description = item.fix
                      } else if(item.idError === 4) {
                        // h1
                        if(obj[encodedURL].tgstxt.h1 && obj[encodedURL].tgstxt.h1.length) {
                          obj[encodedURL].tgstxt.h1.push({
                            txt: item.old,
                            ntx: item.fix
                          })
                        } else {
                          obj[encodedURL].tgstxt.h1 = [{
                            txt: item.old,
                            ntx: item.fix
                          }]
                        }
                      } else if(item.idError === 5) {
                        // h2
                        if(obj[encodedURL].tgstxt.h2 && obj[encodedURL].tgstxt.h2.length) {
                          obj[encodedURL].tgstxt.h2.push({
                            txt: item.old,
                            ntx: item.fix
                          })
                        } else {
                          obj[encodedURL].tgstxt.h2 = [{
                            txt: item.old,
                            ntx: item.fix
                          }]
                        }
                      } else if(item.idError === 8) {
                        // imgs
                        if(obj[encodedURL].src && obj[encodedURL].src.length) {
                          obj[encodedURL].src.push({
                            url: item.old,
                            src: item.fix
                          })
                        } else {
                          obj[encodedURL].src = [{
                            url: item.old,
                            src: item.fix
                          }]
                        }

                        if(item.alt) {
                          if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                            obj[encodedURL].alt.push({
                              url: item.fix,
                              alt: item.alt
                            })
                          } else {
                            obj[encodedURL].alt = [{
                              url: item.fix,
                              alt: item.alt
                            }]
                          }
                        }
                      } else if(item.idError === 6) {
                        // lWord
                        // 08-03-2024 no available fix/edit (word count is value from crawl page)
                      } else if(item.idError === 13) {
                        // strings
                        if(item.findAndReplace) {
                          if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                            obj[encodedURL].findAndReplace.push({
                              old: item.old,
                              new: item.fix
                            })
                          } else {
                            obj[encodedURL].findAndReplace = [{
                              old: item.old,
                              new: item.fix
                            }]
                          }
                        }
                      } else if(item.idError === 3) {
                        // mAlt
                        if(item.alt) {
                          if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                            obj[encodedURL].alt.push({
                              url: item.urlImg,
                              alt: item.fix
                            })
                          } else {
                            obj[encodedURL].alt = [{
                              url: item.urlImg,
                              alt: item.fix
                            }]
                          }
                        }
                      } else if(item.idError === 9) {
                        // dLink
                        if(item.dlinks) {
                          if(obj[encodedURL].dlinks && obj[encodedURL].dlinks.length) {
                            obj[encodedURL].dlinks.push({
                              url: item.old,
                              rmp: item.fix
                            })
                          } else {
                            obj[encodedURL].dlinks = [{
                              url: item.old,
                              rmp: item.fix
                            }]
                          }
                        }
                      } else if(item.idError === 14) {
                        // missimgs
                        if(obj[encodedURL].src && obj[encodedURL].src.length) {
                          obj[encodedURL].src.push({
                            url: item.old,
                            src: item.fix
                          })
                        } else {
                          obj[encodedURL].src = [{
                            url: item.old,
                            src: item.fix
                          }]
                        }

                        if(item.alt) {
                          if(obj[encodedURL].alt && obj[encodedURL].alt.length) {
                            obj[encodedURL].alt.push({
                              url: item.fix,
                              alt: item.alt
                            })
                          } else {
                            obj[encodedURL].alt = [{
                              url: item.fix,
                              alt: item.alt
                            }]
                          }
                        }
                      } else if(item.idError === 11) {
                        // urlCan
                        obj[encodedURL].canonical = item.fix
                      } else if(item.idError === 10) {
                        // greenPadlockErrors
                      } else if(item.idError === 15) {
                        // Suspicious
                        if(item.findAndReplace) {
                          if(obj[encodedURL].findAndReplace && obj[encodedURL].findAndReplace.length) {
                            obj[encodedURL].findAndReplace.push({
                              old: item.old,
                              new: item.fix
                            })
                          } else {
                            obj[encodedURL].findAndReplace = [{
                              old: item.old,
                              new: item.fix
                            }]
                          }
                        }
                      }
                    })
                  }
                })

                var config = {
                  method: 'POST',
                  url: 'https://apijs-wwkeahz7bq-uc.a.run.app',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  data: JSON.stringify(obj),
                }

                this.axios(config)
                .then(response => {
                  dbErrors.collection('fixes_cache').doc(val).update({
                    api_file_request: obj
                  })
                  
                  console.log('fixes file updated: '+response.data.status)
                })
                .catch(e => console.log(e.message))
              })
            }, 2000)
          })
        }
      }
    },
    saveImgDallE(payloadImgDallE, payloadAltDallE) {
      this.processSave = true
      // console.log(payloadImgDallE);
      var obj = {
        url: payloadImgDallE
      }
      var config = {
        method: 'POST',
        url: 'https://luguzxy83e.execute-api.us-east-2.amazonaws.com/prod/imgseocloud',
        maxBodyLength: Infinity,
        headers: {
          'Content-Type': 'application/json',
        },
        data: JSON.stringify(obj),
      }

      this.axios(config)
      .then(async response => {
        // console.log(response.data);
        const refImg = await storage.ref().child('sitesImgs').child(`${Date.now()}DallE`)
        const upl = await refImg.putString(response.data, 'data_url')
        const urlDescarga = await refImg.getDownloadURL()
        this.fixText = urlDescarga
        this.fixTextAlt = payloadAltDallE
        this.saveChange()
      })
      .catch(e => {
        console.error(e.message)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Error: Please try again!`,
            icon: 'BellIcon',
            variant: 'warning',
          },
        })
        this.processSave = false
      })
    },
    saveChange() {
      // console.log('first')
      this.processSave = true
      var user = auth.currentUser
      // save changes
      db.collection('Users').doc(user.uid).get()
      .then(userDoc => {
        var authUser = {
          nombre: userDoc.data().nombre ? userDoc.data().nombre : '',
          email: userDoc.data().email,
          uid: userDoc.data().uid,
          foto: userDoc.data().foto
        }

        // console.log(this.rowError)
        // console.log(this.rowError.idsite,this.rowError.idpage,this.rowError.idc.toString())
        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).get()
        .then(siteProject => {
        db.collection('Sites').doc(this.rowError.idsite).get()
        .then(siteDoc => {
          var listCounterPage = siteDoc.data().errorsPages && siteDoc.data().errorsPages.length ? siteDoc.data().errorsPages : []
          var filterCounterPage = listCounterPage.filter(eleErr => eleErr.url === this.rowError.domain)
          var docsObjErrors = siteDoc.data().errorsTypePage && siteDoc.data().errorsTypePage.length >= 1 ? siteDoc.data().errorsTypePage : []
          var filterObjErrorsFilter = docsObjErrors.filter(errObj => errObj.url === this.rowError.domain)
          var filterObjNoSiteErrorsFilter = docsObjErrors.filter(errObj => errObj.url !== this.rowError.domain)
          if(this.rowError.exist === true) {
            filterObjErrorsFilter.forEach(elePage => {
              if(elePage.errors && elePage.errors.length) {
                elePage.errors.forEach(eleCounter => {
                  if(this.rowError.idc === 1) {
                    if(eleCounter.type === 'title') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 2) {
                    if(eleCounter.type === 'desc') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 4) {
                    if(eleCounter.type === 'h1') {
                      this.counterListError >= 2 ? eleCounter.counter -= 1 : null
                    }
                  } else if(this.rowError.idc === 5) {
                    if(eleCounter.type === 'h2') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 8) {
                    if(eleCounter.type === 'imgs') {
                      // ele.beforeIgnore === true ? eleCounter.counter -= 1 : null
                      eleCounter.counter >= 1 ? eleCounter.counter -= 1 : null
                    }
                  } else if(this.rowError.idc === 6) {
                    if(eleCounter.type === 'lWord') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 13) {
                    if(eleCounter.type === 'strings') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 3) {
                    if(eleCounter.type === 'mAlt') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 9) {
                    if(eleCounter.type === 'dLink') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 14) {
                    if(eleCounter.type === 'imgs') {
                      // ele.beforeIgnore === true ? eleCounter.counter -= 1 : null
                      eleCounter.counter >= 1 ? eleCounter.counter -= 1 : null
                    }
                  } else if(this.rowError.idc === 11) {
                    if(eleCounter.type === 'urlCan') {
                      eleCounter.counter -= 1
                    }
                  } else if(this.rowError.idc === 10) {
                    // if(eleCounter.type === 'urlCan') {
                    //   eleCounter.counter -= 1
                    // }
                  } else if(this.rowError.idc === 15) {
                    if(eleCounter.type === 'strings') {
                      eleCounter.counter -= 1
                    }
                  }
                })
              }
            })

            var counterFixErr = 0
            filterObjErrorsFilter.forEach(eleSiteTypeError => {
              if(counterFixErr === 0) {
                counterFixErr += 1
                filterObjNoSiteErrorsFilter.push(eleSiteTypeError)
              }
            })

            db.collection('Sites').doc(this.rowError.idsite).update({
              errorsTypePage: filterObjNoSiteErrorsFilter
            })
          }

          if(this.rowError.idc === 1) {
            var arrayPages = siteDoc.data().pages && siteDoc.data().pages.length >= 1 ? siteDoc.data().pages : []
            var filterPageTitle = arrayPages.filter(ele => ele.url === this.rowError.domain && ele.status !== null)
            var filterNoPageTitle = arrayPages.filter(ele => ele.url !== this.rowError.domain)
            if(filterPageTitle.length >= 1) {
              var counterPageT = 0
              filterPageTitle.forEach(ele => {
                if(counterPageT === 0) {
                  counterPageT += 1
                  ele.title = this.fixText
                  filterNoPageTitle.push(ele)
                }
              })

              db.collection('Sites').doc(this.rowError.idsite).update({
                pages: filterNoPageTitle
              })
              console.log('updated title')
            }
          }

          dbErrors.collection('fixes').where('idpage', '==', this.rowError.domain).limit(1).get()
          .then(docPageFixCollection => {
          dbErrors.collection('fixes_cache').where('SiteID', '==', this.rowError.idsite).limit(1).get()
          .then(docPageFixCacheCollection => {
            var idDocPageFixCacheColl = ''
            var changesFixesCache = []
            docPageFixCacheCollection.forEach(docPageFixCacheColl => {
              idDocPageFixCacheColl = docPageFixCacheColl.id
              changesFixesCache = docPageFixCacheColl.data().changes
            })

            // exist already fix document
            if(docPageFixCollection.size >= 1) {
              var idDocPageFixColl = ''
              var counterErrorFixes = 0
              var arrayFixesDomain = []
              var originalTextFix = ''
              docPageFixCollection.forEach(docPageFixColl => {
                idDocPageFixColl = docPageFixColl.id
                if(docPageFixColl.data().changes && docPageFixColl.data().changes.length >= 1) {
                  docPageFixColl.data().changes.forEach(ele => {
                    arrayFixesDomain.push(ele)
                    if(ele.idfix === 1 && ele.idError === this.rowError.idc && ele.url === this.rowError.domain && ele.id === this.rowError.idError) {
                      this.rowError.idc === 3 ? originalTextFix = ele.urlImg : originalTextFix = ele.old
                    }
                  })
                }
              })
              // !originalTextFix ? originalTextFix = this.oldText : null
              counterErrorFixes = (arrayFixesDomain.filter(ele => ele.idError === this.rowError.idc && ele.url === this.rowError.domain && ele.id === this.rowError.idError).length) + 1
              if(arrayFixesDomain.filter(ele => ele.idError === this.rowError.idc && ele.url === this.rowError.domain && ele.id === this.rowError.idError).length === 0) {
                this.rowError.idc === 3 ? originalTextFix = this.rowError.img : originalTextFix = this.oldText
              }
              !originalTextFix && this.rowError.idc === 3 ? originalTextFix = this.rowError.img : !originalTextFix ? originalTextFix = this.oldText : null

              dbErrors.collection('Errors').where('idpage', '==', this.rowError.domain).limit(1).get()
              .then(docPageError => {
                var idDocError = ''
                var changesDocError = []
                var listErrors = []
                var changesPage = []
                docPageError.forEach(docError => {
                  idDocError = docError.id
                  changesDocError = docError.data().changes ? docError.data().changes : []
                  listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                  changesPage = docError.data().changes ? docError.data().changes : []
                })

                var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                var filterSizePageExclude = pageSizes.filter(ele => ele.url !== this.rowError.domain)
                if(!changesPage.length) {
                  // update asignated pro and sizes
                  db.collection('Sites').doc(this.rowError.idsite).update({
                    asignatedProPages: true,
                    pageSizes: filterSizePageExclude
                  })

                  // update error status and cont/est
                  var filterErr = []
                  if(this.rowError.idc === 8) {
                    filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.rowError.domain))

                    if(filterErr.length) {
                      filterErr.forEach(ele => {
                        // console.log(ele)
                        dbErrors.collection('Errors').doc(idDocError).update({
                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                        })
                        .then(() => {
                          var originalIE = ele.iE
                          if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                            ele.iE = false
                          } else {
                            this.counterListError >= 2 ? null : ele.iE = false
                          }

                          if(ele.idc === 3) {
                            ele.est = this.fixTextAlt
                            ele.cont = this.fixText
                            ele.sta = 200
                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                            if(originalIE) {
                              if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                db.collection('Sites').doc(this.rowError.idsite).update({
                                  'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                })

                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                                  totalErrors: siteProject.data().totalErrors - 1
                                })
                              }
                            }
                          } else {
                            ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                            ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                          }
                          dbErrors.collection('Errors').doc(idDocError).update({
                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                          })
                          .catch(e => console.log('Error add err: ', e.message))
                        })
                        .catch(e => console.log('Error delete err: ', e.message))
                      })
                    }
                  } else {
                    filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE)

                    if(filterErr.length) {
                      filterErr.forEach(ele => {
                        // console.log(ele)
                        dbErrors.collection('Errors').doc(idDocError).update({
                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                        })
                        .then(() => {
                          if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                            ele.iE = false
                          } else {
                            this.counterListError >= 2 ? null : ele.iE = false
                          }
                          this.rowError.idc === 13 || this.rowError.idc === 15 || this.rowError.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                          this.rowError.idc === 3 ? ele.sta = 200 : this.rowError.idc === 8 || this.rowError.idc === 14 ? ele.est = 200 : null
                          ele.history && ele.history.length >= 1 ? ele.history.push({id: this.rowError.idError, idc: this.rowError.idc}) : ele.history = [{id: this.rowError.idError, idc: this.rowError.idc}]
                          dbErrors.collection('Errors').doc(idDocError).update({
                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                          })
                          .catch(e => console.log('Error add err: ', e.message))
                        })
                        .catch(e => console.log('Error delete err: ', e.message))
                      })
                    }
                  }

                  // update error counter category
                  if(this.rowError.idc === 1) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 2) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 4) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                      if(this.counterListError <= 1) {
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                        })

                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                          totalErrors: siteProject.data().totalErrors - 1
                        })
                      }
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 5) {
                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                    //   })
                    // }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 8) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 6) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 13) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 3) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 9) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 14) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 11) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 10) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 15) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                    //   })
                    // }
                  }
                  
                  // update error counters by page
                  if(filterCounterPage.length) {
                    filterErr.forEach(ele => {
                      if(ele.counter) {
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                        })
                        .then(() => {
                          ele.counter = ele.counter - 1
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                          })
                        })
                      }
                    })
                  }

                  if(changesPage.length) {
                    // update asignated sizes
                    db.collection('Sites').doc(this.rowError.idsite).update({
                      pageSizes: filterSizePageExclude
                    })

                    // update Errors page
                    if(idDocError) {
                      if(changesDocError.length) {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: firebase.firestore.FieldValue.arrayUnion({
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix,
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }),
                          has_fix: true
                        })
                      } else {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix,
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }],
                          has_fix: true
                        })
                      }
                    }
                  } else {
                    // update asignated sizes
                    db.collection('Sites').doc(this.rowError.idsite).update({
                      pageSizes: filterSizePageExclude
                    })

                    // update Errors page
                    if(idDocError) {
                      if(changesDocError.length) {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: firebase.firestore.FieldValue.arrayUnion({
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix,
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }),
                          has_fix: true
                        })
                      } else {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix,
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }],
                          has_fix: true
                        })
                      }
                    }
                  }

                  setTimeout(() => {
                    this.$emit('update:changeerror', true)
                    var arrayChan = []
                    this.arraychanges.forEach(ele => {
                      arrayChan.push(ele)
                    })
                    arrayChan.push(this.rowError.idError)
                    this.$emit('update:arraychanges', arrayChan)
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `Change applied`,
                        icon: 'BellIcon',
                        variant: 'success',
                      },
                    })
                    this.$refs.sidebar_fix_error.hide()
                    this.sendJSONFix(idDocPageFixCacheColl)
                    this.processSave = false
                    this.fixText = ''
                    this.fixTextAlt = ''
                    this.oldText = ''
                  }, 1700)
                } else {
                  // update asignated pro
                  db.collection('Sites').doc(this.rowError.idsite).update({
                    asignatedProPages: true,
                    pageSizes: filterSizePageExclude
                  })

                  // update error status and cont/est
                  var filterErr = []
                  if(this.rowError.idc === 8) {
                    filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.rowError.domain))

                    if(filterErr.length) {
                      filterErr.forEach(ele => {
                        // console.log(ele)
                        dbErrors.collection('Errors').doc(idDocError).update({
                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                        })
                        .then(() => {
                          var originalIE = ele.iE
                          if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                            ele.iE = false
                          } else {
                            this.counterListError >= 2 ? null : ele.iE = false
                          }

                          if(ele.idc === 3) {
                            ele.est = this.fixTextAlt
                            ele.cont = this.fixText
                            ele.sta = 200
                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                            if(originalIE) {
                              if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                db.collection('Sites').doc(this.rowError.idsite).update({
                                  'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                })

                                db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                                  totalErrors: siteProject.data().totalErrors - 1
                                })
                              }
                            }
                          } else {
                            ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                            ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                            ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                          }
                          dbErrors.collection('Errors').doc(idDocError).update({
                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                          })
                          .catch(e => console.log('Error add err: ', e.message))
                        })
                        .catch(e => console.log('Error delete err: ', e.message))
                      })
                    }
                  } else {
                    filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE)

                    if(filterErr.length) {
                      filterErr.forEach(ele => {
                        // console.log(ele)
                        dbErrors.collection('Errors').doc(idDocError).update({
                          errors: firebase.firestore.FieldValue.arrayRemove(ele)
                        })
                        .then(() => {
                          if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                            ele.iE = false
                          } else {
                            this.counterListError >= 2 ? null : ele.iE = false
                          }
                          this.rowError.idc === 13 || this.rowError.idc === 15 || this.rowError.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                          this.rowError.idc === 3 ? ele.sta = 200 : this.rowError.idc === 8 || this.rowError.idc === 14 ? ele.est = 200 : null
                          ele.history && ele.history.length >= 1 ? ele.history.push({id: this.rowError.idError, idc: this.rowError.idc}) : ele.history = [{id: this.rowError.idError, idc: this.rowError.idc}]
                          dbErrors.collection('Errors').doc(idDocError).update({
                            errors: firebase.firestore.FieldValue.arrayUnion(ele)
                          })
                          .catch(e => console.log('Error add err: ', e.message))
                        })
                        .catch(e => console.log('Error delete err: ', e.message))
                      })
                    }
                  }

                  // update error counter category
                  if(this.rowError.idc === 1) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 2) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 4) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                      if(this.counterListError <= 1) {
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                        })

                        db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                          totalErrors: siteProject.data().totalErrors - 1
                        })
                      }
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 5) {
                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                    //   })
                    // }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 8) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 6) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 13) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 3) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 9) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 14) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 11) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 10) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                    //   })
                    // }
                  } else if(this.rowError.idc === 15) {
                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                      db.collection('Sites').doc(this.rowError.idsite).update({
                        'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                      })

                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                        totalErrors: siteProject.data().totalErrors - 1
                      })
                    }

                    // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                    //   db.collection('Sites').doc(this.rowError.idsite).update({
                    //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                    //   })
                    // }
                  }
                  
                  // update error counters by page
                  if(filterCounterPage.length) {
                    filterErr.forEach(ele => {
                      if(ele.counter) {
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                        })
                        .then(() => {
                          ele.counter = ele.counter - 1
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                          })
                        })
                      }
                    })
                  }

                  if(changesPage.length) {
                    // update asignated sizes
                    db.collection('Sites').doc(this.rowError.idsite).update({
                      pageSizes: filterSizePageExclude
                    })

                    // update Errors page
                    if(idDocError) {
                      if(changesDocError.length) {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: firebase.firestore.FieldValue.arrayUnion({
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix,
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }),
                          has_fix: true
                        })
                      } else {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix,
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }],
                          has_fix: true
                        })
                      }
                    }
                  } else {
                    // update asignated sizes
                    db.collection('Sites').doc(this.rowError.idsite).update({
                      pageSizes: filterSizePageExclude
                    })

                    // update Errors page
                    if(idDocError) {
                      if(changesDocError.length) {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: firebase.firestore.FieldValue.arrayUnion({
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix,
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }),
                          has_fix: true
                        })
                      } else {
                        dbErrors.collection('Errors').doc(idDocError).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: originalTextFix,
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser,
                            idfix: counterErrorFixes
                          }],
                          has_fix: true
                        })
                      }
                    }
                  }

                  setTimeout(() => {
                    this.$emit('update:changeerror', true)
                    var arrayChan = []
                    this.arraychanges.forEach(ele => {
                      arrayChan.push(ele)
                    })
                    arrayChan.push(this.rowError.idError)
                    this.$emit('update:arraychanges', arrayChan)
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: `Change applied`,
                        icon: 'BellIcon',
                        variant: 'success',
                      },
                    })
                    this.$refs.sidebar_fix_error.hide()
                    this.sendJSONFix(idDocPageFixCacheColl)
                    this.processSave = false
                    this.fixText = ''
                    this.fixTextAlt = ''
                    this.oldText = ''
                  }, 1700)
                }

                if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                  // console.log('1')
                  if(this.rowError.idc === 3) {
                    dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                      changes: firebase.firestore.FieldValue.arrayUnion({
                        id: this.rowError.idError,
                        idError: this.rowError.idc,
                        typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                        old: this.rowError.idc === 3 ? this.oldText : originalTextFix,
                        fix: this.fixText,
                        alt: this.fixTextAlt,
                        url: this.rowError.domain ? this.rowError.domain : '',
                        urlImg: originalTextFix,
                        date: Date.now(),
                        user: authUser.uid,
                        idfix: counterErrorFixes
                      }),
                      has_fix: true,
                      updated: Date.now()
                    })

                    var filterchangesFixesCache8 = changesFixesCache.filter(ele => ele.urlImg === originalTextFix && ele.idError === 8 && ele.url === this.rowError.domain)
                    if(filterchangesFixesCache8.length === 0) {
                    } else {
                      var filterNochangesFixesCache = []
                      var idChangeCache = ''
                      changesFixesCache.forEach(ele => {
                        if(ele.urlImg === originalTextFix && ele.idError === 8 && ele.url === this.rowError.domain) {
                          idChangeCache = ele.id
                        } else {
                          filterNochangesFixesCache.push(ele)
                        }
                      })
                      filterNochangesFixesCache.push({
                        id: idChangeCache,
                        idError: 8,
                        typeError: 'Images',
                        old: this.rowError.idc === 3 ? this.oldText : originalTextFix,
                        fix: this.fixText,
                        alt: this.fixTextAlt,
                        url: this.rowError.domain ? this.rowError.domain : '',
                        urlImg: originalTextFix,
                        date: Date.now(),
                        // user: authUser,
                        idfix: counterErrorFixes
                      })

                      dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                        changes: firebase.firestore.FieldValue.arrayUnion({
                          id: filterNochangesFixesCache[0].id,
                          idError: 8,
                          typeError: 'Images',
                          old: this.rowError.idc === 3 ? this.oldText : originalTextFix,
                          fix: this.fixText,
                          alt: this.fixTextAlt,
                          url: this.rowError.domain ? this.rowError.domain : '',
                          urlImg: originalTextFix,
                          date: Date.now(),
                          user: authUser.uid,
                          idfix: counterErrorFixes
                        }),
                        has_fix: true,
                        updated: Date.now()
                      })

                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                        changes: filterNochangesFixesCache,
                        has_fix: true,
                        updated: Date.now()
                      })
                    }

                    var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain)
                    if(filterchangesFixesCache.length === 0) {
                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                        changes: firebase.firestore.FieldValue.arrayUnion({
                          id: this.rowError.idError,
                          idError: this.rowError.idc,
                          typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                          old: this.rowError.idc === 3 ? this.oldText : originalTextFix,
                          fix: this.fixText,
                          alt: this.fixTextAlt,
                          url: this.rowError.domain ? this.rowError.domain : '',
                          urlImg: originalTextFix,
                          date: Date.now(),
                          // user: authUser,
                          idfix: counterErrorFixes
                        }),
                        has_fix: true,
                        updated: Date.now()
                      })
                    } else {
                      // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== this.rowError.idError && ele.idError !== this.rowError.idc && ele.url !== this.rowError.domain)
                      var filterNochangesFixesCache = []
                      changesFixesCache.forEach(ele => {
                        // console.log(ele)
                        if(ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain) {
                          // filterNochangesFixesCache.push(ele)
                          // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                        } else {
                          // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                          filterNochangesFixesCache.push(ele)
                        }
                      })
                      // console.log(filterNochangesFixesCache, changesFixesCache)
                      filterNochangesFixesCache.push({
                        id: this.rowError.idError,
                        idError: this.rowError.idc,
                        typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                        old: this.rowError.idc === 3 ? this.oldText : originalTextFix,
                        fix: this.fixText,
                        alt: this.fixTextAlt,
                        url: this.rowError.domain ? this.rowError.domain : '',
                        urlImg: originalTextFix,
                        date: Date.now(),
                        // user: authUser,
                        idfix: counterErrorFixes
                      })

                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                        changes: filterNochangesFixesCache,
                        has_fix: true,
                        updated: Date.now()
                      })
                    }
                  } else {
                    dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                      changes: firebase.firestore.FieldValue.arrayUnion({
                        id: this.rowError.idError,
                        idError: this.rowError.idc,
                        typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                        old: this.rowError.idc === 3 ? this.oldText : originalTextFix,
                        fix: this.fixText,
                        alt: this.fixTextAlt,
                        url: this.rowError.domain ? this.rowError.domain : '',
                        urlImg: originalTextFix,
                        date: Date.now(),
                        user: authUser.uid,
                        idfix: counterErrorFixes
                      }),
                      has_fix: true,
                      updated: Date.now()
                    })

                    var filterchangesFixesCache3 = changesFixesCache.filter(ele => ele.urlImg === originalTextFix && ele.idError === 3 && ele.url === this.rowError.domain)
                    if(filterchangesFixesCache3.length === 0) {
                    } else {
                      var filterNochangesFixesCache = []
                      var idChangeCache = ''
                      changesFixesCache.forEach(ele => {
                        if(ele.urlImg === originalTextFix && ele.idError === 3 && ele.url === this.rowError.domain) {
                          idChangeCache = ele.id
                        } else {
                          filterNochangesFixesCache.push(ele)
                        }
                      })
                      filterNochangesFixesCache.push({
                        id: idChangeCache,
                        idError: 3,
                        typeError: 'Missing alts',
                        old: this.rowError.idc === 3 ? this.oldText : originalTextFix,
                        fix: this.fixText,
                        alt: this.fixTextAlt,
                        url: this.rowError.domain ? this.rowError.domain : '',
                        urlImg: originalTextFix,
                        date: Date.now(),
                        // user: authUser,
                        idfix: counterErrorFixes
                      })

                      dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                        changes: firebase.firestore.FieldValue.arrayUnion({
                          id: filterNochangesFixesCache[0].id,
                          idError: 3,
                          typeError: 'Missing alts',
                          old: this.rowError.idc === 3 ? this.oldText : originalTextFix,
                          fix: this.fixText,
                          alt: this.fixTextAlt,
                          url: this.rowError.domain ? this.rowError.domain : '',
                          urlImg: originalTextFix,
                          date: Date.now(),
                          user: authUser.uid,
                          idfix: counterErrorFixes
                        }),
                        has_fix: true,
                        updated: Date.now()
                      })

                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                        changes: filterNochangesFixesCache,
                        has_fix: true,
                        updated: Date.now()
                      })
                    }

                    var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain)
                    if(filterchangesFixesCache.length === 0) {
                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                        changes: firebase.firestore.FieldValue.arrayUnion({
                          id: this.rowError.idError,
                          idError: this.rowError.idc,
                          typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                          old: this.rowError.idc === 3 ? this.oldText : originalTextFix,
                          fix: this.fixText,
                          alt: this.fixTextAlt,
                          url: this.rowError.domain ? this.rowError.domain : '',
                          urlImg: originalTextFix,
                          date: Date.now(),
                          // user: authUser,
                          idfix: counterErrorFixes
                        }),
                        has_fix: true,
                        updated: Date.now()
                      })
                    } else {
                      // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== this.rowError.idError && ele.idError !== this.rowError.idc && ele.url !== this.rowError.domain)
                      var filterNochangesFixesCache = []
                      changesFixesCache.forEach(ele => {
                        // console.log(ele)
                        if(ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain) {
                          // filterNochangesFixesCache.push(ele)
                          // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                        } else {
                          // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                          filterNochangesFixesCache.push(ele)
                        }
                      })
                      // console.log(filterNochangesFixesCache, changesFixesCache)
                      filterNochangesFixesCache.push({
                        id: this.rowError.idError,
                        idError: this.rowError.idc,
                        typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                        old: this.rowError.idc === 3 ? this.oldText : originalTextFix,
                        fix: this.fixText,
                        alt: this.fixTextAlt,
                        url: this.rowError.domain ? this.rowError.domain : '',
                        urlImg: originalTextFix,
                        date: Date.now(),
                        // user: authUser,
                        idfix: counterErrorFixes
                      })

                      dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                        changes: filterNochangesFixesCache,
                        has_fix: true,
                        updated: Date.now()
                      })
                    }
                  }
                } else {
                  dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                    changes: firebase.firestore.FieldValue.arrayUnion({
                      id: this.rowError.idError,
                      idError: this.rowError.idc,
                      typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                      old: originalTextFix,
                      fix: this.fixText,
                      url: this.rowError.domain ? this.rowError.domain : '',
                      date: Date.now(),
                      user: authUser.uid,
                      idfix: counterErrorFixes
                    }),
                    has_fix: true,
                    updated: Date.now()
                  })

                  var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain)
                  // console.log(filterchangesFixesCache)
                  if(filterchangesFixesCache.length === 0) {
                    dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                      changes: firebase.firestore.FieldValue.arrayUnion({
                        id: this.rowError.idError,
                        idError: this.rowError.idc,
                        typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                        old: originalTextFix,
                        fix: this.fixText,
                        url: this.rowError.domain ? this.rowError.domain : '',
                        date: Date.now(),
                        // user: authUser,
                        idfix: counterErrorFixes
                      }),
                      has_fix: true,
                      updated: Date.now()
                    })
                  } else {
                    // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== this.rowError.idError && ele.idError !== this.rowError.idc && ele.url !== this.rowError.domain)
                    var filterNochangesFixesCache = []
                    changesFixesCache.forEach(ele => {
                      // console.log(ele)
                      if(ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain) {
                        // filterNochangesFixesCache.push(ele)
                        // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                      } else {
                        // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                        filterNochangesFixesCache.push(ele)
                      }
                    })
                    // console.log(filterNochangesFixesCache, changesFixesCache)
                    filterNochangesFixesCache.push({
                      id: this.rowError.idError,
                      idError: this.rowError.idc,
                      typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                      old: originalTextFix,
                      fix: this.fixText,
                      url: this.rowError.domain ? this.rowError.domain : '',
                      date: Date.now(),
                      // user: authUser,
                      idfix: counterErrorFixes
                    })

                    dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                      changes: filterNochangesFixesCache,
                      has_fix: true,
                      updated: Date.now()
                    })
                  }
                }
              })
            } else {
              if(!idDocPageFixCacheColl) {
                // doesnt exist fix document
                dbErrors.collection('fixes_cache').add({
                  SiteID: this.rowError.idsite,
                  // idpage: this.rowError.domain,
                  owner: authUser.uid,
                  ownerEmail: authUser.email,
                  changes: [],
                  updated: Date.now()
                }).then((docRefFixCacheColl) => {
                  idDocPageFixCacheColl = docRefFixCacheColl.id
                  
                  dbErrors.collection('fixes').add({
                    SiteID: this.rowError.idsite,
                    idpage: this.rowError.domain,
                    changes: [],
                    updated: Date.now()
                  }).then((docRefFixColl) => {
                    var idDocPageFixColl = docRefFixColl.id
                    // var idDocPageFixCacheColl = docRefFixCacheColl.id

                    dbErrors.collection('Errors').where('idpage', '==', this.rowError.domain).limit(1).get()
                    .then(docPageError => {
                      var idDocError = ''
                      var changesDocError = []
                      var listErrors = []
                      var changesPage = []
                      docPageError.forEach(docError => {
                        idDocError = docError.id
                        changesDocError = docError.data().changes ? docError.data().changes : []
                        listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                        changesPage = docError.data().changes ? docError.data().changes : []
                      })

                      var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                      var filterSizePageExclude = pageSizes.filter(ele => ele.url !== this.rowError.domain)
                      if(!changesPage.length) {
                        // update asignated pro and sizes
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          asignatedProPages: true,
                          pageSizes: filterSizePageExclude
                        })

                        // update error status and cont/est
                        var filterErr = []
                        if(this.rowError.idc === 8) {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.rowError.domain))

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                var originalIE = ele.iE
                                if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }

                                if(ele.idc === 3) {
                                  ele.est = this.fixTextAlt
                                  ele.cont = this.fixText
                                  ele.sta = 200
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                  if(originalIE) {
                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(this.rowError.idsite).update({
                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })

                                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                                        totalErrors: siteProject.data().totalErrors - 1
                                      })
                                    }
                                  }
                                } else {
                                  ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                  ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                }
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        } else {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE)

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }
                                this.rowError.idc === 13 || this.rowError.idc === 15 || this.rowError.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                this.rowError.idc === 3 ? ele.sta = 200 : this.rowError.idc === 8 || this.rowError.idc === 14 ? ele.est = 200 : null
                                ele.history && ele.history.length >= 1 ? ele.history.push({id: this.rowError.idError, idc: this.rowError.idc}) : ele.history = [{id: this.rowError.idError, idc: this.rowError.idc}]
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        }

                        // update error counter category
                        if(this.rowError.idc === 1) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 2) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 4) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                            if(this.counterListError <= 1) {
                              db.collection('Sites').doc(this.rowError.idsite).update({
                                'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                              })

                              db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                                totalErrors: siteProject.data().totalErrors - 1
                              })
                            }
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 5) {
                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                          //   })
                          // }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 8) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 6) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 13) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 3) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 9) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 14) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 11) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 10) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 15) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                          //   })
                          // }
                        }
                        
                        // update error counters by page
                        if(filterCounterPage.length) {
                          filterErr.forEach(ele => {
                            if(ele.counter) {
                              db.collection('Sites').doc(this.rowError.idsite).update({
                                errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                ele.counter = ele.counter - 1
                                db.collection('Sites').doc(this.rowError.idsite).update({
                                  errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                              })
                            }
                          })
                        }

                        if(changesPage.length) {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        } else {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        }

                        setTimeout(() => {
                          this.$emit('update:changeerror', true)
                          var arrayChan = []
                          this.arraychanges.forEach(ele => {
                            arrayChan.push(ele)
                          })
                          arrayChan.push(this.rowError.idError)
                          this.$emit('update:arraychanges', arrayChan)
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Change applied`,
                              icon: 'BellIcon',
                              variant: 'success',
                            },
                          })
                          this.$refs.sidebar_fix_error.hide()
                          this.sendJSONFix(idDocPageFixCacheColl)
                          this.processSave = false
                          this.fixText = ''
                          this.fixTextAlt = ''
                          this.oldText = ''
                        }, 1700)
                      } else {
                        // update asignated pro
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          asignatedProPages: true,
                          pageSizes: filterSizePageExclude
                        })

                        // update error status and cont/est
                        var filterErr = []
                        if(this.rowError.idc === 8) {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.rowError.domain))

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                var originalIE = ele.iE
                                if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }

                                if(ele.idc === 3) {
                                  ele.est = this.fixTextAlt
                                  ele.cont = this.fixText
                                  ele.sta = 200
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                  if(originalIE) {
                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(this.rowError.idsite).update({
                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })

                                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                                        totalErrors: siteProject.data().totalErrors - 1
                                      })
                                    }
                                  }
                                } else {
                                  ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                  ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                }
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        } else {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE)

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }
                                this.rowError.idc === 13 || this.rowError.idc === 15 || this.rowError.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                this.rowError.idc === 3 ? ele.sta = 200 : this.rowError.idc === 8 || this.rowError.idc === 14 ? ele.est = 200 : null
                                ele.history && ele.history.length >= 1 ? ele.history.push({id: this.rowError.idError, idc: this.rowError.idc}) : ele.history = [{id: this.rowError.idError, idc: this.rowError.idc}]
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        }

                        // update error counter category
                        if(this.rowError.idc === 1) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 2) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 4) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                            if(this.counterListError <= 1) {
                              db.collection('Sites').doc(this.rowError.idsite).update({
                                'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                              })

                              db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                                totalErrors: siteProject.data().totalErrors - 1
                              })
                            }
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 5) {
                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                          //   })
                          // }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 8) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 6) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 13) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 3) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 9) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 14) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 11) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 10) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 15) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                          //   })
                          // }
                        }
                        
                        // update error counters by page
                        if(filterCounterPage.length) {
                          filterErr.forEach(ele => {
                            if(ele.counter) {
                              db.collection('Sites').doc(this.rowError.idsite).update({
                                errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                ele.counter = ele.counter - 1
                                db.collection('Sites').doc(this.rowError.idsite).update({
                                  errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                              })
                            }
                          })
                        }

                        if(changesPage.length) {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        } else {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        }

                        setTimeout(() => {
                          this.$emit('update:changeerror', true)
                          var arrayChan = []
                          this.arraychanges.forEach(ele => {
                            arrayChan.push(ele)
                          })
                          arrayChan.push(this.rowError.idError)
                          this.$emit('update:arraychanges', arrayChan)
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Change applied`,
                              icon: 'BellIcon',
                              variant: 'success',
                            },
                          })
                          this.$refs.sidebar_fix_error.hide()
                          this.sendJSONFix(idDocPageFixCacheColl)
                          this.processSave = false
                          this.fixText = ''
                          this.fixTextAlt = ''
                          this.oldText = ''
                        }, 1700)
                      }

                      if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                        // console.log('2')
                        dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: this.oldText,
                            fix: this.fixText,
                            alt: this.fixTextAlt,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                            date: Date.now(),
                            user: authUser.uid,
                            idfix: 1
                          }],
                          has_fix: true,
                          updated: Date.now()
                        })

                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: this.oldText,
                            fix: this.fixText,
                            alt: this.fixTextAlt,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                            date: Date.now(),
                            // user: authUser,
                            idfix: 1
                          }],
                          has_fix: true,
                          updated: Date.now()
                        })
                      } else {
                        dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: this.oldText,
                            fix: this.fixText,
                            alt: this.fixTextAlt,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                            date: Date.now(),
                            user: authUser.uid,
                            idfix: 1
                          }],
                          has_fix: true,
                          updated: Date.now()
                        })

                        dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: this.oldText,
                            fix: this.fixText,
                            alt: this.fixTextAlt,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                            date: Date.now(),
                            // user: authUser,
                            idfix: 1
                          }],
                          has_fix: true,
                          updated: Date.now()
                        })
                      }
                    })
                  })
                })
              } else {
                // doesnt exist fix document
                  dbErrors.collection('fixes').add({
                    SiteID: this.rowError.idsite,
                    idpage: this.rowError.domain,
                    changes: [],
                    updated: Date.now()
                  }).then((docRefFixColl) => {
                    var idDocPageFixColl = docRefFixColl.id
                    // var idDocPageFixCacheColl = docRefFixCacheColl.id

                    dbErrors.collection('Errors').where('idpage', '==', this.rowError.domain).limit(1).get()
                    .then(docPageError => {
                      var idDocError = ''
                      var changesDocError = []
                      var listErrors = []
                      var changesPage = []
                      docPageError.forEach(docError => {
                        idDocError = docError.id
                        changesDocError = docError.data().changes ? docError.data().changes : []
                        listErrors = docError.data().errors && docError.data().errors.length ? docError.data().errors : []
                        changesPage = docError.data().changes ? docError.data().changes : []
                      })

                      var pageSizes = siteDoc.data().pageSizes && siteDoc.data().pageSizes.length ? siteDoc.data().pageSizes : []
                      var filterSizePageExclude = pageSizes.filter(ele => ele.url !== this.rowError.domain)
                      if(!changesPage.length) {
                        // update asignated pro and sizes
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          asignatedProPages: true,
                          pageSizes: filterSizePageExclude
                        })

                        // update error status and cont/est
                        var filterErr = []
                        if(this.rowError.idc === 8) {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.rowError.domain))

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                var originalIE = ele.iE
                                if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }

                                if(ele.idc === 3) {
                                  ele.est = this.fixTextAlt
                                  ele.cont = this.fixText
                                  ele.sta = 200
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                  if(originalIE) {
                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(this.rowError.idsite).update({
                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })

                                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                                        totalErrors: siteProject.data().totalErrors - 1
                                      })
                                    }
                                  }
                                } else {
                                  ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                  ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                }
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        } else {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE)

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }
                                this.rowError.idc === 13 || this.rowError.idc === 15 || this.rowError.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                this.rowError.idc === 3 ? ele.sta = 200 : this.rowError.idc === 8 || this.rowError.idc === 14 ? ele.est = 200 : null
                                ele.history && ele.history.length >= 1 ? ele.history.push({id: this.rowError.idError, idc: this.rowError.idc}) : ele.history = [{id: this.rowError.idError, idc: this.rowError.idc}]
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        }

                        // update error counter category
                        if(this.rowError.idc === 1) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 2) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 4) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                            if(this.counterListError <= 1) {
                              db.collection('Sites').doc(this.rowError.idsite).update({
                                'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                              })

                              db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                                totalErrors: siteProject.data().totalErrors - 1
                              })
                            }
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 5) {
                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                          //   })
                          // }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 8) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 6) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 13) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 3) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 9) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 14) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 11) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 10) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 15) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                          //   })
                          // }
                        }
                        
                        // update error counters by page
                        if(filterCounterPage.length) {
                          filterErr.forEach(ele => {
                            if(ele.counter) {
                              db.collection('Sites').doc(this.rowError.idsite).update({
                                errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                ele.counter = ele.counter - 1
                                db.collection('Sites').doc(this.rowError.idsite).update({
                                  errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                              })
                            }
                          })
                        }

                        if(changesPage.length) {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        } else {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        }

                        setTimeout(() => {
                          this.$emit('update:changeerror', true)
                          var arrayChan = []
                          this.arraychanges.forEach(ele => {
                            arrayChan.push(ele)
                          })
                          arrayChan.push(this.rowError.idError)
                          this.$emit('update:arraychanges', arrayChan)
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Change applied`,
                              icon: 'BellIcon',
                              variant: 'success',
                            },
                          })
                          this.$refs.sidebar_fix_error.hide()
                          this.sendJSONFix(idDocPageFixCacheColl)
                          this.processSave = false
                          this.fixText = ''
                          this.fixTextAlt = ''
                          this.oldText = ''
                        }, 1700)
                      } else {
                        // update asignated pro
                        db.collection('Sites').doc(this.rowError.idsite).update({
                          asignatedProPages: true,
                          pageSizes: filterSizePageExclude
                        })

                        // update error status and cont/est
                        var filterErr = []
                        if(this.rowError.idc === 8) {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE || (eleErr.cont === this.oldText && eleErr.idc === 3 && eleErr.urlS === this.rowError.domain))

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                var originalIE = ele.iE
                                if(ele.idc === 8 || ele.idc === 14 || ele.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }

                                if(ele.idc === 3) {
                                  ele.est = this.fixTextAlt
                                  ele.cont = this.fixText
                                  ele.sta = 200
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]

                                  if(originalIE) {
                                    if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                                      db.collection('Sites').doc(this.rowError.idsite).update({
                                        'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                                      })

                                      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                                        totalErrors: siteProject.data().totalErrors - 1
                                      })
                                    }
                                  }
                                } else {
                                  ele.idc === 13 || ele.idc === 15 || ele.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                  ele.idc === 3 ? ele.sta = 200 : ele.idc === 8 || ele.idc === 14 ? ele.est = 200 : null
                                  ele.history && ele.history.length >= 1 ? ele.history.push({id: ele.id, idc: ele.idc}) : ele.history = [{id: ele.id, idc: ele.idc}]
                                }
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        } else {
                          filterErr = listErrors.filter(eleErr => eleErr.id === this.rowError.idE)

                          if(filterErr.length) {
                            filterErr.forEach(ele => {
                              // console.log(ele)
                              dbErrors.collection('Errors').doc(idDocError).update({
                                errors: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                                  ele.iE = false
                                } else {
                                  this.counterListError >= 2 ? null : ele.iE = false
                                }
                                this.rowError.idc === 13 || this.rowError.idc === 15 || this.rowError.idc === 3 ? ele.est = this.fixText : ele.cont = this.fixText
                                this.rowError.idc === 3 ? ele.sta = 200 : this.rowError.idc === 8 || this.rowError.idc === 14 ? ele.est = 200 : null
                                ele.history && ele.history.length >= 1 ? ele.history.push({id: this.rowError.idError, idc: this.rowError.idc}) : ele.history = [{id: this.rowError.idError, idc: this.rowError.idc}]
                                dbErrors.collection('Errors').doc(idDocError).update({
                                  errors: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                                .catch(e => console.log('Error add err: ', e.message))
                              })
                              .catch(e => console.log('Error delete err: ', e.message))
                            })
                          }
                        }

                        // update error counter category
                        if(this.rowError.idc === 1) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.title) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.title': siteDoc.data().errorsCategory.title - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.titleCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.titleCache': siteDoc.data().errorsCategory.titleCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 2) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.desc) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.desc': siteDoc.data().errorsCategory.desc - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.descCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.descCache': siteDoc.data().errorsCategory.descCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 4) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1) {
                            if(this.counterListError <= 1) {
                              db.collection('Sites').doc(this.rowError.idsite).update({
                                'errorsCategory.h1': siteDoc.data().errorsCategory.h1 - 1
                              })

                              db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                                totalErrors: siteProject.data().totalErrors - 1
                              })
                            }
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h1Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h1Cache': siteDoc.data().errorsCategory.h1Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 5) {
                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h2': siteDoc.data().errorsCategory.h2 - 1
                          //   })
                          // }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.h2Cache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.h2Cache': siteDoc.data().errorsCategory.h2Cache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 8) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.Imgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.Imgs': siteDoc.data().errorsCategory.Imgs - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.ImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.ImgsCache': siteDoc.data().errorsCategory.ImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 6) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCount) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.wCount': siteDoc.data().errorsCategory.wCount - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.wCountCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.wCountCache': siteDoc.data().errorsCategory.wCountCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 13) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.strings) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.strings': siteDoc.data().errorsCategory.strings - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.stringsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.stringsCache': siteDoc.data().errorsCategory.stringsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 3) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.alts) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.alts': siteDoc.data().errorsCategory.alts - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.altsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.altsCache': siteDoc.data().errorsCategory.altsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 9) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLink) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.dLink': siteDoc.data().errorsCategory.dLink - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.dLinkCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.dLinkCache': siteDoc.data().errorsCategory.dLinkCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 14) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgs) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.missImgs': siteDoc.data().errorsCategory.missImgs - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.missImgsCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.missImgsCache': siteDoc.data().errorsCategory.missImgsCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 11) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCan) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.urlCan': siteDoc.data().errorsCategory.urlCan - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.urlCanCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.urlCanCache': siteDoc.data().errorsCategory.urlCanCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 10) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlock) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.gPadlock': siteDoc.data().errorsCategory.gPadlock - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.gPadlockCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.gPadlockCache': siteDoc.data().errorsCategory.gPadlockCache - 1
                          //   })
                          // }
                        } else if(this.rowError.idc === 15) {
                          if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.susp) {
                            db.collection('Sites').doc(this.rowError.idsite).update({
                              'errorsCategory.susp': siteDoc.data().errorsCategory.susp - 1
                            })

                            db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                              totalErrors: siteProject.data().totalErrors - 1
                            })
                          }

                          // if(siteDoc.data().errorsCategory && siteDoc.data().errorsCategory.suspCache) {
                          //   db.collection('Sites').doc(this.rowError.idsite).update({
                          //     'errorsCategory.suspCache': siteDoc.data().errorsCategory.suspCache - 1
                          //   })
                          // }
                        }
                        
                        // update error counters by page
                        if(filterCounterPage.length) {
                          filterErr.forEach(ele => {
                            if(ele.counter) {
                              db.collection('Sites').doc(this.rowError.idsite).update({
                                errorsPages: firebase.firestore.FieldValue.arrayRemove(ele)
                              })
                              .then(() => {
                                ele.counter = ele.counter - 1
                                db.collection('Sites').doc(this.rowError.idsite).update({
                                  errorsPages: firebase.firestore.FieldValue.arrayUnion(ele)
                                })
                              })
                            }
                          })
                        }

                        if(changesPage.length) {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        } else {
                          // update asignated sizes
                          db.collection('Sites').doc(this.rowError.idsite).update({
                            pageSizes: filterSizePageExclude
                          })

                          // update Errors page
                          if(idDocError) {
                            if(changesDocError.length) {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true
                              })
                            } else {
                              dbErrors.collection('Errors').doc(idDocError).update({
                                changes: [{
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  date: Date.now(),
                                  user: authUser,
                                  idfix: 1
                                }],
                                has_fix: true
                              })
                            }
                          }
                        }

                        setTimeout(() => {
                          this.$emit('update:changeerror', true)
                          var arrayChan = []
                          this.arraychanges.forEach(ele => {
                            arrayChan.push(ele)
                          })
                          arrayChan.push(this.rowError.idError)
                          this.$emit('update:arraychanges', arrayChan)
                          this.$toast({
                            component: ToastificationContent,
                            props: {
                              title: `Change applied`,
                              icon: 'BellIcon',
                              variant: 'success',
                            },
                          })
                          this.$refs.sidebar_fix_error.hide()
                          this.sendJSONFix(idDocPageFixCacheColl)
                          this.processSave = false
                          this.fixText = ''
                          this.fixTextAlt = ''
                          this.oldText = ''
                        }, 1700)
                      }

                      if(this.rowError.idc === 8 || this.rowError.idc === 14 || this.rowError.idc === 3) {
                        // console.log('3')
                        dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: this.oldText,
                            fix: this.fixText,
                            alt: this.fixTextAlt,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                            date: Date.now(),
                            user: authUser.uid,
                            idfix: 1
                          }],
                          has_fix: true,
                          updated: Date.now()
                        })

                        if(changesFixesCache.length >= 1) {
                          if(this.rowError.idc === 3) {
                            var filterchangesFixesCache8 = changesFixesCache.filter(ele => ele.urlImg === this.oldText && ele.idError === 8 && ele.url === this.rowError.domain)
                            if(filterchangesFixesCache8.length === 0) {
                            } else {
                              var filterNochangesFixesCache = []
                              var idChangeCache = ''
                              changesFixesCache.forEach(ele => {
                                if(ele.urlImg === this.oldText && ele.idError === 8 && ele.url === this.rowError.domain) {
                                  idChangeCache = ele.id
                                } else {
                                  filterNochangesFixesCache.push(ele)
                                }
                              })
                              filterNochangesFixesCache.push({
                                id: idChangeCache,
                                idError: 8,
                                typeError: 'Images',
                                old: this.oldText,
                                fix: this.fixText,
                                alt: this.fixTextAlt,
                                url: this.rowError.domain ? this.rowError.domain : '',
                                urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                                date: Date.now(),
                                // user: authUser,
                                idfix: counterErrorFixes
                              })

                              dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: filterNochangesFixesCache[0].id,
                                  idError: 8,
                                  typeError: 'Images',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  alt: this.fixTextAlt,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                                  date: Date.now(),
                                  user: authUser.uid,
                                  idfix: counterErrorFixes
                                }),
                                has_fix: true,
                                updated: Date.now()
                              })

                              dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                changes: filterNochangesFixesCache,
                                has_fix: true,
                                updated: Date.now()
                              })
                            }

                            var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain)
                            if(filterchangesFixesCache.length === 0) {
                              dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  alt: this.fixTextAlt,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                                  date: Date.now(),
                                  // user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true,
                                updated: Date.now()
                              })
                            } else {
                              // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== this.rowError.idError && ele.idError !== this.rowError.idc && ele.url !== this.rowError.domain)
                              var filterNochangesFixesCache = []
                              changesFixesCache.forEach(ele => {
                                // console.log(ele)
                                if(ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain) {
                                  // filterNochangesFixesCache.push(ele)
                                  // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                                } else {
                                  // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                                  filterNochangesFixesCache.push(ele)
                                }
                              })
                              // console.log(filterNochangesFixesCache, changesFixesCache)
                              filterNochangesFixesCache.push({
                                id: this.rowError.idError,
                                idError: this.rowError.idc,
                                typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                old: this.oldText,
                                fix: this.fixText,
                                alt: this.fixTextAlt,
                                url: this.rowError.domain ? this.rowError.domain : '',
                                urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                                date: Date.now(),
                                // user: authUser,
                                idfix: 1
                              })

                              dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                changes: filterNochangesFixesCache,
                                has_fix: true,
                                updated: Date.now()
                              })
                            }
                          } else {
                            var filterchangesFixesCache3 = changesFixesCache.filter(ele => ele.urlImg === this.oldText && ele.idError === 3 && ele.url === this.rowError.domain)
                            if(filterchangesFixesCache3.length === 0) {
                            } else {
                              var filterNochangesFixesCache = []
                              var idChangeCache = ''
                              changesFixesCache.forEach(ele => {
                                if(ele.urlImg === this.oldText && ele.idError === 3 && ele.url === this.rowError.domain) {
                                  idChangeCache = ele.id
                                } else {
                                  filterNochangesFixesCache.push(ele)
                                }
                              })
                              filterNochangesFixesCache.push({
                                id: idChangeCache,
                                idError: 3,
                                typeError: 'Missing alts',
                                old: this.oldText,
                                fix: this.fixText,
                                alt: this.fixTextAlt,
                                url: this.rowError.domain ? this.rowError.domain : '',
                                urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                                date: Date.now(),
                                // user: authUser,
                                idfix: counterErrorFixes
                              })

                              dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: filterNochangesFixesCache[0].id,
                                  idError: 3,
                                  typeError: 'Missing alts',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  alt: this.fixTextAlt,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                                  date: Date.now(),
                                  user: authUser.uid,
                                  idfix: counterErrorFixes
                                }),
                                has_fix: true,
                                updated: Date.now()
                              })

                              dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                changes: filterNochangesFixesCache,
                                has_fix: true,
                                updated: Date.now()
                              })
                            }

                            var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain)
                            if(filterchangesFixesCache.length === 0) {
                              dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                changes: firebase.firestore.FieldValue.arrayUnion({
                                  id: this.rowError.idError,
                                  idError: this.rowError.idc,
                                  typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                  old: this.oldText,
                                  fix: this.fixText,
                                  alt: this.fixTextAlt,
                                  url: this.rowError.domain ? this.rowError.domain : '',
                                  urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                                  date: Date.now(),
                                  // user: authUser,
                                  idfix: 1
                                }),
                                has_fix: true,
                                updated: Date.now()
                              })
                            } else {
                              // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== this.rowError.idError && ele.idError !== this.rowError.idc && ele.url !== this.rowError.domain)
                              var filterNochangesFixesCache = []
                              changesFixesCache.forEach(ele => {
                                // console.log(ele)
                                if(ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain) {
                                  // filterNochangesFixesCache.push(ele)
                                  // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                                } else {
                                  // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                                  filterNochangesFixesCache.push(ele)
                                }
                              })
                              // console.log(filterNochangesFixesCache, changesFixesCache)
                              filterNochangesFixesCache.push({
                                id: this.rowError.idError,
                                idError: this.rowError.idc,
                                typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                old: this.oldText,
                                fix: this.fixText,
                                alt: this.fixTextAlt,
                                url: this.rowError.domain ? this.rowError.domain : '',
                                urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                                date: Date.now(),
                                // user: authUser,
                                idfix: 1
                              })

                              dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                                changes: filterNochangesFixesCache,
                                has_fix: true,
                                updated: Date.now()
                              })
                            }
                          }
                        } else {
                          dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                            changes: [{
                              id: this.rowError.idError,
                              idError: this.rowError.idc,
                              typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                              old: this.oldText,
                              fix: this.fixText,
                              alt: this.fixTextAlt,
                              url: this.rowError.domain ? this.rowError.domain : '',
                              urlImg: this.rowError.idc === 3 ? this.rowError.img : this.oldText,
                              date: Date.now(),
                              // user: authUser,
                              idfix: 1
                            }],
                            has_fix: true,
                            updated: Date.now()
                          })
                        }
                      } else {
                        dbErrors.collection('fixes').doc(idDocPageFixColl).update({
                          changes: [{
                            id: this.rowError.idError,
                            idError: this.rowError.idc,
                            typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                            old: this.oldText,
                            fix: this.fixText,
                            url: this.rowError.domain ? this.rowError.domain : '',
                            date: Date.now(),
                            user: authUser.uid,
                            idfix: 1
                          }],
                          has_fix: true,
                          updated: Date.now()
                        })

                        if(changesFixesCache.length >= 1) {
                          var filterchangesFixesCache = changesFixesCache.filter(ele => ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain)
                          // console.log(filterchangesFixesCache)
                          if(filterchangesFixesCache.length === 0) {
                            dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                              changes: firebase.firestore.FieldValue.arrayUnion({
                                id: this.rowError.idError,
                                idError: this.rowError.idc,
                                typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                                old: this.oldText,
                                fix: this.fixText,
                                url: this.rowError.domain ? this.rowError.domain : '',
                                date: Date.now(),
                                // user: authUser,
                                idfix: 1
                              }),
                              has_fix: true,
                              updated: Date.now()
                            })
                          } else {
                            // var filterNochangesFixesCache = changesFixesCache.filter(ele => ele.id !== this.rowError.idError && ele.idError !== this.rowError.idc && ele.url !== this.rowError.domain)
                            var filterNochangesFixesCache = []
                            changesFixesCache.forEach(ele => {
                              // console.log(ele)
                              if(ele.id === this.rowError.idError && ele.idError === this.rowError.idc && ele.url === this.rowError.domain) {
                                // filterNochangesFixesCache.push(ele)
                                // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                              } else {
                                // console.log(ele.id, this.rowError.idError, ele.idError, this.rowError.idc, ele.url, this.rowError.domain)
                                filterNochangesFixesCache.push(ele)
                              }
                            })
                            // console.log(filterNochangesFixesCache, changesFixesCache)
                            filterNochangesFixesCache.push({
                              id: this.rowError.idError,
                              idError: this.rowError.idc,
                              typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                              old: this.oldText,
                              fix: this.fixText,
                              url: this.rowError.domain ? this.rowError.domain : '',
                              date: Date.now(),
                              // user: authUser,
                              idfix: 1
                            })

                            dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                              changes: filterNochangesFixesCache,
                              has_fix: true,
                              updated: Date.now()
                            })
                          }
                        } else {
                          dbErrors.collection('fixes_cache').doc(idDocPageFixCacheColl).update({
                            changes: [{
                              id: this.rowError.idError,
                              idError: this.rowError.idc,
                              typeError: this.rowError.idc === 1 ? 'Missing titles' : this.rowError.idc === 2 ? 'Missing descriptions' : this.rowError.idc === 11 ? 'URL canonalization' : this.rowError.idc === 3 ? 'Missing alts' : this.rowError.idc === 4 ? 'Headings H1' : this.rowError.idc === 5 ? 'Headings H2' : this.rowError.idc === 6 ? 'Low word count' : this.rowError.idc === 8 ? 'Images' : this.rowError.idc === 14 ? 'Images' : this.rowError.idc === 9 ? 'Dead link checker' : this.rowError.idc === 10 ? 'Canonicals' : this.rowError.idc === 13 ? 'Strings' : this.rowError.idc === 15 ? 'Suspicious' : this.rowError.idc === 7 ? 'Externals' : '',
                              old: this.oldText,
                              fix: this.fixText,
                              url: this.rowError.domain ? this.rowError.domain : '',
                              date: Date.now(),
                              // user: authUser,
                              idfix: 1
                            }],
                            has_fix: true,
                            updated: Date.now()
                          })
                        }
                      }
                    })
                  })
              }
            }
          })
          })
        })
        })
      })
    },
    saveChangeExternal() {
      this.processSave = true
      // var user = auth.currentUser
      // save changes
      // db.collection('Users').doc(user.uid).get()
      // .then(userDoc => {
      //   var authUser = {
      //     nombre: userDoc.data().nombre ? userDoc.data().nombre : '',
      //     email: userDoc.data().email,
      //     uid: userDoc.data().uid,
      //     foto: userDoc.data().foto
      //   }
      var makeid = (length) => {
        var result = ''
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        var charactersLength = characters.length
        for(var i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        return result
      }

      db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).get()
      .then(siteProject => {  
      db.collection('Sites').doc(this.$route.params.id).get()
        .then(eleSite => {
          var URLactual = new URL(this.rowError.source)
          var fixUrlIsInternal = this.fixText.includes(URLactual.hostname)

          if(fixUrlIsInternal) {
            var externalSite = eleSite.data().externalLinks && eleSite.data().externalLinks.length >= 1 ? eleSite.data().externalLinks : []
            var filtExternalSite = externalSite.filter(item => item.url === this.rowError.url && item.source === this.rowError.source)
            var filterExternalSite = []
            externalSite.forEach(ele => {
              if(filtExternalSite.filter(item => item.url === ele.url && item.source === ele.source).length === 0) {
                filterExternalSite.push(ele)
              }
            })
            // var filterWExternalSite = externalSite.filter(item => item.url === this.rowError.url)
            // filterWExternalSite.forEach(item => {
            //   item.url = this.fixText
            //   item.status = (this.rowError.status === 400 || this.rowError.status === 401 || this.rowError.status === 402 || this.rowError.status === 403 || this.rowError.status === 404 || this.rowError.status === 405 || this.rowError.status === 500 || this.rowError.status === 501 || this.rowError.status === 502 || this.rowError.status === 503 || this.rowError.status === 504 || this.rowError.status === 505 || this.rowError.status === 406 || this.rowError.status === 407 || this.rowError.status === 408 || this.rowError.status === 409 || this.rowError.status === 506 || this.rowError.status === 507 || this.rowError.status === 508 || this.rowError.status === 509) ? 200 : this.rowError.status
            //   item.redirectTo = this.rowError.status === 301 || this.rowError.status === 302 ? '' : this.rowError.redirectTo
            //   filterExternalSite.push(item)
            // })

            db.collection('Sites').doc(this.$route.params.id).update({
              externalLinks: filterExternalSite
            })

            // this.rowError.pagesFound.forEach(page => {
              db.collection('Sites').doc(this.$route.params.id).collection('pages').where('url', '==', this.rowError.source).get()
              .then(docsExternals => {
                var iddocsExternals = ''
                docsExternals.forEach(ele => {
                  iddocsExternals = ele.id
                  var listExternals = ele.data().externalLinks && ele.data().externalLinks.length ? ele.data().externalLinks : []
                  var filtExternalSiteNotFound = externalSite.filter(item => item.url === this.rowError.url && item.source === this.rowError.source)
                  var filterExternalSiteNotFound = []
                  listExternals.forEach(element => {
                    if(filtExternalSiteNotFound.filter(item => item.url === element.url && item.source === element.source).length === 0) {
                      filterExternalSiteNotFound.push(element)
                    }
                  })
                  // var filterWExternalSiteNotFound = listExternals.filter(item => item.url === this.rowError.url)
                  // filterWExternalSiteNotFound.forEach(item => {
                  //   item.url = this.fixText
                  //   item.status = (this.rowError.status === 400 || this.rowError.status === 401 || this.rowError.status === 402 || this.rowError.status === 403 || this.rowError.status === 404 || this.rowError.status === 405 || this.rowError.status === 500 || this.rowError.status === 501 || this.rowError.status === 502 || this.rowError.status === 503 || this.rowError.status === 504 || this.rowError.status === 505 || this.rowError.status === 406 || this.rowError.status === 407 || this.rowError.status === 408 || this.rowError.status === 409 || this.rowError.status === 506 || this.rowError.status === 507 || this.rowError.status === 508 || this.rowError.status === 509) ? 200 : this.rowError.status
                  //   item.redirectTo = this.rowError.status === 301 || this.rowError.status === 302 ? '' : this.rowError.redirectTo
                  //   filterExternalSiteNotFound.push(item)
                  // })

                  db.collection('Sites').doc(this.$route.params.id).collection('pages').doc(ele.id).update({
                    externalLinks: filterExternalSiteNotFound
                  })
                  .catch((e) => console.log('error 1.1', e.message))
                })

                if(this.rowError.status === 400 || this.rowError.status === 401 || this.rowError.status === 402 || this.rowError.status === 403 || this.rowError.status === 404 || this.rowError.status === 405 || this.rowError.status === 500 || this.rowError.status === 501 || this.rowError.status === 502 || this.rowError.status === 503 || this.rowError.status === 504 || this.rowError.status === 505 || this.rowError.status === 406 || this.rowError.status === 407 || this.rowError.status === 408 || this.rowError.status === 409 || this.rowError.status === 506 || this.rowError.status === 507 || this.rowError.status === 508 || this.rowError.status === 509) {
                  var errorDl = eleSite.data().errors && eleSite.data().errors.length >= 1 ? eleSite.data().errors : []
                  var filterWErrorDead = errorDl.filter(lit => lit.idc === 9 && lit.cont === this.rowError.url && lit.urlS === this.rowError.source)
                  var arrayNewDl = []
                  // console.log(this.rowError.url)
                  errorDl.forEach(element => {
                    if(filterWErrorDead.filter(lit => lit.id === element.id).length === 0) {
                      arrayNewDl.push(element)
                    }
                  })

                  db.collection('Sites').doc(this.$route.params.id).collection('pages').doc(iddocsExternals).update({
                    errors: arrayNewDl,
                    hasDLinks: arrayNewDl.filter(lit => lit.idc === 9).length >= 1 ? true : false,
                  })
                  .then(() => console.log('Update dl'))
                  .catch((e) => console.log('error 1.2', e.message))

                  var docsObjErrors = eleSite.data().errorsTypePage && eleSite.data().errorsTypePage.length >= 1 ? eleSite.data().errorsTypePage : []
                  var filterObjErrorsFilter = docsObjErrors.filter(errObj => errObj.url === this.rowError.source)
                  var filterObjNoSiteErrorsFilter = docsObjErrors.filter(errObj => errObj.url !== this.rowError.source)
                  filterObjErrorsFilter.forEach(elePage => {
                    if(elePage.errors && elePage.errors.length) {
                      elePage.errors.forEach(eleCounter => {
                        if(eleCounter.type === 'dLink') {
                          eleCounter.counter -= 1
                        }
                      })
                    }
                  })

                  var counterFixErr = 0
                  filterObjErrorsFilter.forEach(eleSiteTypeError => {
                    if(counterFixErr === 0) {
                      counterFixErr += 1
                      filterObjNoSiteErrorsFilter.push(eleSiteTypeError)
                    }
                  })

                  db.collection('Sites').doc(this.$route.params.id).update({
                    'errorsCategory.dLink': eleSite.data().errorsCategory.dLink - 1,
                    'errorsCategory.dLinkCache': eleSite.data().errorsCategory.dLink - 1,
                    errorsTypePage: filterObjNoSiteErrorsFilter
                  })
                  .then(() => console.log('Upd site ext dl'))

                  db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                    totalErrors: siteProject.data().totalErrors - 1
                  })
                }
              })
            // })
            // console.log(filterExternalSite.length)
            setTimeout(() => {
              this.$emit('update:changeerror', true)
              // var arrayChan = []
              // this.arraychanges.forEach(ele => {
              //   arrayChan.push(ele)
              // })
              // arrayChan.push(this.rowError.idError)
              // this.$emit('update:arraychanges', arrayChan)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Change applied`,
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$refs.sidebar_fix_error.hide()
              this.sendJSONFix()
              this.processSave = false
              this.fixText = ''
              this.fixTextAlt = ''
              this.oldText = ''
            }, 2000)
          } else {
            var externalSite = eleSite.data().externalLinks && eleSite.data().externalLinks.length >= 1 ? eleSite.data().externalLinks : []
            var filtExternalSite = externalSite.filter(item => item.url === this.rowError.url && item.source === this.rowError.source)
            var filterExternalSite = []
            externalSite.forEach(ele => {
              if(filtExternalSite.filter(item => item.url === ele.url && item.source === ele.source).length === 0) {
                filterExternalSite.push(ele)
              }
            })

            var filterWExternalSite = externalSite.filter(item => item.url === this.fixText)
            if(filterWExternalSite.length === 0) {
              filterExternalSite.push({st: null, url: this.fixText, source: this.rowError.source, redirectTo: null})
            } else {
              var redirectTo = null
              var status = null
              filterWExternalSite.forEach(ele => {
                if(ele.st && !status) {
                  status = ele.st
                }

                if(ele.redirectTo && !redirectTo) {
                  redirectTo = ele.redirectTo
                }
              })
              filterExternalSite.push({st: status, url: this.fixText, source: this.rowError.source, redirectTo: redirectTo})
            }

            db.collection('Sites').doc(this.$route.params.id).update({
              externalLinks: filterExternalSite
            })

            // this.rowError.pagesFound.forEach(page => {
              db.collection('Sites').doc(this.$route.params.id).collection('pages').where('url', '==', this.rowError.source).get()
              .then(docsExternals => {
                var iddocsExternals = ''
                var statusExternal = null
                docsExternals.forEach(ele => {
                  iddocsExternals = ele.id
                  var listExternals = ele.data().externalLinks && ele.data().externalLinks.length ? ele.data().externalLinks : []
                  var filtExternalSiteNotFound = listExternals.filter(item => item.url === this.rowError.url && item.source === this.rowError.source)
                  var filterExternalSiteNotFound = []
                  listExternals.forEach(element => {
                    if(filtExternalSiteNotFound.filter(item => item.url === element.url && item.source === element.source).length === 0) {
                      filterExternalSiteNotFound.push(element)
                    }
                  })

                  // var filterWExternalSiteNotFound = listExternals.filter(item => item.url === this.fixText)
                  var filterWExternalSiteNotFound = externalSite.filter(item => item.url === this.fixText)
                  if(filterWExternalSiteNotFound.length === 0) {
                    filterExternalSiteNotFound.push({st: null, url: this.fixText, source: this.rowError.source, redirectTo: null})
                  } else {
                    var redirectTo = null
                    var status = null
                    filterWExternalSiteNotFound.forEach(elem => {
                      if(elem.st && !status) {
                        status = elem.st
                      }

                      if(elem.redirectTo && !redirectTo) {
                        redirectTo = elem.redirectTo
                      }
                    })
                    statusExternal = status
                    filterExternalSiteNotFound.push({st: status, url: this.fixText, source: this.rowError.source, redirectTo: redirectTo})
                  }

                  // console.log(filterExternalSiteNotFound)
                  db.collection('Sites').doc(this.$route.params.id).collection('pages').doc(ele.id).update({
                    externalLinks: filterExternalSiteNotFound
                  })
                  .catch((e) => console.log('error 1', e.message))
                })

                if(this.rowError.status === 400 || this.rowError.status === 401 || this.rowError.status === 402 || this.rowError.status === 403 || this.rowError.status === 404 || this.rowError.status === 405 || this.rowError.status === 500 || this.rowError.status === 501 || this.rowError.status === 502 || this.rowError.status === 503 || this.rowError.status === 504 || this.rowError.status === 505 || this.rowError.status === 406 || this.rowError.status === 407 || this.rowError.status === 408 || this.rowError.status === 409 || this.rowError.status === 506 || this.rowError.status === 507 || this.rowError.status === 508 || this.rowError.status === 509) {
                  var errorDl = eleSite.data().errors && eleSite.data().errors.length >= 1 ? eleSite.data().errors : []
                  var filterWErrorDead = errorDl.filter(lit => lit.idc === 9 && lit.cont === this.rowError.url && lit.urlS === this.rowError.source)
                  var arrayNewDl = []
                  // console.log(this.rowError.url)
                  errorDl.forEach(element => {
                    if(filterWErrorDead.filter(lit => lit.id === element.id).length === 0) {
                      arrayNewDl.push(element)
                    }
                  })

                  db.collection('Sites').doc(this.$route.params.id).collection('pages').doc(iddocsExternals).update({
                    errors: arrayNewDl,
                    hasDLinks: arrayNewDl.filter(lit => lit.idc === 9).length >= 1 ? true : false,
                  })
                  .then(() => console.log('Update dl'))
                  .catch((e) => console.log('error 2', e.message))

                  var docsObjErrors = eleSite.data().errorsTypePage && eleSite.data().errorsTypePage.length >= 1 ? eleSite.data().errorsTypePage : []
                  var filterObjErrorsFilter = docsObjErrors.filter(errObj => errObj.url === this.rowError.source)
                  var filterObjNoSiteErrorsFilter = docsObjErrors.filter(errObj => errObj.url !== this.rowError.source)
                  filterObjErrorsFilter.forEach(elePage => {
                    if(elePage.errors && elePage.errors.length) {
                      elePage.errors.forEach(eleCounter => {
                        if(eleCounter.type === 'dLink') {
                          eleCounter.counter -= 1
                        }
                      })
                    }
                  })

                  var counterFixErr = 0
                  filterObjErrorsFilter.forEach(eleSiteTypeError => {
                    if(counterFixErr === 0) {
                      counterFixErr += 1
                      filterObjNoSiteErrorsFilter.push(eleSiteTypeError)
                    }
                  })

                  db.collection('Sites').doc(this.$route.params.id).update({
                    'errorsCategory.dLink': eleSite.data().errorsCategory.dLink - 1,
                    'errorsCategory.dLinkCache': eleSite.data().errorsCategory.dLink - 1,
                    errorsTypePage: filterObjNoSiteErrorsFilter
                  })
                  .then(() => console.log('Upd site ext dl'))

                  db.collection('Projects').doc(this.$route.params.project).collection('sites').doc(this.rowError.idsite).update({
                    totalErrors: siteProject.data().totalErrors - 1
                  })
                }

                if(statusExternal === 400 || statusExternal === 401 || statusExternal === 402 || statusExternal === 403 || statusExternal === 404 || statusExternal === 405 || statusExternal === 500 || statusExternal === 501 || statusExternal === 502 || statusExternal === 503 || statusExternal === 504 || statusExternal === 505 || statusExternal === 406 || statusExternal === 407 || statusExternal === 408 || statusExternal === 409 || statusExternal === 506 || statusExternal === 507 || statusExternal === 508 || statusExternal === 509) {
                  var errorDl = eleSite.data().errors && eleSite.data().errors.length >= 1 ? eleSite.data().errors : []
                  var filterWErrorDead = errorDl.filter(lit => lit.idc === 9 && lit.cont === this.rowError.url && lit.urlS === this.rowError.source)
                  var arrayNewDl = [{
                    'id': makeid(25),
                    'tipo': 'Dead link checker',
                    'est': statusExternal,
                    'iE': true,
                    'cont': this.fixText,
                    'idc': 9,
                    'ids': this.$route.params.id,
                    src: 'external',
                    urlS: this.rowError.source,
                    history: []
                  }]
                  // console.log(this.rowError.url)
                  errorDl.forEach(element => {
                  //   if(filterWErrorDead.filter(lit => lit.id === element.id).length === 0) {
                      arrayNewDl.push(element)
                  //   }
                  })

                  db.collection('Sites').doc(this.$route.params.id).collection('pages').doc(iddocsExternals).update({
                    errors: arrayNewDl,
                    hasDLinks: true,
                  })
                  .then(() => console.log('Update dl'))
                  .catch((e) => console.log('error 3', e.message))

                  var docsObjErrors = eleSite.data().errorsTypePage && eleSite.data().errorsTypePage.length >= 1 ? eleSite.data().errorsTypePage : []
                  var filterObjErrorsFilter = docsObjErrors.filter(errObj => errObj.url === this.rowError.source)
                  var filterObjNoSiteErrorsFilter = docsObjErrors.filter(errObj => errObj.url !== this.rowError.source)
                  filterObjErrorsFilter.forEach(elePage => {
                    if(elePage.errors && elePage.errors.length) {
                      elePage.errors.forEach(eleCounter => {
                        if(eleCounter.type === 'dLink') {
                          eleCounter.counter += 1
                        }
                      })
                    }
                  })

                  var counterFixErr = 0
                  filterObjErrorsFilter.forEach(eleSiteTypeError => {
                    if(counterFixErr === 0) {
                      counterFixErr += 1
                      filterObjNoSiteErrorsFilter.push(eleSiteTypeError)
                    }
                  })

                  db.collection('Sites').doc(this.$route.params.id).update({
                    'errorsCategory.dLink': firebase.firestore.FieldValue.increment(1),
                    'errorsCategory.dLinkCache': firebase.firestore.FieldValue.increment(1),
                    errorsTypePage: filterObjNoSiteErrorsFilter
                  })
                  .then(() => console.log('Upd site ext dl'))
                  .catch(() => console.log('error 4'))
                }
              })
            // })

            setTimeout(() => {
              this.$emit('update:changeerror', true)
              // var arrayChan = []
              // this.arraychanges.forEach(ele => {
              //   arrayChan.push(ele)
              // })
              // arrayChan.push(this.rowError.idError)
              // this.$emit('update:arraychanges', arrayChan)
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: `Change applied`,
                  icon: 'BellIcon',
                  variant: 'success',
                },
              })
              this.$refs.sidebar_fix_error.hide()
              this.sendJSONFix()
              this.processSave = false
              this.fixText = ''
              this.fixTextAlt = ''
              this.oldText = ''
            }, 2000)
          }
        })
      })
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if(success) {
          // console.log('success')
          if(this.rowError.idc === 7) {
            this.saveChangeExternal()
          } else {
            this.saveChange()
          }
        }
      })
    },
    validationFormImg(payload) {
      this.$refs.simpleRulesImg.validate().then(success => {
        if(success) {
          // console.log('success')
          this.loadImgInfo(payload)
        }
      })
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-fix-error {
  width: 90%;
  max-width: 1200px !important;
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>