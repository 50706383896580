<template>
  <div
    class="compScreenshots"
  >
    <b-overlay
      id="overlay-background"
      :show="loadingHistory"
      :variant="'transparent'"
      :opacity="0.85"
      :blur="'2px'"
      rounded="sm"
    >
      <div class="calendar_history_takess_container">
        {{ moreRecentDate }}
        <div
          class="calendar_history_container"
        >
          <Calendar
            class="compCalendar"
            :dates-with-data="daysWithData"
            :more-recent.sync="moreRecentDate"
            @currentDate="updateCurrentDate"
          />
          <History
            v-if="allData && proPagesList"
            class="compHistory"
            :all-data.sync="allData"
            :current-date.sync="currentDate"
            :pro-pages.sync="proPagesList"
            :idsite.sync="idsite"
            @showPreviews="updatePreview"
            @moreRecentMode="backToMoreRecent"
          />
          <div
            v-else
            class="compHistory flex align-items-center"
          >
            <h3 class="text-center w-full">
              Your screenshots are being generated! Check again soon.
            </h3>
          </div>
        </div>
      </div>
      <PreviewScreenshots
        v-if="previewToShow"
        :previews="previewToShow"
      />
    </b-overlay>
  </div>
</template>

<script>
import {
  BOverlay
} from 'bootstrap-vue'
import Calendar from './components/Calendar.vue'
import History from './components/History.vue'
import PreviewScreenshots from './components/PreviewScreenshots.vue'
import screenshotsRepository from './services'

export default {
  name: 'ScreenshotsModule',
  components: {
    BOverlay,
    Calendar,
    History,
    PreviewScreenshots,
  },
  props: ['urlViewSite','urlViewSiteConverter','daysWScreenshots','idsite'],
  data() {
    return {
      selectedScreenshotData: {},
      daysWithData: [],
      currentDate: '',
      previewToShow: {},
      moreRecentDate: null,
      allData: null,
      urlSite: null,
      proPagesList: [],
      listPages: null,
      loadingHistory: false,
      timerInfiniteScreenshot: null
    }
  },
  watch: {
    urlViewSiteConverter(val) {
      if(val) {
        // console.log(val)
        this.loadScreenshotsSite(val)
      }
    }
  },
  created() {
    this.timerInfiniteScreenshot = null
    if(this.urlViewSiteConverter) {
      this.loadScreenshotsSite(this.urlViewSiteConverter)
    }
  },
  mounted() {
    this.timerInfiniteScreenshot = setInterval(() => {
      this.observerChanges()
    }, 1000)
  },
  destroyed() {
    clearInterval(this.timerInfiniteScreenshot)
  },
  methods: {
    observerChanges() {
      var changeScreen = localStorage.getItem('changeScreen')
      if(changeScreen && changeScreen === 'true') {
        this.loadingHistory = true
        localStorage.removeItem('changeScreen')
        this.loadScreenshotsSite(this.urlViewSiteConverter)
      }
    },
    async loadScreenshotsSite(payload) {
      if (!payload) {
        console.error('The param site is required.')
        return
      }

      try {
        // console.log(payload);
        const response = await screenshotsRepository.actions.getScreenshots(payload)
        // console.log(response, payload, this.urlViewSite);
        if(response.pages) {
          // console.log(response.pages);
          // response.pages[response.home] -> this.urlViewSite
          const resProPages = await screenshotsRepository.actions.getPages(this.urlViewSite)
          // console.log(resProPages,response.pages);
          this.proPagesList = resProPages?.pages
          this.allData = response
          this.daysWithData = response.daysWithData
          this.$emit('update:daysWScreenshots', response.daysWithData)
          this.currentDate = response.moreRecent
          this.urlSite = this.urlViewSite
          this.pages = response.pages
          this.moreRecentDate = this.allData?.moreRecent
          setTimeout(() => {
            this.loadingHistory = false
          }, 1500)
        } else {
          // this.registerSite(payload)
          // console.error('Error al obtener capturas de pantalla 500')
        }
      } catch (error) {
        this.registerSite(payload)
        console.error('Error al obtener capturas de pantalla:', error)
      }
    },
    registerSite(payload) {
      this.loadingHistory = true
      var config = {
        "url": "https://sslcheckerapi-h4klmyt43a-uc.a.run.app/screenshots/register",
        "method": "POST",
        maxBodyLength: Infinity,
        timeout: 0,
        "headers": {
          "Content-Type": "application/json"
        },
        "data": JSON.stringify({
          "website": this.urlViewSite
        }),
      };

      this.axios(config)
      .then((response) => {
        this.loadScreenshotsSite(payload)
        console.log('register screenshots: ', response)
      })
      .catch(e => {
        this.loadingHistory = false
        console.error('Error register screenshot: ', e)
      })
    },
    updateCurrentDate(event) {
      this.currentDate = event
    },
    updatePreview(event) {
      this.previewToShow = event
      this.previewToShow.home = this.allData.home
      this.previewToShow.url = this.pages[event.page]
    },
    backToMoreRecent() {
      this.moreRecentDate = this.allData?.moreRecent

      var valTab = this.$route.query.section
      // console.log(valTab, window.location.search);
      if(valTab === 'tools' || (window.location.search && window.location.search.includes('section=tools&content=screen'))) {
        this.loadScreenshotsSite(this.urlViewSiteConverter)
        setTimeout(() => {
          window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth'
          })
        }, 500)
      }
    },
  },
}
</script>

<style lang="scss">
  @import "./components/styles.scss";
</style>