<template>
  <div>
    <b-sidebar
      id="sidebar-right-strings-search"
      ref="sidebar_strings_search"
      :visible="showOpenStringsSearch"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-strings-search', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-2">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>
        <hr v-if="idsite.label">

        <div class="mx-1">
          <div style="height: 100%; text-size: 18px;">
            <validation-observer ref="rulesForm">
              <b-form>
                <b-row>
                  <b-col md="6">
                    <b-form-group
                      :label="type === 'suspicious' ? 'Suspicious text' : 'Text String'"
                      class=""
                      style="font-weight: 700;"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="type === 'suspicious' ? 'Suspicious text' : 'Text String'"
                        rules="required|max: 72"
                      >
                        <b-form-input
                          v-model="textString"
                          :placeholder="type === 'suspicious' ? 'Suspicious text' : 'Text String'"
                          style="font-weight: 700; border-radius: 10px; width: 100%; height: 60px;"
                          class=""
                          :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-button
                  style="background: #7838ff !important; border: 1px solid #7838ff !important; width: 158px; height: 46px; border-radius: 23px;"
                  class="mt-2"
                  variant="primary"
                  :disabled="process ? true : false"
                  @click="validationForm"
                >
                  <span
                    class="align-middle"
                    style="font-weight: 700; color: #FFFFFF;"
                  >Save</span>
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </div>
        <help-footer :url="idsite.domain" />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
  BFormTags, BInputGroup, BInputGroupAppend
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    HelpFooter,
    BFormTags,
    BInputGroup,
    BInputGroupAppend,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenStringsSearch',
    event: 'update:show-open-strings-search',
  },
  props: ['idsite', 'showOpenStringsSearch', 'strings', 'type'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      selectedMove: '',

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      permissionsOptions: ['clinician viewer', 'manage users', 'data specialist'],
      allProject: [
        {
          label: 'My sites',
          color: '#F0F1F7',
        },
        {
          label: 'Facade interactive sites',
          color: '#F39C19',
        },
        {
          label: "Jane's sites",
          color: '#1CA085',
        },
      ],
      selectedProPage: '',
      optionsProPage: [
        'Home',
        'Blog',
        'Contact',
      ],
      selectedError: 'Missing Title',
      optionsErrors: [
        'Meta title',
        'Missing Title',
        'Missing descriptions',
        'Missing alts',
        'Headings H1',
        'Low word count',
        'External links',
      ],
      fixText: '',

      //   sources
      errSitesImg: require('@/assets/images/pages/robot-thumb.svg'),

      // variables sidebar
      stringLabelTag: '',
      arrayLabelTag: '',
      typeLabelTag: '',
      stringsArray: [],
      textString: '',
      process: false,
    }
  },
  validations: {
  },
  watch: {
    showOpenStringsSearch(val) {
      if(val) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }
    // strings(val) {
    //   // console.log(val)
    //   if(!this.showOpenStringsSearch) {
    //     this.stringsArray = val
    //   }
    // }
  },
  methods: {
    submitEditar() {
        // console.log(this.strings)
        if(this.idsite.domain) {
          var hostUrl = this.idsite.domain

          db.collection('Sites').where('WebsiteURL', '==', hostUrl).limit(1).get()
          .then(itemSite => {
            if(itemSite.size) {
              itemSite.forEach(docS => {
                var str = this.type === 'suspicious' ? (docS.data().suspiciousText ? docS.data().suspiciousText : []) : docS.data().stringSearch ? docS.data().stringSearch : []
                this.stringsArray = str
                this.stringsArray.push(this.textString)
                this.$emit('update:strings', this.stringsArray)

                if(this.type === 'suspicious') {
                  db.collection('Sites').doc(docS.id).update({
                    suspiciousText: this.stringsArray,
                    pageSizes: []
                  })

                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'Suspicious text has been updated',
                      icon: 'BellIcon',
                      variant: 'success',
                    },
                  })

                  // setTimeout(() => {
                  this.textString = ''
                  // }, 1500)
                  this.process = false
                  this.$refs.sidebar_strings_search.hide()
                } else {
                  db.collection('Sites').doc(docS.id).update({
                    stringSearch: this.stringsArray,
                    pageSizes: []
                  })

                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: 'String has been updated',
                      icon: 'BellIcon',
                      variant: 'success',
                    },
                  })

                  // setTimeout(() => {
                  this.textString = ''
                  // }, 1500)
                  this.process = false
                  this.$refs.sidebar_strings_search.hide()
                }
              })
            } else {
              this.process = false
            }
          })
          .catch(() => this.process = false)
        }
        //   this.$refs.sidebar_strings_search.hide()
    },
    editarString(payload) {
        var filterTaxonomy = payload.tags.filter(tag => {
            return tag !== payload.tag;
        })

        this.stringLabelTag = payload.tag
        this.arrayLabelTag = filterTaxonomy
        this.typeLabelTag = payload.type
    },
    eliminarString(payload) {
        if(this.idsite.domain) {
            var hostUrl = this.idsite.domain

            db.collection('Sites').where('WebsiteURL', '==', hostUrl).limit(1).get()
            .then(itemSite => {
                itemSite.forEach(docS => {
                    this.$swal({
                        title: 'Are you sure?',
                        // text: `This selecction has been assigned to ${itemUser.size} records`,
                        icon: 'warning',
                        showCancelButton: true,
                        confirmButtonText: 'Yes, delete it!',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                            cancelButton: 'btn btn-outline-danger ml-1',
                        },
                        buttonsStyling: false,
                    }).then(result => {
                        if(result.value) {
                            var filterTaxonomy = payload.tags.filter(tag => {
                                return tag !== payload.tag;
                            })
                            this.stringsArray = filterTaxonomy
                            this.$emit('update:strings', filterTaxonomy)

                            db.collection('Sites').doc(docS.id).update({
                                stringSearch: this.stringsArray,
                                pageSizes: []
                            })

                            this.$toast({
                                component: ToastificationContent,
                                props: {
                                    title: 'String has been deleted',
                                    icon: 'BellIcon',
                                    variant: 'success',
                                },
                            })
                            // this.$swal({
                            //     icon: 'success',
                            //     title: 'Deleted!',
                            //     text: 'Taxonomy has been deleted.',
                            //     customClass: {
                            //         confirmButton: 'btn btn-success',
                            //     },
                            // })
                        }
                    })
                })
            })
        }
    },
    validationForm() {
      // console.log(this.siteProject)
      this.$refs.rulesForm.validate().then(success => {
        if(success) {
          this.process = true
          this.submitEditar()
        }
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-strings-search {
    width: 90%;
    max-width: 593px !important;
}
// label {
//   margin-bottom: 0px !important;
//   height: 25px;
// }
// .col-form-label {
//   margin-bottom: 0px !important;
//   height: 25px;
//   font-size: 0.857rem !important;
// }
// .form-control {
//   height: 35px;
// }
// .v-select {
//   height: 35px;
//   margin-top: 0px;
// }
// .vs__dropdown-toggle {
//   height: 35px;
// }
// .vs__selected {
//   margin-top: 0px !important;
// }
</style>
