<template>
  <div class="sidebar-copilot">
    <b-sidebar
      id="sidebar-right-copilot"
      ref="sidebar_copilot"
      :visible="showOpenSidebarCopilot"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-sidebar-copilot', val)"
    >
        <template #default="{ }">
          <!-- <div
            class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
            style="background-color: #F0F1F7; height: 91px;"
          >
            <b-card
              no-body
              class="overflow-hidden w-100"
              style="background-color: #F0F1F7;"
            >
              <header-sidebar :idsite-header.sync="idsite" />
            </b-card>
            <feather-icon
              class="cursor-pointer"
              icon="XIcon"
              size="20"
              @click="hide"
            />
          </div> -->

          <div class="pl-0 px-2 pt-75 pb-2" style="background: #f7f7f7; border-bottom: 1px solid #ebe9f1;">
            <p class="card-text mb-0 pt-50 name-site-header-sidebar" style="font-weight: 700; color: #181E38; font-size: 15px;">
              Page:
            </p>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-dark"
              class="btn-icon rounded-circle float-right"
              @click.prevent="!openCopilotTabs ? openCopilotTabs = true : openCopilotTabs = false"
            >
              <feather-icon icon="SettingsIcon" />
            </b-button>
            <p class="card-text mb-0 pt-50 name-site-header-sidebar" style="font-weight: 400; color: #181E38; font-size: 15px;">
              {{ IASiteSelected && IASiteSelected.length ? IASiteSelected[0].title : '' }}
            </p>
            <a :href="IASiteSelected && IASiteSelected.length ? IASiteSelected[0].cUrl : ''" target="_blank">
              <span
                class="url-header-sidebar"
                style="text-decoration: underline; color: #A7AAB9; font-size: 15px;"
              >
                {{ IASiteSelected && IASiteSelected.length ? IASiteSelected[0].cUrl : '' }}
              </span>
            </a>
          </div>

          <div class="my-2 mx-2 mt-25" v-if="openCopilotTabs">
            <b-button
              variant="flat-light"
              style="color: #c1c1c1; display: block; text-align: left; width: 40px;"
              class="btn-icon pl-0 mb-0 pb-25 px-1 mt-1"
              @click.prevent="openCopilotTabs = false"
            >
              <feather-icon
                icon="ArrowLeftIcon"
                size="25"
              />
            </b-button>
            <h3 :class="'mt-2 mb-2 px-1'" style="">Settings</h3>
            <b-tabs class="tabsViewCopilot">
              <b-tab title="Site">
                <div class="px-1 mt-2">
                  <label for="textarea-default" style="font-size: 14px;">Description:</label>
                  <b-form-textarea
                    v-model="descriptionSite"
                    id="textarea-default"
                    placeholder="Description"
                    rows="3"
                    class="mb-1"
                  />

                  <label for="textarea-default" style="font-size: 14px;">Tone:</label>
                  <v-select
                    v-model="toneSite"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="title"
                    :options="optionsTone"
                    class="mb-2"
                    placeholder="Tone"
                  />

                  <label for="tags-basic" style="font-size: 14px;">Keywords:</label>
                  <b-form-tags
                    v-model="keywordsSite"
                    input-id="tags-basic"
                    class="mb-2 keywords-config"
                    placeholder="Add keywords"
                    duplicate-tag-text="Keyword duplicado"
                  />

                  <div class="w-100" style="text-align: right;">
                    <b-button
                      style="border: 0px;"
                      class="add-field-form mt-1"
                      variant="primary"
                      @click.prevent="updateConfigurationSite"
                    >
                      Save
                    </b-button>
                  </div>
                </div>
              </b-tab>
              <b-tab
                active
                title="Page"
              >
                <div class="px-1 mt-2">
                  <label for="textarea-default" style="font-size: 14px;">Description:</label>
                  <b-form-textarea
                    v-model="descriptionPage"
                    id="textarea-default"
                    placeholder="Description"
                    rows="3"
                    class="mb-1"
                  />

                  <label for="textarea-default" style="font-size: 14px;">Tone:</label>
                  <v-select
                    v-model="tonePage"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="title"
                    :options="optionsTone"
                    class="mb-2"
                    placeholder="Tone"
                  />

                  <label for="tags-basic" style="font-size: 14px;">Keywords:</label>
                  <b-form-tags
                    v-model="keywordsPage"
                    input-id="tags-basic"
                    class="mb-2 keywords-config"
                    placeholder="Add keywords"
                    duplicate-tag-text="Keyword duplicado"
                  />

                  <div class="w-100" style="text-align: right;">
                    <b-button
                      style="border: 0px;"
                      class="add-field-form mt-1"
                      variant="primary"
                      @click.prevent="updateConfigurationPage"
                    >
                      Save
                    </b-button>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>

          <div class="my-2 mx-2 mt-25" v-if="!openCopilotTabs">
            <suggestions-overview :site-id.sync="SiteID" :info-ia.sync="infoIaS" :site-selected.sync="IASiteSelected" :permission.sync="logicOwner" :id-crawl.sync="crawlID" :pros.sync="sitesProPages" :changeerrorsugg.sync="changeerror" :site-obj.sync="siteObj" :site-homepage.sync="siteHomepage" :domain-prefix.sync="domainPrefix" :show-open.sync="showOpenSidebarCopilot" />
          </div>
        </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BImgLazy, BListGroup, BTabs, BTab, BFormTags,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow,
} from 'bootstrap-vue'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ClaimOwnership from '@/views/apps/sites/sites-add/ClaimOwnership.vue'
import SuggestionsOverview from '@/views/apps/IASuggestions/SuggestionsOverview.vue'
import countries from '@/@fake-db/data/other/countries'
import store from '@/store'
import { db, dbErrors, auth } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BImgLazy,
    BListGroup,
    BTabs,
    BTab,
    BFormTags,
    ClaimOwnership,
    AppCollapse,
    AppCollapseItem,
    HeaderSidebar,
    SuggestionsOverview,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenSidebarCopilot',
    event: 'update:show-open-sidebar-copilot',
  },
  props: ['showOpenSidebarCopilot','SiteID','infoIA','IASiteSelected','logicOwner','crawlID','sitesProPages','changeerror','siteObj','siteHomepage','domainPrefix'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,

      agentesAsignados: '',
      userData: '',
      password: '',
      passwordConfirm: '',
      emailOld: '',
      showDateHour: false,
      selectedPermissions: [],
      infoIaS: [],
      processSubmit: false,
      numberPages: 0,
      crawlType: 0,
      sitesImg: require('@/assets/images/illustration/i-site.png'),
      siteJS: require('@/assets/images/icons/js.png'),
      isloggedIn: false,
      idsite: '',
      openCopilotTabs: false,
      descriptionSite: '',
      toneSite: '',
      descriptionPage: '',
      tonePage: '',
      keywordsPage: [],
      optionsTone: [],
      keywordsSite: [],
      optionsKeywords: [],
      loadConf: false,

      // tagState: null,
      // errorMessage: '',
    }
  },
  watch: {
    toneSite(val) {
      if(this.loadConf) {
        // console.log(val)
        this.updateConfigurationSite()
      }
    },
    tonePage(val) {
      if(this.loadConf) {
        // console.log(val)
        this.updateConfigurationPage()
      }
    },
    keywordsSite(val) {
      if(this.loadConf) {
        // console.log(val)
        this.updateConfigurationSite()
      }
    },
    keywordsPage(val) {
      if(this.loadConf) {
        // console.log(val)
        this.updateConfigurationPage()
      }
    },
    showOpenSidebarCopilot(val) {
        this.openCopilotTabs = false
        this.descriptionSite = ''
        this.toneSite = ''
        this.keywordsSite = []

        this.descriptionPage = ''
        this.tonePage = ''
        this.keywordsPage = []
        this.loadConf = false

        if(val) {
          document.body.style.overflow = 'hidden'
        } else {
          document.body.style.overflow = 'auto'
        }

        if(val) {
          if(this.SiteID) {
            db.collection('Sites').doc(this.SiteID).get()
            .then(docSite => {
              if(docSite.exists) {
                this.loadConf = false
                this.descriptionSite = docSite.data().descriptionSite ? docSite.data().descriptionSite : ''
                this.toneSite = docSite.data().toneSite ? docSite.data().toneSite : ''
                this.keywordsSite = docSite.data().keywordsSite ? docSite.data().keywordsSite : []

                var actualConf = docSite.data().confPages ? docSite.data().confPages : []
                var filterConfPage = actualConf.filter(ele => ele.url === this.IASiteSelected[0].cUrl)
                this.descriptionPage = filterConfPage.length && filterConfPage[0].description ? filterConfPage[0].description : ''
                this.tonePage = filterConfPage.length && filterConfPage[0].tone ? filterConfPage[0].tone : ''
                this.keywordsPage = filterConfPage.length && filterConfPage[0].keywords ? filterConfPage[0].keywords : []

                setTimeout(() => {
                  this.loadConf = true
                }, 1500)
              }
            })
          }

          dbErrors.collection('IA_form_builder').doc('sites').get()
          .then(docIASite => {
            if(docIASite.exists) {
              this.optionsTone = docIASite.data().tones
              this.optionsKeywords = docIASite.data().keywords
            }
          })
        }
    },
    infoIA(val) {
        // console.log(val);
        if(val && val.length) {
            this.infoIaS = val
        }
    },
    siteObj(val) {
        // console.log(val);
        if(val && val.length) {
            var obj = val[0]
            // obj.domain = val[0].domainFix
            // obj.domainFix = val[0].domain
            // console.log(obj);
            this.idsite = obj
        }
    },
    changeerror(val) {
      if(val) {
        // console.log(val);
        this.$emit('update:changeerror', true)
      }
    }
  },
  validations: {
  },
  methods: {
    submitEditar(payload) {
    //   if(this.$route.name === 'shared-site' && (this.numberPages === 1 || this.numberPages === 2 || this.numberPages === '1' || this.numberPages === '2')) {
    //     this.$refs.sidebar_copilot.hide()
    //   } else {
    //     this.$refs.sidebar_copilot.hide()
    //   }
    },
    filterSelectSearch(options, search) {
      // console.log(search)
      const optionsFilterSelect = options
      // return this.optionsFiltered
      return search.length
        ? optionsFilterSelect.filter(item => item.toLowerCase().includes(search.toLowerCase()))
        : optionsFilterSelect
    },
    updateConfigurationSite() {
      db.collection('Sites').doc(this.SiteID).update({
        descriptionSite: this.descriptionSite,
        toneSite: this.toneSite,
        keywordsSite: this.keywordsSite,
      })
      .then(() => {
        db.collection('Requests').where('idcrawl', '==', this.crawlID).where('tipo', '==', 'only-text').get()
        .then(docsReqs => {
          docsReqs.forEach(docR => {
            db.collection('Requests').doc(docR.id).delete()
            .then(() => console.log('delete req only-text ia'))
          })
        })

        db.collection('Requests').where('crawlid', '==', this.crawlID).get()
        .then(docsReqs => {
          docsReqs.forEach(docR => {
            db.collection('Requests').doc(docR.id).delete()
            .then(() => console.log('delete req results ia'))
          })
        })

        // var newIAText = []
        // this.$emit('update:infoIA', newIAText)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: `Sites configuration saved`,
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      })
    },
    updateConfigurationPage() {
      db.collection('Sites').doc(this.SiteID).get()
      .then(docSite => {
        if(docSite.exists) {
          var actualConf = docSite.data().confPages ? docSite.data().confPages : []
          var filterConfPage = actualConf.filter(ele => ele.url !== this.IASiteSelected[0].cUrl)
          filterConfPage.push({
            url: this.IASiteSelected[0].cUrl,
            description: this.descriptionPage,
            tone: this.tonePage,
            keywords: this.keywordsPage
          })

          // DallE-tag
          // DallE-cop
          // DallE-cop-alt

          db.collection('Requests').where('idcrawl', '==', this.crawlID).where('RequestURL', '==', this.IASiteSelected[0].cUrl).where('tipo', '==', 'only-text').get()
          .then(docsReqs => {
            docsReqs.forEach(docR => {
              db.collection('Requests').doc(docR.id).delete()
              .then(() => console.log('delete req only-text ia'))
            })
          })

          db.collection('Requests').where('crawlid', '==', this.crawlID).where('url', '==', this.IASiteSelected[0].cUrl).get()
          .then(docsReqs => {
            docsReqs.forEach(docR => {
              db.collection('Requests').doc(docR.id).delete()
              .then(() => console.log('delete req results ia'))
            })
          })

          // var newIAText = this.infoIA.filter(item => item.url !== this.IASiteSelected[0].cUrl)
          // this.$emit('update:infoIA', newIAText)

          // db.collection('Requests').where('crawlid', '==', this.crawlID).where('url', '==', this.IASiteSelected[0].cUrl).where('tipo', '==', 'only-text')
          // .then(docsReqs => {
          //   docsReqs.forEach(docR => {
          //     db.collection('Requests').doc(docR.id).delete()
          //     .then(() => console.log('delete req only-text ia'))
          //   })
          // })

          // db.collection('Requests').where('crawlid', '==', this.crawlID).where('url', '==', this.IASiteSelected[0].cUrl).where('tipo', '==', 'only-text')
          // .then(docsReqs => {
          //   docsReqs.forEach(docR => {
          //     db.collection('Requests').doc(docR.id).delete()
          //     .then(() => console.log('delete req only-text ia'))
          //   })
          // })

          db.collection('Sites').doc(this.SiteID).update({
            confPages: filterConfPage
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Page configuration saved`,
                icon: 'BellIcon',
                variant: 'success',
              },
            })
          })
        }
      })
    },
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
.keywords-config li.b-form-tag {
  color: #656B85 !important;
  background: #E3E6F2 !important;
  border: 1px solid #CFD3E5 !important;
  font-size: 14px;
  padding: 7px 10px;
}
.sidebar-copilot .b-sidebar-outer {
  z-index: calc(1030 + 4);
}
.tabsViewCopilot .nav-tabs .nav-link.active.no-active-overview::after {
  /* background: white !important; */
  background: #ddd !important;
}
.tabsViewCopilot
.nav-tabs {
  margin-bottom: 0px !important;
}
.tabsViewCopilot
.nav-tabs
.nav-link.active {
  color: #232C51 !important;
  font-family: 'Hurme Geometric Sans 3';
  font-weight: 700;
  padding-bottom: 4px;
  font-size: 19px;
  background: none !important;
}
.tabsViewCopilot
.nav-tabs
.nav-link.active::after {
  background: transparent linear-gradient(90deg, #7838ff 0%, #30CDFF 100%) !important;
}
.tabsViewCopilot
.nav-tabs
.nav-link::after {
  background: #ddd !important;
  transform: translate3d(0, 0, 0);
}
.tabsViewCopilot
.nav-tabs
.nav-link {
  color: #232C51 !important;
  /* font-family: ; */
  /* padding-bottom: 0px; */
  font-size: 19px;
  height: 36px !important;
  padding: 0.61rem 1.6rem 0.61rem 1.6rem !important;
}
.tabsViewCopilot ul {
  /* display: flex; */
  flex-wrap: nowrap !important;
  overflow-x: auto;
  scrollbar-width: thin !important;
  -ms-overflow-style: none;
  scrollbar-color: #181e3800 #dae1e700 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-copilot {
    width: 90%;
    max-width: 793px !important;
}
</style>
