<template>
  <div>
    <b-sidebar
      id="sidebar-right-subscription"
      ref="sidebar_info_subscription"
      :visible="showOpenSubscription"
      bg-variant="white"
      right
      backdrop
      shadow
      no-header
      @change="(val) => $emit('update:show-open-subscription', val)"
    >
      <template #default="{ hide }">
        <div
          class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-50"
          style="background-color: #F0F1F7; height: 91px;"
        >
          <b-card
            no-body
            class="overflow-hidden w-100"
            style="background-color: #F0F1F7;"
          >
            <header-sidebar :idsite-header.sync="idsite" />
          </b-card>
          <feather-icon
            class="cursor-pointer"
            icon="XIcon"
            size="20"
            @click="hide"
          />
        </div>
        <div class="ml-3 my-2">
          <p
            v-if="idsite.label"
            class="mb-0"
            style="font-weight: 300; font-style: normal; color: #A7AAB9; font-size: 14px;"
          >
            Project
          </p>
          <h4
            v-if="idsite.label"
            class=""
            style="font-weight: 700;"
          >
            {{ idsite.label }}
          </h4>
        </div>

        <b-card
          no-body
          class="overflow-hidden w-100 mt-0"
          style="background-color: transparent;"
        >
          <b-row
            no-gutters
            class="mt-0 mx-1"
          >
            <b-col
              md="12"
              class="m-auto"
            >
              <h3 style="" class="mt-1">Current license: <span style="font-weight: 400 !important;">{{!planType ? 'free' : planType === 5 ? '5 pages' : planType === 10 ? '10 pages' : planType === 25 ? '25 pages' : planType + ' pages'}}</span></h3>
              <p style="margin-bottom: 7px;" class="mt-1"><b>Current pages: </b>{{pages ? pages : '2'}}</p>
              <p style="margin-bottom: 7px;"><b>Price: </b> {{ !planType ? 'free' : getPlanAmount(paidRecurrency, planType, planType) }}{{ !planType ? '' : paidRecurrency === 'month' || paidRecurrency === 'Month' ? ' / month' : ' / month billed anually' }}</p>
              <p style="margin-bottom: 7px;"><b>Next due: </b>{{nextDue ? nextDue : '-'}}</p>
              <span class="mr-50"><b>Status:</b> </span>
              <div v-if="cancelSub === true && nextDue && subscriptionId">
                <b-badge variant="light-warning">
                  Canceled
                </b-badge>
                <b-badge class="mt-50 ml-75" style="font-size: 11px;" variant="light-dark" v-if="nextDue !== '-'">
                    <span style="color: #646464 !important">Cancels {{nextDueCancel}}</span>
                    <feather-icon
                        icon="ClockIcon"
                        class="ml-25"
                        style="padding-bottom: 1px;"
                    />
                </b-badge>
              </div>
              <b-badge variant="light-success" v-else>
                  Active
              </b-badge>

              <div v-if="cancelSub === true && nextDue && subscriptionId">
                <div class="w-100 d-block">
                  <b-button
                    :style="'background: #7838ff !important; border: 1px solid #7838ff !important; width: 188px; height: 46px; border-radius: 23px; margin-top: 30px !important;'"
                    class="ml-0"
                    variant="primary"
                    @click="getInfoReactivateLicense()"
                  >Reactivate
                  </b-button>
                </div>
              </div>
              <div v-else>
                <div class="w-100 d-block" v-if="infoLicense.subscriptionId && infoLicense.plan !== 'Free'">
                  <b-overlay
                    :show="processInvoice"
                    :variant="'transparent'"
                    :opacity="0.50"
                    :blur="'2px'"
                    rounded="sm"
                  >
                    <b-link
                      :class="!processInvoice && infoLicense.customerId ? '' : 'disabled-link'"
                      @click.prevent="!processInvoice && infoLicense.customerId ? redirectToBilling(infoLicense.customerId) : null"
                      :style="infoLicense.customerId ? (processInvoice ? 'cursor: progress; color: #c1c1c1 !important; padding-left: 46px; padding-top: 20px; display: block;' : 'padding-left: 46px; padding-top: 20px; display: block;') : 'padding-left: 46px; padding-top: 20px; display: block;'"
                    >
                      View invoices
                    </b-link>
                  </b-overlay>
                </div>

                <div class="w-100 d-block">
                  <b-button
                    :style="'background: #7838ff !important; border: 1px solid #7838ff !important; width: 188px; height: 46px; border-radius: 23px; margin-top: 30px !important;'"
                    class="ml-0"
                    variant="primary"
                    :disabled="processInvoice"
                    @click="getInfoLicense()"
                  >Upgrade/Downgrade
                  </b-button>
                </div>

                <div class="w-100 d-block" v-if="infoLicense.subscriptionId && infoLicense.plan !== 'Free'">
                  <b-button
                    :style="'width: 188px; height: 46px; border-radius: 23px; margin-top: 30px !important;'"
                    class="ml-0"
                    variant="outline-warning"
                    :disabled="processInvoice"
                    @click="getInfoCancelLicense()"
                  >{{ infoLicense.freeTrial === true && infoLicense.subscriptionId ? 'Cancel Free Trial' : 'Cancel license' }}
                  </b-button>
                </div>
              </div>

              <feather-icon
                size="21"
                icon="ArrowLeftIcon"
                class="cursor-pointer"
                style="margin-top: 40px; margin-left: 15px;"
                @click="hide"
              />

            </b-col>
          </b-row>
        </b-card>
        
        <help-footer />
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BButton, BFormTextarea, BListGroup, BListGroupItem, BImgLazy, BBadge,
  BFormCheckbox, BFormCheckboxGroup, BFormTimepicker, BAvatar, VBToggle, VBTooltip, BCard, BCardImgLazy, BCardText, BCol, BRow, BLink, BOverlay,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import {
  required, minLength, password, confirmed, email,
} from 'vuelidate/lib/validators'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import HelpFooter from '@/views/components/footer/HelpFooter'
import HeaderSidebar from '@/views/extensions/acl/table/sidebar/HeaderSidebar.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapActions } from 'vuex'
import countries from '@/@fake-db/data/other/countries'
import moment from "moment";
import store from '@/store'
import { db, auth } from '@/firebase'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    vSelect,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormGroup,
    BFormTimepicker,
    BAvatar,
    BCard,
    BCardImgLazy,
    BCardText,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BImgLazy,
    BBadge,
    BLink,
    BOverlay,
    HelpFooter,
    HeaderSidebar,

    // date
    flatPickr,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'showOpenSubscription',
    event: 'update:show-open-subscription',
  },
  props: ['idsite','showOpenSubscription'],
  data() {
    return {
      email,
      required,
      password,
      confirmed,
      userData: '',

      planType: '',
      planSite: '',
      paidRecurrency: '',
      nextDue: '',
      nextDueCancel: '',
      cancelSub: '',
      subscriptionId: '',
      pages: '',
      downgrade_planed: '',
      infoLicense: '',
      processInvoice: false,

      //   sources
      appImgComingSoon: require('@/assets/images/icons/logo-coming-soon.svg'),
    }
  },
  watch: {
    showOpenSubscription(val) {
      this.infoLicense = ''
      this.processInvoice = false
      if(val) {
        document.body.style.overflow = 'hidden'

        const user = auth.currentUser
        db.collection('Users').doc(user.uid).get()
        .then(userDocCustomer => {
          var projectid = this.$route.params.project ? this.$route.params.project : this.idsite.projectid ? this.idsite.projectid : this.$route.params.project
          var siteid = this.$route.params.id ? this.$route.params.id : this.idsite.id ? this.idsite.id : this.$route.params.id
          // console.log(projectid, siteid);
          db.collection('Users').doc(user.uid).collection('projects').doc(projectid).get()
          .then(eleUserProject => {
            db.collection('Projects').doc(projectid).collection('sites').doc(siteid).get()
            .then(site => {
                if(site.exists) {
                    var downgrade_date = site.data().downgrade_date ? site.data().downgrade_date : false
                    var downgrade_planed = false
                    if(downgrade_date) {
                      // console.log(statusCancel, site.data().due*1000);
                      var dueCancel = moment(downgrade_date*1000)
                      var dateActual = moment()

                      // Diferencia en días
                      var differenceInMinutes = dueCancel.diff(dateActual, 'minutes');
                      // console.log(differenceInMinutes, dueCancel, dateActual);
                      if(differenceInMinutes < 0) {
                        downgrade_planed = false
                        db.collection('Projects').doc(eleUserProject.id).collection('sites').doc(site.id).update({
                          downgrade_date: ''
                        })
                      } else {
                        downgrade_planed = true
                      }
                    }

                    this.planType = site.data().planType ? parseInt(site.data().planType, 10) : ''
                    this.planSite = site.data().plan ? site.data().plan : ''
                    this.paidRecurrency = site.data().paidRecurrency ? site.data().paidRecurrency : ''
                    this.nextDue = site.data().due_end ? moment(site.data().due_end*1000).format('MM/DD/YYYY') : site.data().due ? moment(site.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : moment(Date.now()).add(1, 'month').format('MM/DD/YYYY')
                    this.nextDueCancel = site.data().due_end ? moment(site.data().due_end*1000).format('ll') : site.data().due ? moment(site.data().due*1000).add(1, 'month').format('ll') : moment(Date.now()).add(1, 'month').format('ll')
                    this.cancelSub = site.data().cancelSub ? site.data().cancelSub : false
                    this.subscriptionId = site.data().subscriptionId ? site.data().subscriptionId : ''
                    this.pages = site.data().planType ? site.data().planType : ''
                    this.downgrade_planed = downgrade_planed

                    if(site.data().plan === 'free' || site.data().plan === 'Free' || !site.data().plan) {
                      this.infoLicense = {
                        id: site.id,
                        img: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                        name: site.data().name ? site.data().name : site.data().WebsiteURL,
                        domainFixed: site.data().WebsiteURL,
                        project: eleUserProject.data().name,
                        statusSite: site.data().status,
                        domain: site.data().homepage,
                        projectid: eleUserProject.data().id,
                        color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)',
                        own: 'me',
                        invitation: '',
                        imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                        plan: 'Free',
                        permission: 'owner',
                        amount: 'free',
                        nextDue: site.data().due ? moment(site.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : '-',
                        nextDueCancel: site.data().due ? moment(site.data().due*1000).add(1, 'month').format('ll') : '-',
                        // nextDueCancel: site.data().due ? moment(site.data().due*1000).format('MMM DD') : '-',
                        cancelSub: site.data().cancelSub ? site.data().cancelSub : false,
                        status: 'active',
                        payments: 'View Invoice',
                        freeTrial: site.data().freeTrial ? site.data().freeTrial : false,
                        customerId: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                        customerIdSite: site.data().customerId ? site.data().customerId : '',
                        subscriptionId: site.data().subscriptionId ? site.data().subscriptionId : '',
                        planType: site.data().planType ? parseInt(site.data().planType, 10) : '',
                        planSite: site.data().plan ? site.data().plan : '',
                        paidRecurrency: site.data().paidRecurrency ? site.data().paidRecurrency : ''
                      }
                    } else if(site.data().plan === 'basic' || site.data().plan === 'Basic' || site.data().plan === 'pro' || site.data().plan === 'Pro' || site.data().plan === 'custom' || site.data().plan === 'Custom') {
                      this.infoLicense = {
                        id: site.id,
                        img: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                        name: site.data().name ? site.data().name : site.data().WebsiteURL,
                        domainFixed: site.data().WebsiteURL,
                        project: eleUserProject.data().name,
                        statusSite: site.data().status,
                        domain: site.data().homepage,
                        projectid: eleUserProject.data().id,
                        color: eleUserProject.data().color ? eleUserProject.data().color : 'rgb(240, 241, 247)',
                        own: 'me',
                        invitation: '',
                        imgSite: site.data().thumbnail ? site.data().thumbnail : 'https://firebasestorage.googleapis.com/v0/b/seocloud-7e24d.appspot.com/o/site-thumb.png?alt=media&token=6ec3aa77-3ce7-4d16-a70d-10340b8a392b',
                        plan: 'Pro',
                        permission: 'owner',
                        amount: '$20.00',
                        nextDue: site.data().due ? moment(site.data().due*1000).add(1, 'month').format('MM/DD/YYYY') : moment(Date.now()).add(1, 'month').format('MM/DD/YYYY'),
                        nextDueCancel: site.data().due ? moment(site.data().due*1000).add(1, 'month').format('ll') : '-',
                        cancelSub: site.data().cancelSub ? site.data().cancelSub : false,
                        status: 'active',
                        payments: 'View Invoice',
                        freeTrial: site.data().freeTrial ? site.data().freeTrial : false,
                        customerId: userDocCustomer.data().customerId ? userDocCustomer.data().customerId : '',
                        customerIdSite: site.data().customerId ? site.data().customerId : '',
                        subscriptionId: site.data().subscriptionId ? site.data().subscriptionId : '',
                        planType: site.data().planType ? parseInt(site.data().planType, 10) : '',
                        planSite: site.data().plan ? site.data().plan : '',
                        paidRecurrency: site.data().paidRecurrency ? site.data().paidRecurrency : '',
                        pages: site.data().planType ? site.data().planType : ''
                      }
                    }
                }
            })
          })
        })
      } else {
        document.body.style.overflow = 'auto'
      }
    }
  },
  validations: {
  },
  methods: {
    getInfoLicense() {
      localStorage.setItem('jsonInfoLic', JSON.stringify(this.infoLicense))
      this.submitEditar()
    },
    getInfoCancelLicense() {
      localStorage.setItem('jsonInfoCancel', JSON.stringify(this.infoLicense))
      this.submitEditar()
    },
    getInfoReactivateLicense() {
      localStorage.setItem('jsonInfoReactivate', JSON.stringify(this.infoLicense))
      this.submitEditar()
    },
    getPlanAmount(annualyBill,customPlan,typeCrawls) {
      //  paidRecurrency === 'year' ? (planType === 5 ? '$'+123 : planType === 10 ? '$'+140 : planType === 25 ? '$'+195 : planType >= 50 ? '$'+(220 + (planType - 50)) : '$'+20) : (planType === 5 ? '$'+5 : planType === 10 ? '$'+8 : planType === 25 ? '$'+10 : planType >= 50 ? '$'+(12 + ((planType - 50) / 10)) : '$'+20) }}.00
      var typeCrawl = 0
      if(typeCrawls === 5) {
        typeCrawl = 1
      } else if(typeCrawls === 10) {
        typeCrawl = 2
      } else if(typeCrawls === 25) {
        typeCrawl = 3
      } else {
        typeCrawl = 4
      }

      var priceId = ''
      if(annualyBill === 'year') {
        // yearly
        if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = '$' + 19
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = '$' + 29
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = '$' + 49
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((220 + (customPlan - 50)) === 220) {
            priceId = '$' + 69
          } else if((220 + (customPlan - 50)) === 270) {
            priceId = '$' + 99
          } else if((220 + (customPlan - 50)) === 320) {
            priceId = '$' + 139
          } else if((220 + (customPlan - 50)) === 370) {
            priceId = '$' + 179
          } else if((220 + (customPlan - 50)) === 420) {
            priceId = '$' + 219
          } else if((220 + (customPlan - 50)) === 470) {
            priceId = '$' + 259
          } else if((220 + (customPlan - 50)) === 520) {
            priceId = '$' + 299
          } else if((220 + (customPlan - 50)) === 570) {
            priceId = '$' + 339
          } else if((220 + (customPlan - 50)) === 620) {
            priceId = '$' + 379
          } else if((220 + (customPlan - 50)) === 670) {
            priceId = '$' + 419
          } else if((220 + (customPlan - 50)) === 720) {
            priceId = '$' + 459
          } else if((220 + (customPlan - 50)) === 770) {
            priceId = '$' + 499
          } else if((220 + (customPlan - 50)) === 820) {
            priceId = '$' + 539
          } else if((220 + (customPlan - 50)) === 870) {
            priceId = '$' + 579
          } else if((220 + (customPlan - 50)) === 920) {
            priceId = '$' + 619
          } else if((220 + (customPlan - 50)) === 970) {
            priceId = '$' + 659
          } else if((220 + (customPlan - 50)) === 1020) {
            priceId = '$' + 699
          } else if((220 + (customPlan - 50)) === 1070) {
            priceId = '$' + 739
          } else if((220 + (customPlan - 50)) === 1120) {
            priceId = '$' + 779
          } else if((220 + (customPlan - 50)) === 1170) {
            priceId = '$' + 819
          }
        }
      } else {
        // monthly
        if(typeCrawl === 1 || typeCrawl === '1') {
          priceId = '$' + 25
        } else if(typeCrawl === 2 || typeCrawl === '2') {
          priceId = '$' + 39
        } else if(typeCrawl === 3 || typeCrawl === '3') {
          priceId = '$' + 65
        } else if(typeCrawl === 4 || typeCrawl === '4') {
          if((12 + ((customPlan - 50) / 10)) === 12) {
            priceId = '$' + 89
          } else if((12 + ((customPlan - 50) / 10)) === 17) {
            priceId = '$' + 129
          } else if((12 + ((customPlan - 50) / 10)) === 22) {
            priceId = '$' + 179
          } else if((12 + ((customPlan - 50) / 10)) === 27) {
            priceId = '$' + 229
          } else if((12 + ((customPlan - 50) / 10)) === 32) {
            priceId = '$' + 279
          } else if((12 + ((customPlan - 50) / 10)) === 37) {
            priceId = '$' + 329
          } else if((12 + ((customPlan - 50) / 10)) === 42) {
            priceId = '$' + 379
          } else if((12 + ((customPlan - 50) / 10)) === 47) {
            priceId = '$' + 429
          } else if((12 + ((customPlan - 50) / 10)) === 52) {
            priceId = '$' + 479
          } else if((12 + ((customPlan - 50) / 10)) === 57) {
            priceId = '$' + 529
          } else if((12 + ((customPlan - 50) / 10)) === 62) {
            priceId = '$' + 579
          } else if((12 + ((customPlan - 50) / 10)) === 67) {
            priceId = '$' + 629
          } else if((12 + ((customPlan - 50) / 10)) === 72) {
            priceId = '$' + 679
          } else if((12 + ((customPlan - 50) / 10)) === 77) {
            priceId = '$' + 729
          } else if((12 + ((customPlan - 50) / 10)) === 82) {
            priceId = '$' + 779
          } else if((12 + ((customPlan - 50) / 10)) === 87) {
            priceId = '$' + 829
          } else if((12 + ((customPlan - 50) / 10)) === 92) {
            priceId = '$' + 879
          } else if((12 + ((customPlan - 50) / 10)) === 97) {
            priceId = '$' + 929
          } else if((12 + ((customPlan - 50) / 10)) === 102) {
            priceId = '$' + 979
          } else if((12 + ((customPlan - 50) / 10)) === 107) {
            priceId = '$' + 999
          }
        }
      }
      // console.log(priceId);

      return priceId
    },
    submitEditar(payload) {
      this.$refs.sidebar_info_subscription.hide()
    },
    redirectToBilling(customerId) {
      // this.processInvoice = customerId
      // this.processInvoice = idsite
      this.processInvoice = true
      var URLactual = new URL(window.location)
      var domain = URLactual.protocol + '//' + (URLactual.hostname === 'localhost' ? URLactual.hostname + ':8080' : URLactual.hostname)

      let config = {
        method: 'post',
        // maxBodyLength: Infinity,
        url: 'https://us-central1-seocloud-dcdfb.cloudfunctions.net/stripe/create-billing-session',
        headers: { 
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({customerId: customerId, domain: domain})
      };
  
      this.axios(config)
      .then((response) => {
        // Redirigir al usuario a la URL de facturación de Stripe
        var url = response.data.url
        if(url) {
          window.location.href = url

          setTimeout(() => {
            this.processInvoice = ''
          }, 1500)
        }
      })
      .catch(e => console.log(e.message))
    }
  },
  setup() {
    return {
      avatarText,
    }
  },
}
</script>

<style>
.b-sidebar-backdrop {
  background-color: #181E38BF !important;
  opacity: 1 !important;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
#sidebar-right-subscription {
    width: 90%;
    max-width: 593px !important;
}
</style>